import EventEmitter from "events";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import Modal from "matrix-react-sdk/src/Modal";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import { EventType } from "matrix-js-sdk/src/@types/event";
import { EventStatus } from "matrix-js-sdk/src/models/event";
import { getForwardableEvent } from "matrix-react-sdk/src/events/forward/getForwardableEvent";
import { logger } from "matrix-js-sdk/src/logger";
import { MatrixClient } from "matrix-js-sdk/src/client";
// CTalk imported
import { ERoomSelectionEvent } from "@ctalk/enums/room-selection.enum";
import { unpinMessage } from "@ctalk/helpers/MessagesHelper";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";

// CTalk added
import ConfirmRedactDialog from "../components/views/dialogs/ConfirmRedactDialog";
import ForwardDialog from "../components/views/dialogs/ForwardDialog";
import { isContentActionable } from "../utils/EventUtils";
import { RoomEventInfoStore } from "./RoomEventInfoStore";

const MAX_SELECTION_MESSAGES = 50; // Default limit 50 messages

export interface OpenForwardDialogPayload extends ActionPayload {
    action: Action.OpenForwardDialog;
    event: MatrixEvent | MatrixEvent[];
}

interface OnBulkAction {
    onFinished: (...args: any[]) => void;
}

export class RoomSelectionStore extends EventEmitter {
    private static readonly internalInstance = ((): RoomSelectionStore => {
        return new RoomSelectionStore();
    })();

    public static get instance(): RoomSelectionStore {
        return RoomSelectionStore.internalInstance;
    }

    private readonly selectedEvents: Map<string, MatrixEvent>;

    public constructor() {
        super();
        this.selectedEvents = new Map<string, MatrixEvent>();
    }

    /**
     * Base on context menu, checking event can be forwarded
     * @param mxEvent MatrixEvent
     * @param client MatrixClient (optional)
     */
    public static canForward(mxEvent: MatrixEvent, client?: MatrixClient): boolean {
        const cli = client ?? MatrixClientPeg.get();
        const contentActionable = isContentActionable(mxEvent);
        if (!contentActionable) return false;

        return !!getForwardableEvent(mxEvent, cli);
    }

    /**
     * Base on context menu, checking event can be redacted
     * @param mxEvent MatrixEvent
     * @param client MatrixClient (optional)
     */
    public static canRedact(mxEvent: MatrixEvent, client?: MatrixClient): boolean {
        const isSent = !mxEvent.status || mxEvent.status === EventStatus.SENT;
        if (!isSent) return false;

        const whitelist: string[] = [EventType.RoomMessage, EventType.Sticker];
        const allowedType = whitelist.includes(mxEvent.getType());
        if (!allowedType) return false;

        const isPinMessages = mxEvent.getType() === EventType.RoomPinnedEvents;
        if (isPinMessages) return false;

        const blackList: string[] = [EventType.RoomServerAcl, EventType.RoomEncryption];
        const isIgnoredType = blackList.includes(mxEvent.getType());
        if (isIgnoredType) return false;

        const cli = client ?? MatrixClientPeg.safeGet();
        const room = cli.getRoom(mxEvent.getRoomId());
        return room.currentState.maySendRedactionForEvent(mxEvent, cli.credentials.userId)
            && !blackList.includes(mxEvent.getType());
    }

    /**
     * Combine forward and redact condition
     * @param mxEvent MatrixEvent
     */
    public static isSelectable(mxEvent: MatrixEvent): boolean {
        const cli = MatrixClientPeg.safeGet();
        const isForwardAble = this.canForward(mxEvent, cli);
        if (isForwardAble) {
            return true;
        }
        return this.canRedact(mxEvent, cli);
    }

    public getSelectedEvents(): MatrixEvent[] {
        return Array.from(this.selectedEvents.values());
    }

    public isSelectingMode(): boolean {
        return this.selectedEvents.size > 0;
    }

    public canSelectMore(): boolean {
        return this.selectedEvents.size < MAX_SELECTION_MESSAGES;
    }

    public disabledForward(): boolean {
        const events = this.getSelectedEvents();
        if (!events.length) {
            return false;
        }
        const cli = MatrixClientPeg.get();
        return events.some((event) => {
            return !RoomSelectionStore.canForward(event, cli);
        });
    }

    public disabledDelete(): boolean {
        const events = this.getSelectedEvents();
        if (!events.length) {
            return false;
        }
        const cli = MatrixClientPeg.get();
        return events.some((event) => {
            return !RoomSelectionStore.canRedact(event, cli);
        });
    }

    public isEventSelected(eventId: string): boolean {
        return this.selectedEvents.has(eventId);
    }

    public add(event: MatrixEvent): void {
        if (
            !this.canSelectMore() || // selection limit reached
            this.selectedEvents.has(event.getId()) || // already added this event to store
            !RoomSelectionStore.isSelectable(event) // cannot select this event type
        ) {
            return;
        }
        this.selectedEvents.set(event.getId(), event);
        this.emit(ERoomSelectionEvent.UPDATE, this.selectedEvents);
    }

    public toggle(event: MatrixEvent): void {
        const key = event.getId();
        if (this.selectedEvents.has(key)) {
            this.selectedEvents.delete(key);
        } else if (this.canSelectMore() && RoomSelectionStore.isSelectable(event)) {
            this.selectedEvents.set(key, event);
        }
        this.emit(ERoomSelectionEvent.UPDATE, this.selectedEvents);
    }

    public remove(event: MatrixEvent | string): void {
        const eventId: string = event instanceof MatrixEvent ? event.getId() : event;
        if (!this.selectedEvents.has(eventId)) {
            return;
        }
        this.selectedEvents.delete(eventId);
        this.emit(ERoomSelectionEvent.UPDATE, this.selectedEvents);
    }

    public empty(): void {
        this.selectedEvents.clear();
        this.emit(ERoomSelectionEvent.UPDATE, this.selectedEvents);
    }

    public bulkDelete({ onFinished }: OnBulkAction): void {
        Modal.createDialog(ConfirmRedactDialog, {
            onFinished: async (done: boolean, reason?: string) => {
                onFinished(done);
                if (!done) return;
                const cli = MatrixClientPeg.safeGet();
                const selectedEvents = this.getSelectedEvents();
                const calls = [];
                const ckClient = CTalkClientPeg.get();
                selectedEvents.map((mxEvent) => {
                    calls.push(
                        unpinMessage(cli, mxEvent),
                        cli.redactEvent(
                            mxEvent.getRoomId(),
                            mxEvent.getId(),
                            undefined,
                            reason ? { reason } : {},
                        ).then(async () => {
                            RoomEventInfoStore.instance.deleteRoomEvent(mxEvent.getRoomId(), mxEvent.getId());
                            ckClient
                                .deleteRoomMessageEvent(mxEvent.getId())
                                .catch((e) => {
                                    logger.error(e);
                                });
                        }),
                    );
                });
                this.empty();
                try {
                    await Promise.all(calls);
                } catch (e) {
                    logger.error(e);
                }
            },
        }, 'mx_Dialog_confirmredact');
    }

    public bulkForward({ onFinished }: OnBulkAction): void {
        Modal.createDialog(ForwardDialog, {
            matrixClient: MatrixClientPeg.safeGet(),
            event: this.getSelectedEvents(),
            permalinkCreator: undefined,
            onFinished: (sent) => {
                if (sent) {
                    this.empty();
                }
                onFinished(sent);
            },
        });
    }
}
