import { encryptAES, decryptAES } from "matrix-js-sdk/src/crypto/aes";
import CryptoJS from "crypto-js";

function stringToUint8(str: string): Uint8Array {
    const uint8Array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        uint8Array[i] = str.charCodeAt(i);
    }
    return uint8Array;
}

export async function encrypt(data: string, keyData: string): Promise<string> {
    // eslint-disable-next-line
    const keyHash = (CryptoJS.MD5(keyData) + '');
    const encrypted = await encryptAES(data, stringToUint8(keyHash), "seed_phrase");
    return JSON.stringify(encrypted);
}

export async function decrypt(data: string, keyData: string): Promise<string> {
    // eslint-disable-next-line
    const keyHash = (CryptoJS.MD5(keyData) + '');
    const payload = JSON.parse(data);
    return await decryptAES(payload, stringToUint8(keyHash), "seed_phrase");
}
