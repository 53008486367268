import React, { ChangeEvent, RefObject } from "react";
import CBaseComponent, { IBaseFormState } from "@ctalk/components/views/messages/form_components/CBaseForm";
import { ICFileProps } from "@ctalk/models/bot-form.interface";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { Icon as FilesIcon } from "matrix-react-sdk/res/img/feather-customised/files.svg";
import { Icon as CancelIcon } from "matrix-react-sdk/res/img/element-icons/cancel-rounded.svg";
import { Icon as CameraIcon } from "matrix-react-sdk/res/img/element-icons/camera.svg";
import Field from "matrix-react-sdk/src/components/views/elements/Field";
// import CTimeExpire from "@ctalk/components/views/messages/form_components/CTimeExpire";
import { EFormType } from "@ctalk/enums/bot-form.enum";
import { compareEncryptFileContent, IEncryptFileContent } from "@ctalk/helpers/BotFormHelper";
import Modal from "matrix-react-sdk/src/Modal";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import { chromeFileInputFix } from "matrix-react-sdk/src/utils/BrowserWorkarounds";

import PreviewFile from "../../elements/PreviewFile";
import {_t} from "../../../../../languageHandler";
import { truncate } from "../../../../helpers/StringHelper";
import CameraDialog from "../../elements/CameraDialog";

export interface IFileValue {
    file: File;
    note: string;
}

enum ESelectFileMethod {
    CAMERA = "camera",
    MEDIA = "media"
}

interface IFileSelectedProps {
    file: File;
    previewFileExpiresIn: number;
    maxNoteLength: number;
    onRemoveFile: (file: File) => void;
    onNoteChange: (fileValue: IFileValue) => void;
}

interface IFileSelectedState {
    noteValue: string;
    // expireTime: number;
    expired: boolean;
}

class CFileSelected extends React.Component<IFileSelectedProps, IFileSelectedState> {
    private intervalId: NodeJS.Timeout | null = null;

    public constructor(props: IFileSelectedProps) {
        super(props);
        this.state = {
            noteValue: '',
            // expireTime: this.props.previewFileExpiresIn,
            expired: false
        };
    }

    public componentDidMount(): void {
        /*
        if (this.state.expireTime > 0) {
            this.intervalId = setInterval(() => {
                this.setState(prevState => ({
                    expireTime: prevState.expireTime - 1000, // Decrement by 1 minute
                }), () => {
                    if (this.state.expireTime <= 0) {
                        this.resetInterval();
                        this.setState({
                            expired: true,
                        });
                    }
                });
            }, 1000);
        }
         */
    }

    public componentWillUnmount(): void {
        this.resetInterval();
    }

    private resetInterval(): void {
        if (!this.intervalId) {
            return;
        }
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    private onValueChange = (ev: ChangeEvent<HTMLInputElement>): void => {
        const noteValue = ev.target.value
        this.setState({ noteValue });
        this.props.onNoteChange({
            file: this.props.file,
            note: noteValue,
        });
    };

    private onRemoveClick = (): void => {
        this.props.onRemoveFile(this.props.file);
    };

    public render(): React.ReactNode {
        /*
        const rangeTime = {
            from: ETimeLevel.MINUTE,
            to: ETimeLevel.MINUTE,
        }
         */
        return (
            <div className="ctalk_file_selected">
                <div className="ctalk_file_selected_info">
                    {
                        this.state.expired ?
                        <div className="ctalk_Form_file_text_preview_expired">
                            { truncate(this.props.file.name, 35) }
                        </div> : <PreviewFile
                            file={this.props.file}
                            fileName={this.props.file.name}
                            note={this.state.noteValue}
                            isNotAllowDownload={true}
                            isPreviewRestricted={this.state.expired}
                        />
                    }
                    <div>
                        <AccessibleButton
                            onClick={this.onRemoveClick}
                        >
                            <CancelIcon width="1em" height="1em" />
                        </AccessibleButton>
                    </div>
                </div>
                <div>
                    <Field
                        label={_t("ctalk|bot_form|edit_file_note_placeholder")}
                        onChange={this.onValueChange}
                        value={this.state.noteValue}
                        maxLength={this.props.maxNoteLength}
                        className="mx_CreateRoomDialog_name"
                    />
                    <div className="ctalk_input_count">
                        {this.state.noteValue.length}/{this.props.maxNoteLength}
                    </div>
                </div>
                {/*<CTimeExpire
                    label={_t("ctalk|bot_form|preview_file_expired_in")}
                    expireTime={this.state.expireTime}
                    rangeTime={rangeTime}
                    needToShowLessThan={true}
                    roundUp={true}
                />*/}
            </div>
        );
    }
}

interface IFileResponseValue {
    file: IEncryptFileContent;
    note: string;
}

interface IFileRespondedProps {
    file: IEncryptFileContent;
    maxNoteLength: number;
    onRemoveFile: (file: IEncryptFileContent) => void;
    onNoteChange: (fileValue: IFileResponseValue) => void;
}

interface IFileRespondedState {
    noteValue: string;
}

class CFileResponded extends React.Component<IFileRespondedProps, IFileRespondedState> {
    public constructor(props: IFileRespondedProps) {
        super(props);
        this.state = {
            noteValue: this.props.file.note,
        }
    }

    private onValueChange = (ev: ChangeEvent<HTMLInputElement>): void => {
        const noteValue = ev.target.value
        this.setState({ noteValue });
        this.props.onNoteChange({
            file: this.props.file,
            note: noteValue,
        });
    };

    private onRemoveClick = (): void => {
        this.props.onRemoveFile(this.props.file);
    };

    public render(): React.ReactNode {
        return (
            <div className="ctalk_file_selected">
                <div className="ctalk_file_selected_info">
                    <PreviewFile
                        file={this.props.file}
                        fileName={this.props.file.filename}
                        note={this.state.noteValue}
                        isNotAllowDownload={true}
                    />
                    <div>
                        <AccessibleButton
                            onClick={this.onRemoveClick}
                        >
                            <CancelIcon width="1em" height="1em" />
                        </AccessibleButton>
                    </div>
                </div>
                <div>
                    <Field
                        label={_t("ctalk|bot_form|edit_file_note_placeholder")}
                        onChange={this.onValueChange}
                        value={this.state.noteValue}
                        maxLength={this.props.maxNoteLength}
                        className="mx_CreateRoomDialog_name"
                    />
                    <div className="ctalk_input_count">
                        {this.state.noteValue.length}/{this.props.maxNoteLength}
                    </div>
                </div>
            </div>
        );
    }
}

interface IFileState extends IBaseFormState {
    fileValues: IFileValue[];
    fileResponseValues: IEncryptFileContent[];
    note: string;
}

const FILE_NAME_LIMIT = 64;

export default class CFile extends CBaseComponent<ICFileProps, IFileState> {
    private readonly inputOpenFileRef: RefObject<HTMLInputElement>;
    // private needToUseCamera = false;

    public constructor(props: ICFileProps) {
        super(props);
        this.inputOpenFileRef = React.createRef();

        this.state = {
            ...this.state,
            fileValues: [],
            fileResponseValues: [],
            note: '',
        }
    }

    public componentDidMount(): void {
        const value = this.props.responseValue?.value ?? [];
        this.updateStateAndValidate({
            fileResponseValues: value,
            value: value,
        });
    }

    // private onOpenCamera = (): void => {
    //     // TODO: Handle open camera on desktop app
    // };

    private onSelectFiles = (): void => {
        if (this.inputOpenFileRef?.current) {
            this.inputOpenFileRef.current.click();
        }
    };

    private handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const files = event.target.files;
        this.handleAddFile(files);
    };

    private handleAddFile = (files: FileList | null): void => {
        const maxFileSize = this.props.size * 1024 * 1024; // 5MB in bytes
        const maxFiles = this.props.limit;

        if (!files || files.length === 0) return;
        const fileValues: IFileValue[] = [...(this.state.fileValues || [])];
        const existingFileNames = fileValues.map((fileValue) => fileValue.file.name);
        if (this.props.responseValue) {
            const responseFileNames = this.props.responseValue.value.map((value: any) => value.filename);
            existingFileNames.push(...responseFileNames);
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const isValidFileType = this.isValidFileType(file.type, this.props.allowed_types);
            if (!isValidFileType) {
                Modal.createDialog(ErrorDialog, {
                    title: _t("upload_failed_title"),
                    description: _t("ctalk|bot_form|error|select_file_from_list"),
                });
                continue;
            }
            if (file.name.length > FILE_NAME_LIMIT) {
                Modal.createDialog(ErrorDialog, {
                    title: _t("upload_failed_title"),
                    description: _t("ctalk|bot_form|error|file_name_limit", {
                        limit: FILE_NAME_LIMIT,
                    }),
                });
                continue;
            }
            if (file.size > maxFileSize) {
                Modal.createDialog(ErrorDialog, {
                    title: _t("upload_failed_title"),
                    description: _t("ctalk|bot_form|error|select_file_max_size", {
                        name: file.name,
                        size: this.props.size,
                    }),
                });
                continue;
            }
            if (fileValues.length >= maxFiles) {
                Modal.createDialog(ErrorDialog, {
                    title: _t("upload_failed_title"),
                    description: _t("ctalk|bot_form|error|select_files_max", {
                        max: maxFiles,
                    }),
                });
                break;
            }
            const fileName = this.getUniqueFileName(file.name, existingFileNames);
            const modifiedFile = new File([file], fileName, { type: file.type });
            fileValues.push({
                file: modifiedFile,
                note: '',
            });
        }
        this.props.onValueChange({
            name: this.props.name,
            type: EFormType.File,
            value: fileValues,
        });
        const fileValuesValidate = [...fileValues, ...this.state.fileResponseValues];
        this.updateStateAndValidate({
            fileValues,
            value: fileValuesValidate,
        });
    };

    private getUniqueFileName(fileName: string, existingFileNames: string[]): string {
        let count = 1;
        let uniqueFileName = fileName;
        while (existingFileNames.includes(uniqueFileName)) {
            const fileNameParts = fileName.split('.');
            const extension = fileNameParts.pop();
            const baseName = fileNameParts.join('.');
            uniqueFileName = `${baseName}_${count}.${extension}`;
            count++;
        }
        return uniqueFileName;
    }

    private isValidFileType = (fileType: string, acceptTypes: string[]): boolean => {
        return acceptTypes.some((reg) => new RegExp(reg.trim()).test(fileType.trim()));
    };

    private isFileSelectionDisabled = (): boolean => {
        const maxFiles = this.props.limit;
        return this.state.fileValues.length + this.state.fileResponseValues.length >= maxFiles;
    };

    private onRemoveFile = (file: File): void => {
        const updatedFileValues = [...(this.state.fileValues)];

        const index = updatedFileValues.findIndex(item => item.file === file);

        if (index !== -1) {
            updatedFileValues.splice(index, 1);

            this.props.onValueChange({
                name: this.props.name,
                type: EFormType.File,
                value: updatedFileValues,
            });
            const updatedFileValuesValidate = [...updatedFileValues, ...this.state.fileResponseValues];
            this.updateStateAndValidate({
                fileValues: updatedFileValues,
                value: updatedFileValuesValidate,
            });
        }
    };

    private onNoteChange = (fileValue: IFileValue): void => {
        const updatedFileValues = [...this.state.fileValues];
        const fileToUpdate = updatedFileValues.find(item => item.file === fileValue.file);
        if (fileToUpdate) {
            fileToUpdate.note = fileValue.note;
            this.setState({
                fileValues: updatedFileValues,
            });
        }
    };

    private onNoteResponseChange = (value: IFileResponseValue): void => {
        const updatedFileValues = [...this.state.fileResponseValues];
        const index = updatedFileValues.findIndex(item => compareEncryptFileContent(item, value.file));
        if (index !== -1) {
            updatedFileValues[index].note = value.note;
            this.updateStateAndValidate({
                fileResponseValues: updatedFileValues,
                value: updatedFileValues,
            });
        }
        this.props.onFileNoteResponseChange(
            {
                value: [updatedFileValues[index]],
                name: this.props.name,
                type: this.props.type,
            }
        );
    };

    private onRemoveRespondedFile = (file: IEncryptFileContent): void => {
        const updatedFileValues = [...this.state.fileResponseValues];
        const index = updatedFileValues.findIndex(item => compareEncryptFileContent(item, file));

        if (index !== -1) {
            updatedFileValues.splice(index, 1);
            const updatedFileValuesValidate = [...updatedFileValues, ...this.state.fileValues];
            this.updateStateAndValidate({
                fileResponseValues: updatedFileValues,
                value: updatedFileValuesValidate,
            });
        }
        this.props.onFileResponseRemoved(
            {
                value: file,
                name: this.props.name,
                type: this.props.type,
            }
        );
    };

    private onOpenCamera = (): void => {
        Modal.createDialog(
            CameraDialog,
            {
                onFinished: (result: any) => {
                    if (!result?.isFinished) {
                        return;
                    }
                    if (result?.imageFile) {
                        this.handleAddFile([result?.imageFile] as any);
                    }
                },
            },
            "ctalk_Dialog_camara_main",
        );
    };

    protected renderInput(): React.ReactNode {
        const fileMap = [];
        if (this.state.fileValues.length) {
            for (let i = 0; i < this.state.fileValues.length; i++) {
                const {file} = this.state.fileValues[i];
                fileMap.push(
                    <CFileSelected
                        key={`${this.props.name}_${i}_${file.name}`}
                        previewFileExpiresIn={this.props.preview_file_expires_in}
                        maxNoteLength={this.props.max_note_length}
                        file={file}
                        onNoteChange={this.onNoteChange}
                        onRemoveFile={this.onRemoveFile}
                    />
                );
            }
        }

        const fileResponsesMap = [];
        if (this.state.fileResponseValues.length) {
            for (let i = 0; i < this.state.fileResponseValues.length; i++) {
                const file = this.state.fileResponseValues[i];
                fileResponsesMap.push(
                    <CFileResponded
                        key={`${this.props.name}_${i}_${file.filename}`}
                        file={file}
                        maxNoteLength={this.props.max_note_length}
                        onNoteChange={this.onNoteResponseChange}
                        onRemoveFile={this.onRemoveRespondedFile}
                    />
                );
            }
        }

        const fileCount = fileMap.length + this.state.fileResponseValues.length;
        return (
            <>
                <div className="ctalk_Form_file_accept_info">
                    {
                        _t(
                            "ctalk|bot_form|accept_files",
                            {
                                accept: this.props.allowed_types.join(","),
                                size: this.props.size,
                            }
                        )
                    }
                </div>
                {
                    fileCount > 0
                    && <div className="ctalk_Form_file_line ctalk_Form_file_text ">
                        {
                            fileCount === 1
                            ? _t("ctalk|bot_form|edit_count_selected_file", { count: fileCount})
                            : _t("ctalk|bot_form|edit_plural_count_selected_file", { count: fileCount})
                        }
                    </div>
                }
                <div>
                    {fileResponsesMap}
                    {fileMap}
                </div>
                <div className="ctalk_Form_file_action">
                    {
                        this.props.sources.includes(ESelectFileMethod.CAMERA) &&
                        <button
                            className="ctalk_AccessibleButton mx_AccessibleButton ctalk_File_Selected_Button"
                            onClick={this.onOpenCamera}
                            disabled={this.isFileSelectionDisabled()}
                        >
                            <CameraIcon width="1em" height="1em" className="ctalk_Form_icon" />
                            {_t("ctalk|bot_form|open_camera")}
                        </button>
                    }
                    {
                        this.props.sources.includes(ESelectFileMethod.MEDIA) &&
                        <button
                            className="ctalk_AccessibleButton mx_AccessibleButton ctalk_File_Selected_Button"
                            onClick={this.onSelectFiles}
                            disabled={this.isFileSelectionDisabled()}
                        >
                            <FilesIcon width="1em" height="1em" className="ctalk_Form_icon" />
                            {_t("ctalk|bot_form|edit_select_files")}
                        </button>
                    }

                    <input
                        type="file"
                        accept={this.props.allowed_types.join(",")}
                        ref={this.inputOpenFileRef}
                        style={{display: 'none'}}
                        onClick={chromeFileInputFix}
                        onChange={this.handleFileChange}
                    />
                </div>
            </>
        );
    }
}
