export interface IErrorDownloaded {
    name: string,
    message: string,
    stack: string;
}

export function downloadError(error: IErrorDownloaded): boolean {
    return error.name === "DownloadError" &&
        (
            error.stack.includes("M_NOT_FOUND") ||
            error.message.includes("MatrixError: [404] Not found '/_matrix/media/")
        );
}
