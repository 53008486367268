/*
Copyright 2017 Vector Creations Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { _t, _td } from "matrix-react-sdk/src/languageHandler";
import { ERoomRole } from "@ctalk/enums/room-role.enum";

const ROLE_NOT_EDIT = [ERoomRole.OWNER, ERoomRole.RESTRICTED];

interface OptionShowSelectorRole {
    needToShowOwner?: boolean;
    isRestricted?: boolean;
}

export const RoleLabel = {
    [ERoomRole.OWNER]: _td("CTALK_OWNER"),
    [ERoomRole.ADMIN]: _td("power_level|admin"),
    [ERoomRole.DEFAULT]: _td("CTALK_MEMBER"),
    [ERoomRole.RESTRICTED]: _td("power_level|restricted"),
};

export function levelRoleMap(usersDefault: number, optionsShow: OptionShowSelectorRole = {}): {[key: ERoomRole]: string} {
    // CTalk updated for need to show owner
    const baseMapping = {
        undefined: _t('CTALK_MEMBER'),
        [ERoomRole.DEFAULT]: _t('CTALK_MEMBER'),
        [usersDefault]: _t('CTALK_MEMBER'),
        [ERoomRole.ADMIN]: _t('power_level|admin'),
    };

    if (optionsShow.needToShowOwner) {
        baseMapping[ERoomRole.OWNER] = _t('CTALK_OWNER');
    }

    if (optionsShow.isRestricted) {
        baseMapping[ERoomRole.RESTRICTED] = _t('power_level|restricted');
    }

    return baseMapping;
}

export function textualPowerLevel(level: number, usersDefault: number, optionsShow?: OptionShowSelectorRole): string {
    // CTalk updated for need to show owner
    const LEVEL_ROLE_MAP = levelRoleMap(usersDefault, optionsShow);
    if (LEVEL_ROLE_MAP[level]) {
        return LEVEL_ROLE_MAP[level];
    } else {
        return (
            ROLE_NOT_EDIT.includes(level) ?
                _t(RoleLabel[level.toString()]) : _t("power_level|custom", { level })
        );
    }
}
