import { IContent } from "matrix-js-sdk/src/models/event";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";

const LINK_REGEX = /(https:\/\/|http:\/\/|ftp|sftp|file:\/\/\/|mailto:)[^\s/$.?#].[^\s]*/gi;
const LINK_NOT_COMPLETE_REGEX =
    /(?<=\s|^|>)(?:www\.)?[a-zA-Z0-9.-]+\.(?:com|net|org|gov|edu|mil|co|io|ai|me|tv|jp)\b(?:\/\S*)?/gi;
const MAIL_REGEX = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/gi;
const META_LINK = /(?:https?:\/\/|ftp:\/\/|sftp:\/\/|file:\/\/|mailto:)([^/\s]+)/g;
const REMOVE_TAG_NAMES = ['mx-reply', 'blockquote'];

/**
 * Check content has link
 * @param content
 */
export function hasLinks(content: string): boolean {
    return getLinks(content).length > 0;
}

/**
 * Extracts links from the given content using a regular expression.
 *
 * @param {string} content - The text content from which to extract links.
 * @returns {string[]} An array containing all the links found in the content.
 */
export function getLinks(content: string): string[] {
    const contentFromHtml = removeTagsAndContent(
        content, REMOVE_TAG_NAMES,
    );
    const links = getMatches(LINK_REGEX, contentFromHtml);
    const linksNotComplete = getMatches(LINK_NOT_COMPLETE_REGEX, contentFromHtml);
    const mails = getMatches(MAIL_REGEX, contentFromHtml);
    const result = links.concat(linksNotComplete, mails).filter(
        result => result !== null &&
            // Check link is not mention user
            !result.includes(`${SdkConfig.get("permalink_prefix")}/#/user/@`),
    );
    return [...new Set(result)];
}

export function getMetaLink(link: string): string[] | null {
    if (link.startsWith('file:///')) {
        return [link];
    }

    const regex = /(?:https?:\/\/(?:www\.)?)?([^/\s]+)/g;
    const matches = link.match(regex);

    if (matches) {
        const domains = matches.map(match => match.replace(/^(?:https?:\/\/(?:www\.)?)?/, ''));
        if (domains[0] === 'ftp:') {
            const [, ...result] = domains;
            return result;
        }
        return domains;
    }

    return link.match(META_LINK);
}

/**
 * Checks if a given link is incomplete or lacks a proper protocol (e.g., "http://" or "https://").
 * An incomplete link may be missing the protocol part or have an incomplete domain name.
 *
 * @param link - The link to be checked.
 * @returns `true` if the link is incomplete, `false` otherwise.
 */
export function isLinkNotComplete(link: string): boolean {
    const links = getMatches(LINK_REGEX, link);
    const linksNotComplete = getMatches(LINK_NOT_COMPLETE_REGEX, link);
    return linksNotComplete.length !== 0 && links.length === 0;
}

export function isEmail(link: string): boolean {
    const links = getMatches(MAIL_REGEX, link);
    return !!links.length;
}

/**
 * A utility function that finds all matches of a regular expression in a given text.
 * @param {RegExp} regex - The regular expression to match.
 * @param {string} text - The text in which to search for matches.
 * @returns {Array} - An array containing all matches found, or an empty array if no matches are found.
 */
function getMatches(regex: RegExp, text: string): string[] {
    if (!text) {
        return [];
    }
    const matches = text?.match(regex) || null;
    return matches ? matches : [];
}

/**
 * Check has link but not reply and quote
 * @param content
 */
export function hasLinksNewMessage(content: IContent): boolean {
    const contentFromHtml = removeTagsAndContent(
        content['formatted_body'], REMOVE_TAG_NAMES,
    ) || content.body;
    return hasLinks(contentFromHtml)
        || hasLinks(content['description']);
}

/**
 * Remove the content of HTML tags with the specified tagNames from the text.
 * @param {string} text - The text containing HTML tags.
 * @param {string[]} tagNames - An array of HTML tag names to remove the content from.
 * @returns {string} The text after removing the content of the tags.
 */
export function removeTagsAndContent(text: string, tagNames: string[]): string {
    /**
     * RegExp to find and replace the content of the HTML tags.
     * <tagName ...>...</tagName> will be replaced with an empty string.
     */
    if (!text) {
        return '';
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');

    tagNames.forEach(tagName => {
        const elements = doc.querySelectorAll(tagName);
        elements.forEach(element => {
            element.parentNode.removeChild(element);
        });
    });

    return doc.body.innerHTML.replace(/<[/]?br>/g, ' ');
}
