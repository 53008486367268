import React from "react";
import { ICRadioProps } from "@ctalk/models/bot-form.interface";
import StyledRadioButton from "matrix-react-sdk/src/components/views/elements/StyledRadioButton";
import classNames from "classnames";
import CBaseComponent, { IBaseFormState } from "@ctalk/components/views/messages/form_components/CBaseForm";
import { EFormType } from "@ctalk/enums/bot-form.enum";

interface IRadioValue {
    label: string;
    value: string;
}

interface IState extends IBaseFormState {
    selectedValue: string;
}
export default class CRadio extends CBaseComponent<ICRadioProps, IState> {
    public constructor(props: ICRadioProps) {
        super(props);

        this.state = {
            ...this.state,
            selectedValue: '',
        };
    }

    public componentDidMount(): void {
        const selectedValue = this.props.responseValue?.value ?? '';
        this.updateValue(selectedValue);
    }

    private onChange = (event: React.FormEvent<HTMLInputElement>): void => {
        this.props.onValueChange({
            name: this.props.name,
            type: EFormType.Radio,
            value: event.currentTarget.value,
        });
        this.updateValue(event.currentTarget.value);
    };

    private updateValue(value: string): void {
        this.updateStateAndValidate({
            selectedValue: value,
            value,
        });
    }

    public renderInput(): React.ReactNode {
        const name = this.props.name;
        const itemsClassName = classNames("ctalk_Form_radio_Items");
        return (
            <div>
                {
                    this.props.values.map((option: IRadioValue, index: number) => {
                        const id = `${index}-${name}-${option.value}`;
                        return (
                            <React.Fragment key={option.value}>
                                <StyledRadioButton
                                    key={id}
                                    id={id}
                                    className={
                                        classNames(
                                            itemsClassName,
                                            option.value === this.state.selectedValue
                                                ? "ctalk_Form_radio_Items_Checked"
                                                : undefined
                                        )}
                                    onChange={this.onChange}
                                    checked={option.value === this.state.selectedValue}
                                    name={name}
                                    value={option.value}
                                >
                                    {option.label}
                                </StyledRadioButton>
                            </React.Fragment>
                        );
                    })}
            </div>
        );
    }
}
