export enum UserEvent {
    Username = 'User.username',
    ThreePID = 'User.threePid',
    TwoFactorAuthentication = 'User.twoFactorAuthentication',
    YubiKey = 'User.yubikey',
    Platforms = 'User.platforms',
    IsCheckPlatformFailed = 'User.IsCheckPlatformFailed',
    IsSynapseAdmin = 'User.isSynapseAdmin',
    DeleteRoom = 'User.DeleteRoom',
}
