import React, { useEffect, useState } from "react";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { _t } from "matrix-react-sdk/src/languageHandler";
// material-ui
import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    ThemeProvider,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import { Icon as EditIcon } from "matrix-react-sdk/res/img/element-icons/room/message-bar/edit.svg";
import { Icon as TrashcanIcon } from "matrix-react-sdk/res/img/element-icons/trashcan.svg";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";
import Modal from "matrix-react-sdk/src/Modal";
import LabelledToggleSwitch from "matrix-react-sdk/src/components/views/elements/LabelledToggleSwitch";
import SettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/SettingsTab";
import { SettingsSection } from "matrix-react-sdk/src/components/views/settings/shared/SettingsSection";
import { SETTING_ROOM_KEYS } from "@ctalk/constants";
import { SettingValues } from "@ctalk/interfaces/rooms/IRoomWhiteListIps";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";
import { EErrorType } from "@ctalk/enums/network.enum";

import muiTheme from "../../../../../../../customisations/helpers/MuiThemeCustom";
import WhitelistedIPRoomSettingsDialog from "./WhitelistedIPRoomSettingsDialog";

export enum ETypeDialog {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
}

interface IProps {
    client: MatrixClient;
    roomId: string;
    closeSettingsFn: () => void;
}

const DEFAULT_ROWS_PER_PAGE = 10;

export default function WhitelistedIPRoomSettingsTab(props: IProps): React.FC<IProps> {
    const { client, roomId } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [loading, setLoading] = useState({
        list: true,
        config: true,
    });
    const [reload, setReLoad] = useState(false);

    const [query, setQuery] = useState("");
    const [dataFilter, setDataFilter] = useState([]);

    const [pageCount, setPageCount] = useState(0);

    // Fetch data
    const [rows, setRows] = useState([]);
    const [enableConfig, setEnableConfig] = useState({} as SettingValues);
    const [errorType, setErrorType] = useState(EErrorType.None);

    const ckClient = CTalkClientPeg.get();

    useEffect(() => {
        setLoading({
            list: true,
            config: true,
        });
        const whiteListIps = ckClient.getRoomWhiteListIps(roomId);
        const config = ckClient.getRoomSetting(roomId, SETTING_ROOM_KEYS.ALLOW_CONFIG_WL_IP);
        Promise.all([whiteListIps, config])
            .then((results) => {
                setRows(results[0].items);
                setDataFilter(results[0].items);
                setEnableConfig({ ...results[1].value });
            }).catch((error) => {
                if (error.errcode == 'M_FORBIDDEN') {
                    setErrorType(EErrorType.Forbidden);
                }
            }).finally(() => {
                setLoading({
                    list: false,
                    config: false,
                });
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading({
            ...loading,
            list: true,
        });
        if (reload) {
            setReLoad(false);
            ckClient.getRoomWhiteListIps(roomId).then((res) => {
                setRows(res.items);
                setDataFilter(res.items);
                setPage(0);
                setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
                setQuery("");
                setLoading({
                    ...loading,
                    list: false,
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        setPage(0);
        setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
        if (query) {
            setDataFilter(rows.filter((item) =>
                item.ip.toLowerCase().includes(query.toLowerCase()) ||
                item.name.toLowerCase().includes(query.toLowerCase()),
            ));
        } else {
            setDataFilter(rows);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        setPageCount(Math.ceil(dataFilter?.length / rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFilter, rowsPerPage]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        setRowsPerPage(parseInt(event?.target.value!, 10));
        setPage(0);
    };

    const onOpenDialog = (typeOpen: ETypeDialog, recordUpdate?: any): void => {
        Modal.createDialog(WhitelistedIPRoomSettingsDialog, {
            client,
            typeOpen,
            roomId,
            recordUpdate,
            onFinished: (success) => {
                if (success) {
                    setReLoad(true);
                }
            },
        });
    };

    const onHandleChangeConfig = async (checked): Promise<void> => {
        setLoading({
            ...loading,
            config: true,
        });
        ckClient.changeRoomSetting(roomId, SETTING_ROOM_KEYS.ALLOW_CONFIG_WL_IP, {
            value: checked,
        }).then(() => {
            setEnableConfig({ ...enableConfig, inRoom: checked });
        }).catch((e) => {
            if (e.errcode === "M_CANNOT_CHANGE_CONFIG_WHITELISTED_IP") {
                ckClient.getRoomSetting(roomId, SETTING_ROOM_KEYS.ALLOW_CONFIG_WL_IP)
                    .then((res) => {
                        setEnableConfig({ ...res.value });
                    });
            }
            console.log(e);
        }).finally(() => {
            setLoading({
                ...loading,
                config: false,
            });
        });
    };

    if (errorType !== EErrorType.None) {
        let content;
        switch (errorType) {
            case EErrorType.Forbidden:
                content = (
                    <div className="error">
                        { _t("CTALK_ACCESS_DENIED_CONTACT_OWNER") }
                    </div>
                );
                break;
                // Can handle other error case;
            default:
                content = <></>;
        }
        return (
            <SettingsTab>
                <SettingsSection heading={_t("CTALK_WHITELISTED_IPS")}>
                    <div className="mx_WhiteListedIPs_item_action">
                        { content }
                    </div>
                </SettingsSection>
            </SettingsTab>
        );
    }

    return (
        <SettingsTab>
            <SettingsSection heading={_t("CTALK_WHITELISTED_IPS")}>
                <div className="mx_WhitelistedIPsRoomSettingsTab">
                    {/*<div className="mx_SettingsTab_heading">{ _t("CTALK_WHITELISTED_IPS") }</div>*/}
                    <div className="mx_WhiteListedIPs_item_action">
                        <div className="ctalk_toggle_enable">
                            { _t("CTALK_WHITELISTED_IPS_ENABLE") }
                            {
                                loading.config ? <InlineSpinner />
                                    :
                                    <div>
                                        <LabelledToggleSwitch
                                            value={enableConfig?.inRoom}
                                            label=""
                                            onChange={(checked): Promise<void> => onHandleChangeConfig(checked)}
                                            disabled={!enableConfig.global}
                                        />
                                    </div>
                            }
                        </div>
                        <div className="ctalk_search_add_btn">
                            <div className="mx_SearchBox mx_textinput">
                                <input
                                    placeholder={_t("CTALK_WHITELISTED_IPS_SEARCH_PLACEHOLDER")}
                                    key="searchfield"
                                    type="text"
                                    className="mx_textinput_icon mx_textinput_search"
                                    autoComplete="off"
                                    value={query}
                                    onChange={(e): void => setQuery(e.target.value)}
                                />
                            </div>
                            <AccessibleButton
                                kind='primary'
                                onClick={(): void => onOpenDialog(ETypeDialog.CREATE)}
                                className='mx_AddWhiteListedIPs_button'
                            >
                                { _t("CTALK_WHITELISTED_IPS_ADD_IP") }
                            </AccessibleButton>
                        </div>
                    </div>
                    {
                        loading.list && <div className="mx_WhiteListedIPs-loading">
                            <InlineSpinner w={39} h={39} />
                        </div>
                    }
                    {
                        !loading.list && <ThemeProvider theme={muiTheme}>
                            <TableContainer sx={{ color: 'inherit', marginTop: '20px' }}>
                                <Table sx={{ minWidth: 350, color: 'inherit' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{ _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_NAME") }</TableCell>
                                            <TableCell sx={{ pl: 3 }}>{ _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP") }</TableCell>
                                            <TableCell align="center">{ _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_CREATED_AT") }</TableCell>
                                            <TableCell align="right" sx={{ pr: 7 }}>
                                                { _t("CTALK_WHITELISTED_IPS_ACTIONS") }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { dataFilter
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <TableRow hover key={index}>
                                                    <TableCell>{ row.name }</TableCell>
                                                    <TableCell sx={{ pl: 3 }}>{ row.ip }</TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            new Date(row.createdAt).toLocaleString()
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ pr: 0 }}>
                                                        <Stack direction="row" justifyContent="right" alignItems="right">
                                                            <IconButton
                                                                color="inherit"
                                                                size="small"
                                                                onClick={(): void => onOpenDialog(ETypeDialog.EDIT, row)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={(): void => onOpenDialog(ETypeDialog.DELETE, row)}
                                                            >
                                                                <TrashcanIcon />
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            )) }
                                        {
                                            !rows.length && (
                                                <TableRow
                                                    style={{
                                                        height: 50,
                                                    }}
                                                >
                                                    <TableCell colSpan={4} align="center">
                                                        { _t("CTALK_WHITELISTED_IPS_NO_ROWS") }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        {
                                            Boolean(query && !dataFilter.length && rows.length) && (
                                                <TableRow
                                                    style={{
                                                        height: 50,
                                                    }}
                                                >
                                                    <TableCell colSpan={4} align="center">
                                                        {_t("common|no_results_found")}.
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={dataFilter.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Rows per page:"
                                labelDisplayedRows={({ from, to, count }): void => `${from}-${to} of ${count}`}
                                ActionsComponent={(props): void => (
                                    <MuiPagination
                                        count={pageCount}
                                        page={page + 1}
                                        onChange={(event, newPage): void => {
                                            props.onPageChange(null, newPage - 1);
                                        }}
                                    />
                                )}
                            />
                        </ThemeProvider>
                    }
                </div>
            </SettingsSection>
        </SettingsTab>

    );
}
