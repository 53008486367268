export enum ERoomMessageEventType {
    LINK = 'link',
    GIF = 'gif',
    MEDIA = 'media',
    FILE = 'file',
}

export enum ERoomEventInfo {
    CTALK_LINK = 'ctalk.room.link',
}

export interface IRoomMessageEventDto {
    eventId: string;
    roomId: string;
    type: ERoomMessageEventType;
}

export interface IGetWithPaginationDto {
    roomId: string;
    type: ERoomMessageEventType;
    from: number;
    to: number;
}

export interface RoomMessageEvent {
    eventId: string;
    roomId: string;
    type: ERoomMessageEventType;
    senderId: string;
    createdAt: string;
}

export interface IGetWithPaginationRo {
    items: RoomMessageEvent[];
    // from: number;
    // to: number;
    total: number;
}
