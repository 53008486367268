import SettingsStore from 'matrix-react-sdk/src/settings/SettingsStore';
import { SettingLevel } from 'matrix-react-sdk/src/settings/SettingLevel';
import { isHighContrastTheme } from 'matrix-react-sdk/src/theme';
import { FontWatcher } from 'matrix-react-sdk/src/settings/watchers/FontWatcher';

export function setDeviceSettings(key: string, value: any): Promise<void> {
    return SettingsStore.setValue(key, null, SettingLevel.DEVICE, value);
}

export function getDeviceSettings<T>(key: string): T {
    return SettingsStore.getValue(key, null);
}

export const isUserOnHighContrastTheme = (): boolean => {
    if (SettingsStore.getValue("use_system_theme")) {
        return window.matchMedia("(prefers-contrast: more)").matches;
    } else {
        const theme = SettingsStore.getValue("theme");
        if (theme.startsWith("custom-")) {
            return false;
        }
        return isHighContrastTheme(theme);
    }
}

export function setCustomEmojiFont(): void {
    setTimeout(() => {
        document.body.style.setProperty(
            FontWatcher.EMOJI_FONT_FAMILY_CUSTOM_PROPERTY,
            FontWatcher.BUNDLED_EMOJI_FONT,
        );
    }, 250);
}
