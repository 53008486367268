
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@vector-im/compound-web';
import dis from 'matrix-react-sdk/src/dispatcher/dispatcher';
import { _t } from 'matrix-react-sdk/src/languageHandler';
import {
    EventTimeline,
    EventType,
    MatrixClient,
    MatrixEvent,
    Room,
    RoomStateEvent,
} from 'matrix-js-sdk/src/matrix';
import { RoomSettingsTab } from 'matrix-react-sdk/src/components/views/dialogs/RoomSettingsDialog';
import { useTypedEventEmitter } from 'matrix-react-sdk/src/hooks/useEventEmitter';
import { hasPermission } from '@ctalk/roles/PermissionView';
import { ECTalkRoomStateEvent } from '@ctalk/enums/event-type.enum';
import { msToShortTimeStr, msToTimeString } from '@ctalk/utils/auto-delete';

import { getPowerLevels } from '../../../components/views/right_panel/UserInfo';

interface IProps {
    key: string;
    room: Room;
    userId: string;
    hoverAble?: boolean;
    clickAble?: boolean;
}

interface IRoomRetentionContent {
    max_lifetime?: number | null;
}

interface IRoomAutoDelete {
    deleted?: number;
    expiredTs?: number;
}

export const getAutoDeleteState = (room?: Room): IRoomAutoDelete =>
    room?.getLiveTimeline()
        .getState(EventTimeline.FORWARDS)
        ?.getStateEvents(ECTalkRoomStateEvent.AutoDelete, '')
        ?.getContent() || {};

export const getRetention = (room?: Room): IRoomRetentionContent =>
    room?.getLiveTimeline()
        .getState(EventTimeline.FORWARDS)
        ?.getStateEvents(ECTalkRoomStateEvent.RoomRetention, "")
        ?.getContent() || {};

export const useRoomRetention = (cli: MatrixClient, room: Room): IRoomRetentionContent => {
    const [retention, setRetention] = useState<IRoomRetentionContent>(getRetention(room));

    const update = useCallback(
        (ev?: MatrixEvent) => {
            if (!room) return;
            if (ev && ev.getType() !== EventType.RoomPowerLevels) return;
            setRetention(getRetention(room));
        },
        [room],
    );

    useTypedEventEmitter(cli, RoomStateEvent.Events, update);
    useEffect(() => {
        update();
        return () => {
            setRetention({});
        };
    }, [update]);
    return retention;
};

export function isRoomEnableRetention(retention: IRoomRetentionContent): boolean {
    return Number(retention.max_lifetime ?? 0) > 0;
}

export class AutoDeleteBadge extends React.Component<IProps, any> {
    public render(): React.ReactNode {
        const retention = getRetention(this.props.room);
        const maxLifetime = retention?.max_lifetime;
        if (!maxLifetime) {
            return null;
        }
        const hasPermissionAutoDelete = hasPermission(
            this.props.room.getMember(this.props.userId),
            getPowerLevels(this.props.room),
            'state_default',
        );
        const autoDeleteLabel = msToShortTimeStr(maxLifetime);

        const badge = <button
            className={classNames("ctalk_AutoDeleteMessage_label", {
                hover: this.props.hoverAble,
                click: hasPermissionAutoDelete && this.props.clickAble,
            })}
            onClick={(): void => {
                if (!hasPermissionAutoDelete) {
                    return;
                }
                dis.dispatch({
                    action: "open_room_settings",
                    room_id: this.props?.room.roomId,
                    initial_tab_id: RoomSettingsTab.Security
                });
            }}
            aria-label={_t("action|search")}
        >
            <span
                // Add dynamic class for auto delete label length
                className={`ctalk_AutoDeleteMessage_label_size_${autoDeleteLabel.length}`}
            >
                {autoDeleteLabel}
            </span>
        </button>;
        if (this.props.clickAble) {
            return <Tooltip
                label={_t(
                    "ctalk|settings|security|auto_delete_messages|label",
                    { value: msToTimeString(+maxLifetime) },
                )}
                side="left">
                {badge}
            </Tooltip>;
        }
        return badge;
    }
}
