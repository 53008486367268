import React, { ReactNode } from "react";
import { _t } from "matrix-react-sdk/src/languageHandler";
import PlatformPeg from "matrix-react-sdk/src/PlatformPeg";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { formatSeconds } from "matrix-react-sdk/src/DateUtils";

import GlobalToastStore from "../stores/GlobalToastStore";
import "../../res/css/toast/ForceUpdateToast.pcss";

export const FORCE_UPDATE_TOAST_KEY = "ctalk_ForceUpdate";

interface IProps {
    description: ReactNode;
    detail?: ReactNode;
    acceptLabel: string;
    dismissLabel?: string;
    onAccept(): void;
    onDismiss?(): void;
    timeout: number; // in ms
    forceUpdate: boolean;
}

interface IState {
    remainingTime: number;
}

const COUNT_DOWN_EVERY = 1000; // 1 second

export default class ForceUpdateToast extends React.Component<IProps, IState> {
    protected countDownInterval: number;
    public constructor(props: IProps) {
        super(props);
        this.state = {
            remainingTime: this.props.timeout,
        };
    }

    public handleAccept = (): void => {
        clearInterval(this.countDownInterval);
        this.props.onAccept();
    };

    protected handleDismiss = (): void => {
        clearInterval(this.countDownInterval);
        this.props.onDismiss?.();
    };


    public startCountDown = (): void => {
        this.countDownInterval = setInterval(() => {
            if (this.state.remainingTime <= 0) {
                this.handleAccept();
                return;
            }
            // else
            this.setState({
                remainingTime: this.state.remainingTime - COUNT_DOWN_EVERY,
            });
        }, COUNT_DOWN_EVERY); // count down every 1s
    };

    public componentDidMount(): void {
        this.startCountDown();
    }

    public render(): React.ReactNode {
        const seconds = this.state.remainingTime / 1000;
        const timoutLabel = _t("CTALK_COUNT_DOWN_AUTO_UPDATE", {
            remaining: seconds >= 60 ? formatSeconds(seconds) : seconds,
        });
        const detailContent = this.props.detail ? <div className="mx_Toast_detail">
            { this.props.detail }
        </div> : null;

        let leftArea;
        if (this.props.forceUpdate) {
            leftArea = <div className="warning ctalk_ForceUpdate_remaining">
                { timoutLabel }
            </div>;
        } else {
            leftArea = <AccessibleButton
                onClick={this.handleDismiss}
                kind="danger_outline"
                className="ctalk_ForceUpdate_UpdateNow">
                { this.props.dismissLabel }
            </AccessibleButton>;
        }

        return <div>
            <div className="mx_Toast_description">
                { this.props.description }
               {detailContent}
            </div>
            <div className="mx_Toast_buttons" aria-live="off">
                {leftArea}
                <AccessibleButton onClick={this.handleAccept} kind="primary" className="ctalk_ForceUpdate_UpdateNow">
                    { this.props.acceptLabel }
                </AccessibleButton>
            </div>
        </div>;
    }
}
function setDismiss(): void {
    localStorage.setItem("ck_dismiss_force_update", JSON.stringify(new Date().getTime()));
}

function getLatestDismiss(): Date | null | void {
    const timestamp = localStorage.getItem("ck_dismiss_force_update");
    if (!timestamp) {
        return;
    }
    return new Date(+timestamp);
}

/**
 * Check user dismiss update is before 24h
 * @param dismissDate
 */
function isDismissTooShort(dismissDate: Date | null | void): boolean {
    if (!dismissDate) {
        return false;
    }
    const now = new Date();
    return ((now.getTime() - dismissDate.getTime()) - 86400000) < 0;
}

function installUpdate(): void {
    PlatformPeg.get()?.installUpdate();
}

function hideUpdate(): void {
    setDismiss();
    GlobalToastStore.instance().dismissToast(FORCE_UPDATE_TOAST_KEY);
}

export const showForceUpdateToast = async (currentVersion: string, newVersion: string): Promise<void> => {
    if (newVersion === '!!UNSET!!') {
        return;
    }
    if (isDismissTooShort(getLatestDismiss())) {
        return;
    }
    await window.ckAppSettingStore.fetchSettings(true);
    GlobalToastStore.instance().addOrReplaceToast({
        key: FORCE_UPDATE_TOAST_KEY,
        title: _t("CTALK_NEW_VERSION_AVAILABLE"),
        props: {
            description: _t("CTALK_PLEASE_UPDATE_APP"),
            acceptLabel: _t("action|update"),
            dismissLabel: _t("action|dismiss"),
            onAccept: installUpdate,
            onDismiss: hideUpdate,
            timeout: 60000, // in ms
            // This will cause deps cycle, we use window.ckAppSettingStore instead of AppSettingStore.instance()
            forceUpdate: window.ckAppSettingStore.get<boolean>("ENABLE_FORCE_UPDATE_APP"),
        },
        component: ForceUpdateToast,
        priority: 1000,
    });
};

export const hideToast = (): void => {
    GlobalToastStore.instance().dismissToast(FORCE_UPDATE_TOAST_KEY);
};
