import { IContent } from "matrix-js-sdk/src/models/event";
import { CTalkRelatesToKeys } from "@ctalk/constants";
import { Room } from "matrix-js-sdk/src/matrix";

const DEACTIVATED_NAME = "Deactivated User";

export function isDesktopMode(): boolean {
    return window.navigator.userAgent.indexOf('Electron') > 0;
}

export function isSafari(): boolean {
    return window.navigator.vendor?.indexOf('Apple') > -1 &&
        window.navigator.userAgent?.indexOf('CriOS') == -1 &&
        window.navigator.userAgent?.indexOf('FxiOS') == -1;
}

export function shortenId(id: string): string {
    return id && id.substring(
        0,
        id.indexOf(':') > 0 && !id.includes(' ') ?
            id.indexOf(':') :
            id.length,
    );
}

export function shortenUserId(input: string | undefined): string {
    return typeOfUserId(input) ? prefixOfId(input) : input;
}

export function shortenRoomId(input: string | undefined): string {
    return typeOfRoomId(input) ? prefixOfId(input) : input;
}

export function typeOfUserId(input: string): boolean {
    return !!input && input.startsWith('@') && input.indexOf(':') > 0;
}

export function typeOfRoomId(input: string): boolean {
    return !!input && (input.startsWith('#') || input.startsWith('!')) && input.indexOf(':') > 0;
}

export function prefixOfId(input: string): string {
    return input?.match(/([^:]+)/)[0];
}

export function isBotUser(userId: string): boolean {
    return !!userId && userId.startsWith('@bot') && userId.indexOf(':') > 0;
}

export function isServerNoticeUser(userId: string): boolean {
    return !!userId && userId.startsWith('@server') && userId.indexOf(':') > 0;
}

export function getForwardedEventInfo(content: IContent): string | undefined {
    return content[CTalkRelatesToKeys.C_RELATES_TO]?.[CTalkRelatesToKeys.C_FORWARDED_FROM];
}

export function isDeactivatedRoom(room: Room): boolean{
    return room.name === DEACTIVATED_NAME;
}
