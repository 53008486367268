export enum SettingStoreEvent {
    allowCreatePublicRoom = "Settings.allowCreatePublicRoom",
    allowCreateSpace = "Settings.allowCreateSpace",
    isKeyVerified = "Settings.isKeyVerified",
    isSSOLogin = "Settings.isSSOLogin",
    blockNonAdminInvites = "Settings.blockNonAdminInvites",
    roomJitsiCallDirectState = "Settings.RoomJitsiCallDirectState",
    isCheckPlatformFailed = "Settings.isCheckPlatformFailed",
    reactionsMenuFrequentlyUsed = "Settings.ReactionsMenuFrequentlyUsed",
    uploadedChatBackground = "Settings.uploadedChatBackground",
    chatBackground = "Settings.desktopChatBackground",
}

export enum AppSettings {
    ENABLE_FORCE_UPDATE_APP = "ENABLE_FORCE_UPDATE_APP",
    FORCE_UPDATE_APP_SYNCHRONIZATION = 'FORCE_UPDATE_APP_SYNCHRONIZATION',
    PLATFORM_NUMBER_OF_RETRY_CHECKS = 'PLATFORM_NUMBER_OF_RETRY_CHECKS',
    PLATFORM_PERIOD_RETRY_CHECKS = 'PLATFORM_PERIOD_RETRY_CHECKS',
}
