import React from "react";
import { EventType, MatrixEvent } from "matrix-js-sdk/src/matrix";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { compactEncryptFileContent, IEncryptFileContent } from "@ctalk/helpers/BotFormHelper";
import { ECustomMsgType } from "@ctalk/enums/custom-event";
import { IFileValue } from "@ctalk/components/views/messages/form_components/CFile";
import { IBotFormValue } from "@ctalk/models/bot-form.interface";
import { EFormRelType, EFormStatus, EFormType } from "@ctalk/enums/bot-form.enum";

import { _t } from "../../../languageHandler";
import BaseDialog from "../../../components/views/dialogs/BaseDialog";
import ContentMessages from "../../../ContentMessages";

enum EProgress {
    None,
    EncryptAndUpload,
    Sending,
    Done,
}

interface ILinearProgressProps {
    total: number;
    processed: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILinearProgressState {}

class LinearProgressWithLabel extends React.Component<ILinearProgressProps, ILinearProgressState> {
    public constructor(props: ILinearProgressProps) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        const progressValue = Math.round((this.props.processed / this.props.total) * 100);

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    {/* handle theme for % */}
                    <Typography
                        variant="body2"
                        className="ctalk_Submit_Progressing_percent"
                    >
                        {`${progressValue}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
}

enum EErrorStatus {
    None = "",
    UploadFileError = "UploadFileError",
    SentMessageError = "SentMessageError",
}

interface IProps {
    mxEvent: MatrixEvent;
    dataForm: IBotFormValue[];
    dataResponse?: IBotFormValue[];
    onFinished: (success: boolean) => void;
}

interface IState {
    dataForm: IBotFormValue[];
    progressValue: number;
    progressTotal: number;
    step: EProgress;
    errorStatus: EErrorStatus;
}

export default class SubmitProgressingDialog extends React.Component<IProps, IState> {
    private readonly client: MatrixClient;

    public constructor(props: IProps) {
        super(props);
        this.client = MatrixClientPeg.get()!;
        this.state = {
            dataForm: this.props.dataForm,
            progressValue: 0,
            progressTotal: 1,
            step: EProgress.None,
            errorStatus: EErrorStatus.None,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.handleSubmitForm();
    }

    public componentWillUnmount(): void {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    private onCancel = (): void => {
        this.props.onFinished(false);
    };

    private handleBeforeUnload = (event: any): string => {
        event.preventDefault();
        return event.returnValue = _t("ctalk|common|sure_to_close");
    };

    private async handleSubmitForm(): Promise<void> {
        const { dataForm, dataResponse } = this.props;
        if (dataResponse && dataResponse.length) {
            this.retrySubmitResponse(dataForm, dataResponse);
        } else {
            this.submitNewResponse(dataForm);
        }
    }

    private handleProgressTotal(fileValues: IBotFormValue[]): void {
        let total = 2; // Step share key and encrypt messages
        fileValues.forEach(fileValue => {
            total+= fileValue.value?.length || 0;
        });
        this.setState({
            progressTotal: total,
        });
    }

    private updateProgress(): void {
        this.setState(prevState => ({
            progressValue: prevState.progressValue + 1,
        }));
    };

    private updateStep(step: EProgress): void {
        this.setState(prevState => ({
            step: step,
        }));
    }

    private stepStatus(): string {
        let content;
        switch (this.state.step) {
            case EProgress.Sending:
                content = _t("ctalk|bot_form|step|sending");
                break;
            case EProgress.EncryptAndUpload:
                content = _t("ctalk|bot_form|step|encrypt_upload");
                break;
            case EProgress.Done:
                content = _t("ctalk|bot_form|step|done");
                break;
            default:
                content = "";
                break;
        }
        return content;
    }

    private onFinished(closeAllModal = true): void {
        setTimeout(() => {
            this.props.onFinished(closeAllModal);
        }, 3000);
    }

    private async onSendEvent (dataSubmit: IBotFormValue[]): Promise<void> {
        const event = this.props.mxEvent;
        const roomId = event.getRoomId();
        const userId = event.getSender();
        if (!roomId || !userId) {
            // TODO handle error, close modal
            return;
        }
        this.updateStep(EProgress.Sending);
        await this.client.sendSharedHistoryKeys(roomId, [userId]);
        this.updateProgress();
        await this.client.sendEvent(
            roomId,
            EventType.RoomMessage,
            {
                msgtype: ECustomMsgType.CForm,
                status: EFormStatus.SUBMITTING,
                request_id: event.getContent().request_id,
                response: dataSubmit,
                'm.relates_to': {
                    rel_type: EFormRelType.FormUpdate,
                    event_id: event.getId(),
                    status: EFormStatus.SUBMITTING,
                }
            },
        );
        this.updateProgress();
        this.updateStep(EProgress.Done);
    }

    private async submitNewResponse(dataForm: IBotFormValue[]): Promise<void> {
        const fileFormValues = dataForm.filter(item => item.type === EFormType.File) as IBotFormValue[];
        const newDataForm = [...dataForm];
        const uploadPromises = [];
        this.handleProgressTotal(fileFormValues);
        this.updateStep(EProgress.EncryptAndUpload);
        if (fileFormValues.length > 0) {
            for (const fileFormValue of fileFormValues) {
                const uploadPromise = this.handleUploadFiles(fileFormValue.value as IFileValue[]).then(dataUploaded => {
                    const indexToUpdate = newDataForm.findIndex(item =>
                        item.type === EFormType.File && item.name === fileFormValue.name);
                    if (indexToUpdate !== -1) {
                        newDataForm[indexToUpdate] = { ...newDataForm[indexToUpdate], value: dataUploaded };
                    }
                });
                uploadPromises.push(uploadPromise);
            }
            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                this.setState({ errorStatus: EErrorStatus.UploadFileError });
                this.onFinished(false);
                return;
            }
        }
        try {
            // Because share key and encrypt + sent event
            await this.onSendEvent(newDataForm);
            this.onFinished();
        } catch (error) {
            this.setState({ errorStatus: EErrorStatus.SentMessageError });
            this.onFinished(false);
        }
    }

    private async retrySubmitResponse(dataForm: IBotFormValue[], dataResponse: IBotFormValue[]): Promise<void> {
        const fileFormNewValues = dataForm.filter(
            item => item.type === EFormType.File && item.value.length > 0
        ) as IBotFormValue[];
        const fileFormResponseValues = dataResponse.filter(
            item => item.type === EFormType.File && item.value.length > 0
        ) as IBotFormValue[];
        const copiedDataResponse = dataResponse.map(item => {
            if (item.type === EFormType.File && item.value.length === 0) {
                return null;
            }
            return { ...item }
        }).filter(Boolean) as NonNullable<IBotFormValue>[];
        const newDataForm = this.mergeObjects(copiedDataResponse, [...dataForm]);
        this.handleProgressTotal(fileFormNewValues);
        const uploadPromises = [];
        if (fileFormNewValues.length > 0) {
            for (const fileFormValue of fileFormNewValues) {
                const uploadPromise = this.handleUploadFiles(fileFormValue.value as IFileValue[]).then(dataUploaded => {
                    const indexToUpdate = newDataForm.findIndex(item =>
                        item.type === EFormType.File && item.name === fileFormValue.name);
                    if (indexToUpdate !== -1) {
                        const oldValue = fileFormResponseValues.find(x => x.name === fileFormValue.name)?.value ?? [];
                        newDataForm[indexToUpdate] = { ...newDataForm[indexToUpdate], value: [...oldValue, ...dataUploaded] };
                    }
                });
                uploadPromises.push(uploadPromise);
            }
            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                this.setState({ errorStatus: EErrorStatus.UploadFileError });
                this.onFinished(false);
                return;
            }
        }
        try {
            // Because share key and encrypt + sent event
            await this.onSendEvent(newDataForm);
            this.onFinished();
        } catch (error) {
            this.setState({ errorStatus: EErrorStatus.SentMessageError });
            this.onFinished(false);
        }
    }

    private mergeObjects(arrOldObj: IBotFormValue[], arrNewObj: IBotFormValue[]): IBotFormValue[] {
        const mergedArray: IBotFormValue[] = [...arrOldObj];

        arrNewObj.forEach(newObj => {
            if (newObj.name !== EFormType.File) { // Ignore with type is file
                const indexToUpdate = mergedArray.findIndex(obj => obj.name === newObj.name);
                if (indexToUpdate !== -1) {
                    mergedArray[indexToUpdate].value = newObj.value;
                } else {
                    mergedArray.push(newObj);
                }
            }
        });

        return mergedArray;
    }

    private async handleUploadFiles(fileVales: IFileValue[]): Promise<IEncryptFileContent[]> {
        const uploadPromises = fileVales.map(async (item) => {
            const data =
                await ContentMessages.sharedInstance()
                    .uploadFileForBorForm(item.file, this.props.mxEvent.getRoomId()!, this.client!);
            const convertData = compactEncryptFileContent(data, item);
            return convertData;
        });

        try {
            const dataFiles = await Promise.all(uploadPromises.map(promise => {
                return promise.then(result => {
                    this.updateProgress();
                    return result;
                }).catch(error => {
                    throw error;
                });
            }))
            // Proceed with any further processing here
            return dataFiles.filter((data) => data !== undefined) as IEncryptFileContent[];
        } catch (error) {
            throw error;
        }
    }

    private getErrorMessage = (): string => {
        let errorMessage;
        switch (this.state.errorStatus) {
            case EErrorStatus.SentMessageError:
                errorMessage = _t("ctalk|bot_form|error|submit_response_failed");
                break;
            case EErrorStatus.UploadFileError:
                errorMessage = _t("ctalk|bot_form|error|upload_failed");
                break;
            default:
                errorMessage = "";
                break;
        }
        return errorMessage;
    }

    public render(): React.ReactNode {
        return (
            <BaseDialog
                onFinished={this.onCancel}
                title={_t("ctalk|bot_form|processing_title")}
                className="ctalk_Submit_Progressing"
                hasCancel={false}
            >
                {
                    this.state.errorStatus === EErrorStatus.None ?
                    <>
                        <div className="ctalk_Submit_Progressing_status">
                            {this.stepStatus()}
                        </div>
                        <div className="ctalk_Linear_Progress">
                            <LinearProgressWithLabel
                                total={this.state.progressTotal}
                                processed={this.state.progressValue}
                            />
                        </div>
                    </> :
                    <div
                        className="ctalk_Submit_Progressing_error"
                    >
                        {this.getErrorMessage()}
                    </div>
                }

            </BaseDialog>
        );
    }
}
