import axios, { AxiosResponse } from "axios";
import * as utils from "matrix-js-sdk/src/utils";
import { logger } from "matrix-js-sdk/src/logger";
import { HttpService } from "@ctalk/http/http.service";
import { EnableTwoFactorRO, GetMultiFactorRO, VerifyTwoFactorRO } from "@ctalk/interfaces/stores/IUserProfile";
import { ISetupYubikeyFlowDTO, ISetupYubikeyFlowRO } from "@ctalk/interfaces/settings/ISetupYubikeyFlow";
import {
    IAddEmailOTPRequest,
    IAddEmailOTPVerify,
    IAddPhoneOTPRequest,
    IAddPhoneOTPVerify, IRemoveEmailVerify, IRemoveEmailVerifyTwoFactor,
    IRemoveEmailViaEmailOTPRequest,
    IRemovePhoneRequest,
    IRemovePhoneVerify,
    IRemovePhoneVerifyTwoFactor,
} from "@ctalk/interfaces/auth/IThreePID";
import { ICallConfig } from "@ctalk/interfaces/rooms/ICallConfig";
import { IConfigByKey } from "@ctalk/interfaces/settings/IConfigByKey";
import { UserSettingsRO } from "@ctalk/@types/user-setting.ro";
import { IShareProfileResignedUrl, IShareProfileViaKey } from "@ctalk/interfaces/IUserActions";
import {
    ERoomMessageEventType,
    IGetWithPaginationDto, IGetWithPaginationRo, IRoomMessageEventDto,
    RoomMessageEvent,
} from "@ctalk/interfaces/rooms/IRoomMessageEvent";
import { convertDtoToQueryString } from "@ctalk/helpers/StringHelper";
import {
    CreateRoomWhiteListIp, GetRoomSettings,
    RoomWhiteListIps,
    UpdateRoomWhiteListIp,
} from "@ctalk/interfaces/rooms/IRoomWhiteListIps";
import { IWhiteListedIps } from "@ctalk/interfaces/settings/IAllowWhiteList";
import { TransferOwnerDto } from '@ctalk/interfaces/rooms/IRoomsApi';
import { IResubmitDto } from "@ctalk/interfaces/rooms/IBotForm";
import { ICommonRoomDto, ICommonRoomRo } from "@ctalk/interfaces/rooms/ICommonRoom";

export class CTalkClient {
    protected http: HttpService;

    public constructor() {
        this.http = new HttpService();
    }

    /**
     * Get User settings
     * @returns
     */
    public getUserSettings(): Promise<UserSettingsRO> {
        return this.http.get("user/settings");
    }

    /**
     * API update username
     * @param username
     * @returns
     */
    public async updateUsername(username: string): Promise<any> {
        const dto = {
            username: username.replace("@", ""),
        };
        return await this.http.post("user/username", dto);
    }

    /**
     * API check username existed
     * @param username
     * @returns
     */
    public async checkUsernameExisted(username: string): Promise<AxiosResponse> {
        const dto = {
            username: username.replace("@", ""),
        };
        return await this.http.post("user/username/existed", dto);
    }

    /**
     * API get pre-signed-url to share profile
     * @returns
     */
    public async getPreSignedUrl(): Promise<IShareProfileResignedUrl> {
        const { data } = await this.http.post<IShareProfileResignedUrl>("share/presigned-url", {});
        return data;
    }

    /**
     * API get user info from key in pre-signed-url
     * @returns
     */
    public async getUserInfo(shareToken: string): Promise<IShareProfileViaKey> {
        return await this.http.get(`share/${shareToken}`);
    }

    /**
     * Add bot to room using shareKey
     * @param roomId string
     * @param shareKey string
     */
    public async addBotToRoom(roomId: string, shareKey: string): Promise<AxiosResponse<any>>{
        const body = {
            key: shareKey,
        };
        const suffixes = utils.encodeUri("bots/add/$roomId/", { $roomId: roomId });
        return await this.http.post(suffixes, body);
    }

    /**
     * Get media content from URL
     * @param url
     */
    public async getMediaContent(url: string): Promise<string> {
        try {
            const res = await axios.get(
                url ,
                {
                    responseType: "arraybuffer",
                });
            const resBuff = Buffer.from(res.data);
            return JSON.parse(resBuff.toString("utf8"));
        } catch (e) {
            logger.error(e);
            return null;
        }
    }

    /**
     * API get two factor status
     * @returns
     */
    public getMultiFactorStatus(retry = 0, maxRetry = 3): Promise<GetMultiFactorRO> {
        return this.http.get("user/two-factor")
            .catch((e) => {
                if (retry < maxRetry) {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            return this.getMultiFactorStatus(retry + 1)
                                .then((res) => {
                                    resolve(res)
                                })
                                .catch((e) => {
                                    reject(e);
                                })
                        }, 3000);
                    });
                }
                throw e;
            });
    }

    /**
     * API enable two factor
     * @returns
     */
    public async enableTwoFactor(): Promise<AxiosResponse<EnableTwoFactorRO>> {
        return await this.http.post<EnableTwoFactorRO>("user/two-factor/enable", {});
    }

    /**
     * API verify two factor
     * @returns
     */
    public async verifyTwoFactor(otpCode: string): Promise<AxiosResponse<VerifyTwoFactorRO>> {
        return await this.http.post("user/two-factor/verify", {
            otpCode,
        });
    }

    /**
     * API setup yubikey flow
     * @returns
     */
    public async setupYubikeyFlow(dto: ISetupYubikeyFlowDTO): Promise<AxiosResponse<ISetupYubikeyFlowRO>> {
        return await this.http.post("user/two-factor/setup-yubikey-flow", dto);
    }

    /**
     * API request otp add phone number
     * @param dto IAddPhoneOTPRequest
     */
    public async requestOTPAddPhone(dto: IAddPhoneOTPRequest): Promise<any>  {
        const res = await this.http.post("auth/otp/add-phone", dto);
        return res.data;
    }

    /**
     * API request otp add email
     * @param dto IAddEmailOTPRequest
     */
    public async requestOTPAddEmail(dto: IAddEmailOTPRequest): Promise<any> {
        const res = await this.http.post("auth/otp/add-email", dto);
        return res.data;
    }

    /**
     * API verify otp - add phone number
     * @param dto
     * @Type IAddPhoneOTPVerify
     */
    public async verifyOTPAddPhone(dto: IAddPhoneOTPVerify): Promise<any> {
        const res = await this.http.post("auth/otp/add-phone/verify", dto);
        return res.data;
    }

    /**
     * API verify otp - add email
     * @param dto IAddEmailOTPVerify
     */
    public async verifyOTPAddEmail(dto: IAddEmailOTPVerify): Promise<any> {
        const res = await this.http.post("auth/otp/add-email/verify", dto);
        return res.data;
    }

    /**
     * API request OTP remove phone via phone
     * @param dto
     * @Type IRemovePhoneViaPhoneOTPRequest
     */
    public async requestOTPRemovePhoneViaPhone(dto: IRemovePhoneRequest): Promise<any> {
        const res = await this.http.post("auth/otp/remove-phone", dto);
        return res.data;
    }

    /**
     * API request OTP remove phone via email
     * @param dto
     * @Type IRemovePhoneViaEmailOTPRequest
     */
    public async requestOTPRemovePhoneViaEmail(dto: IRemovePhoneRequest): Promise<any> {
        const res = await this.http.post("auth/otp/remove-phone/via-email", dto);
        return res.data;
    }

    /**
     * API verify OTP when remove phone
     * @param dto
     * @Type IRemovePhoneVerify
     */
    public async verifyOTPRemovePhone(dto: IRemovePhoneVerify): Promise<any> {
        const res = await this.http.post("auth/otp/remove-phone/verify", dto);
        return res.data;
    }

    /**
     * API verify two factor when remove phone
     * @param dto
     * @Type IRemovePhoneVerify
     */
    public async verifyTwoFactorRemovePhone(dto: IRemovePhoneVerifyTwoFactor): Promise<any> {
        const res = await this.http.put("auth/two-factor/remove-phone/verify", undefined, dto);
        return res.data;
    }

    /**
     * API request OTP remove email via email
     * @param dto IRemoveEmailViaEmailOTPRequest Request DTO
     */
    public async requestOTPRemoveEmailViaEmail(dto: IRemoveEmailViaEmailOTPRequest): Promise<any> {
        const res = await this.http.post("auth/otp/remove-email", dto);
        return res.data;
    }

    /**
     * API verify OTP when remove email
     * @param dto IRemoveEmailVerify Request DTO
     */
    public async verifyOTPRemoveEmail(dto: IRemoveEmailVerify): Promise<any> {
        const res = await this.http.post("auth/otp/remove-email/verify", dto);
        return res.data;
    }

    /**
     * API verify two factor when remove email
     * @param dto IRemoveEmailVerify Request DTO
     */
    public async verifyTwoFactorRemoveEmail(dto: IRemoveEmailVerifyTwoFactor): Promise<any> {
        const res = await this.http.put("auth/two-factor/remove-email/verify", undefined, dto);
        return res.data;
    }

    /**
     * API get config 3pid options
     * @returns
     */
    public async getConfig3pidOption(): Promise<any> {
        return await this.http.get("settings/config-3pid-options");
    }

    /**
     * API get config call direct options
     * @returns
     */
    public async getConfigCallDirectOptions(): Promise<ICallConfig> {
        return await this.http.get("settings/call-direct-options");
    }

    /**
     * API get config system by key
     * @returns IConfigByKey
     */
    public async getConfigSystemByKey(key: string): Promise<IConfigByKey> {
        return await this.http.get(`settings/system/${key}`);
    }

    /**
     * Get Global settings
     * @returns any
     */
    public async getGlobalSettings(): Promise<any> {
        const res = await this.http.get("settings/system/all");
        return res.data;
    }


    /**
     * Get type of event
     * @param eventId
     * @param type
     */
    public async getEventByIdAndType(eventId: string, type: ERoomMessageEventType): Promise<RoomMessageEvent> {
        return await this.http.get(`room-message-events/${eventId}/${type}`);
    }

    /**
     * API get room event message with pagination
     * @returns
     */
    public async getWithPagination(dto: IGetWithPaginationDto): Promise<IGetWithPaginationRo> {
        const queryString = convertDtoToQueryString(dto);
        return await this.http.get(`room-message-events?${queryString}`);
    }

    /**
     * API create room message event
     * @returns
     */
    public async createRoomMessageEvent(dto: IRoomMessageEventDto): Promise<any> {
        return await this.http.post("room-message-events", dto);
    }

    /**
     * API delete room message event by type
     * @param eventId
     * @param type
     */
    public async deleteRoomMessageEventByType(eventId: string, type: string): Promise<any> {
        return await this.http.delete(`room-message-events/${eventId}/${type}`, '');
    }

    /**
     * API delete room message event
     * @param eventId
     */
    public async deleteRoomMessageEvent(eventId: string): Promise<any>  {
        return await this.http.delete("room-message-events", eventId);
    }


    /**
     * API re-invite user to room direct message
     * @returns
     */
    public async reInviteUser(roomId: string): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/re-invite",{
            $roomId: roomId,
        });
        return await this.http.post(suffixes, {});
    }

    public async getRoomWhiteListIps(roomId: string): Promise<RoomWhiteListIps> {
        const suffixes = utils.encodeUri("rooms/$roomId/ip",{
            $roomId: roomId,
        });
        return await this.http.get(suffixes);
    }

    public async createRoomWhiteListIp(dto: CreateRoomWhiteListIp, roomId: string): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/ip",{
            $roomId: roomId,
        });
        return await this.http.post(suffixes, dto);
    }

    public async updateRoomWhiteListIp(dto: UpdateRoomWhiteListIp, roomId: string, ip: string): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/ip",{
            $roomId: roomId,
        });
        return await this.http.put(suffixes, `${ip}`, dto);
    }

    public async deleteRoomWhiteListIp(roomId: string, ip: string): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/ip",{
            $roomId: roomId,
        });
        return await this.http.delete(suffixes, `${ip}`);
    }

    /**
     *  Get room setting
     */
    public async getRoomSetting(roomId: string, key: string): Promise<GetRoomSettings> {
        const prefix = utils.encodeUri("rooms/$roomId/settings",{
            $roomId: roomId,
        });
        const url = `${prefix}/${key}`;
        return await this.http.get(url);
    }

    /**
     * Change room setting
     */
    public async changeRoomSetting(roomId: string, key, data): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/settings",{
            $roomId: roomId,
        });
        return await this.http.put(suffixes, `${key}`, data);
    }

    public async getCommonRooms(dto: ICommonRoomDto): Promise<ICommonRoomRo> {
        const { roomId, targetUserId, ...commonRoomData } = dto;
        const queryString = convertDtoToQueryString(commonRoomData);
        const suffixes = utils.encodeUri(`rooms/$roomId/common-rooms/$targetUserId?${queryString}`,{
            $roomId: roomId,
            $targetUserId: targetUserId,
        });
        return await this.http.get(suffixes);
    }

    /**
     * Whitelist IP: check user IP
     *
     * @param roomId
     */
    public async checkUserIP(roomId: string): Promise<IWhiteListedIps> {
        const suffixes = utils.encodeUri("rooms/$roomId/check-user-ip", {
            $roomId: roomId
        });
        return await this.http.get(suffixes);
    }

    public async botFormResubmit(data: IResubmitDto): Promise<any> {
        const { botId, ...dto } = data;
        const suffixes = utils.encodeUri("bots/form/$botId/resubmit", {
            $botId: botId,
        });
        return await this.http.post(suffixes, dto);
    }

    //#region RoomsApi
    /**
     * API transfer owner
     * @param dto TransferOwnerDto
     * @param roomId string
     */
    public async transferOwner(dto: TransferOwnerDto, roomId: string): Promise<any> {
        const suffixes = utils.encodeUri("rooms/$roomId/transfer-owner", {
            $roomId: roomId,
        });
        return await this.http.post(suffixes, dto);
    }

    /**
         * API transfer owner
         * @param dto TransferOwnerDto
         * @param roomId string
         */
    public async deleteRoom(roomId: string): Promise<any> {
        return await this.http.delete("rooms", roomId);
    }

    //#endregion
}
