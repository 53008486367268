export enum EFormType {
    Radio = "radio",
    File = "file",
    Textarea = "textarea",
}

export enum EFormStatus {
    NONE = "none",
    INITIAL = "initial",
    SUBMITTING = "submitting",
    SUBMITTED= "submitted",
    FAILED= "failed",
    EXPIRED= "expired",
}

export enum EFormRelType {
    FormUpdate ="c.form.update",
}

export enum EFormErrorCode {
    DECRYPT_FAIL = "M_FORM_DECRYPTION_FAILED",
    VALIDATE_FAIL = "M_FORM_VALIDATION_FAILED",
    CALLBACK_FAIL = "M_FORM_CALLBACK_FAILED"
}

export enum ETimeLevel {
    SECOND,
    MINUTE,
    HOUR,
    DAY,
    MONTH,
    YEAR,
}

export enum ETimeUnit {
    YEAR = 'year',
    MONTH = 'month',
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
    SECOND = 'second'
}
