export enum ECallDirect {
    JITSI_ACTION_ACCEPT_CALL = 'm.jitsi.action_accept_call',
    JITSI_EVENT_ACTION_SELF = 'm.jitsi.event_action_self',
    JITSI_ROOM_STATE_CALL_DIRECT = 'm.jitsi.room_state_direct_call',
    JITSI_EVENT_GET_ROOM_DATA = 'm.jitsi.event_get_room_data',
    JITSI_CALL_STORE_EVENT_ACCEPT = 'm.jitsi.call_store_accept'
}

export enum EJitsiCallState {
    Connecting = 'connecting',
    Connected = 'connected',
    Ringing = 'ringing',
    Joined = 'joined',
    Ended = 'ended',
}
