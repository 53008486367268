import Modal from "matrix-react-sdk/src/Modal";

export async function closeAllModals(ignoreClass?: string): void {
    const modalManager = Modal as any;
    const modals = modalManager?.modals;
    const staticModal = modalManager?.staticModal;
    if (staticModal) {
        staticModal.close();
    }
    if (modals && modals.length > 0) {
        for (const modal of modals) {
            if (ignoreClass && modal?.elem?.props?.className === ignoreClass) {
                continue;
            }
            modal.close();
        }
    }
}

export function isOpeningYubiKeySetupDialog(): boolean {
    const modal = Modal as any;
    const current = modal?.getCurrentModal();
    return current?.elem?.props?.isSetupYubiKeyModal ?? false;
}
