import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import { UpdateCheckStatus, UpdateStatus } from "matrix-react-sdk/src/BasePlatform";

interface IState {
    status: UpdateCheckStatus;
    detail?: string;
    startedUp: boolean;
}

export const CHANGE_UPDATE_STATUS = 'change_update_status';

export class UpdateStatusStore extends AsyncStoreWithClient<IState> {
    private static readonly internalInstance = ((): UpdateStatusStore => {
        const instance = new UpdateStatusStore();
        instance.start();
        return instance;
    })();

    public constructor() {
        super(defaultDispatcher, {
            status: null,
            detail: '',
            startedUp: false,
        });
    }

    public static get instance(): UpdateStatusStore {
        return UpdateStatusStore.internalInstance;
    }

    public setStartedUp(isChecked: boolean): Promise<void> {
        return this.updateState({
            startedUp: isChecked,
        });
    }

    public isStartedUp(): boolean {
        return this.state.startedUp;
    }

    public setUpdateStatus = (status: UpdateCheckStatus, detail?: string): Promise<void> => {
        if (status !== this.state.status) {
            this.emit(CHANGE_UPDATE_STATUS, { status, detail });
        }
        return this.updateState({
            status: status,
            detail: detail,
        });
    };

    public getUpdateStatus = (): UpdateStatus => {
        return {
            status: this.state.status,
            detail: this.state.detail,
        };
    };

    protected async onReady(): void {
    }

    protected async onNotReady(): Promise<void> {
        await this.reset({
            status: null,
            detail: '',
            startedUp: false,
        });
    }

    protected async onAction(payload: ActionPayload): void {
        // We don't actually do anything here
    }
}
