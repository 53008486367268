/*
Copyright 2019, 2021 The Matrix.org Foundation C.I.C.
Copyright 2019 Michael Telatynski <7t3chguy@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from "react";
// import { Icon as FileIcon } from "matrix-react-sdk/res/img/feather-customised/files.svg";
import { _t } from "matrix-react-sdk/src/languageHandler";
import { getBlobSafeMimeType } from "matrix-react-sdk/src/utils/blobs";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import DialogButtons from "matrix-react-sdk/src/components/views/elements/DialogButtons";
import { fileSize } from "matrix-react-sdk/src/utils/FileUtils";
// CTalk imported
import classNames from "classnames";
import { formatSeconds } from "matrix-react-sdk/src/DateUtils";
import CaptionComposer from "@ctalk/components/views/rooms/CaptionComposer";

// CTalk added
import { Icon as FileIcon } from "../../../../res/themes/element/icons/file.svg";
import { Icon as ImageIcon } from "../../../../res/themes/element/icons/image.svg";

interface IProps {
    file: File;
    currentIndex: number;
    totalFiles: number;
    onFinished: (uploadConfirmed: boolean, uploadAll?: boolean, description?: string) => void;
}

// CTalk added
type UploadPreviewMode = 'media' | 'file';

// CTalk added
interface IState {
    mode: UploadPreviewMode;
    videoLength?: number | null;
}

export default class UploadConfirmDialog extends React.Component<IProps, IState> {
    private readonly objectUrl: string;
    private readonly mimeType: string;
    private readonly isMediaType: boolean; // CTalk added: Image or Video

    public static defaultProps: Partial<IProps> = {
        totalFiles: 1,
        currentIndex: 0,
    };

    public constructor(props: IProps) {
        super(props);

        // Create a fresh `Blob` for previewing (even though `File` already is
        // one) so we can adjust the MIME type if needed.
        this.mimeType = getBlobSafeMimeType(props.file.type);
        this.isMediaType = (['image/', 'video/'].some(t => this.mimeType.startsWith(t))); // CTalk added
        const blob = new Blob([props.file], { type: this.mimeType });

        // CTalk added
        this.state = {
            mode: (this.props.totalFiles > 1 || !this.isMediaType) ? 'file' : 'media',
            videoLength: null,
        };

        this.objectUrl = URL.createObjectURL(blob);
    }

    public componentWillUnmount(): void {
        if (this.objectUrl) URL.revokeObjectURL(this.objectUrl);
    }

    /**
     * CTalk added
     * @param event
     */
    private onLoadedMetadata = (event): void => {
        this.setState({
            videoLength: event.target.duration,
        });
    };

    /**
     * CTalk added
     * @param type
     */
    private toggleUploadMode = (type: UploadPreviewMode): void => {
        this.setState({
            mode: type,
        });
    };

    /**
     * CTalk added
     * @param description
     */
    private onSendWithDescription = (description: string): void => {
        this.props.onFinished(true, undefined, description);
    };

    private onCancelClick = (): void => {
        this.props.onFinished(false);
    };

    private onUploadClick = (): void => {
        this.props.onFinished(true, undefined); // CTalk added
    };

    private onUploadAllClick = (): void => {
        this.props.onFinished(true, true);
    };

    public render(): React.ReactNode {
        let title: string;
        if (this.props.totalFiles > 1 && this.props.currentIndex !== undefined) {
            title = _t("upload_file|title_progress", {
                current: this.props.currentIndex + 1,
                total: this.props.totalFiles,
            });
        } else if (this.isMediaType) { /* CTalk added */
            const fileClassName = classNames({ active: this.state.mode === 'file' });
            const imageClassName = classNames({ active: this.state.mode === 'media' });
            const modeIconSize = 25;
            title = <div className="ctalk_UploadMode">
                <span title={_t('ctalk|action|preview')} onClick={(): void => { this.toggleUploadMode('media'); }}>
                    <ImageIcon
                        height={modeIconSize}
                        width={modeIconSize}
                        className={imageClassName}
                    />
                </span>
                <span title={_t('CTALK_UPLOAD_TAB_FILE_INFO')} onClick={(): void => { this.toggleUploadMode('file'); }}>
                    <FileIcon
                        height={modeIconSize}
                        width={modeIconSize}
                        className={fileClassName}
                    />
                </span>
            </div>;
        } else {
            title = _t("upload_file|title");
        }

        const fileId = `mx-uploadconfirmdialog-${this.props.file.name}`;
        let preview: JSX.Element | undefined;
        let placeholder: JSX.Element | undefined;
        const isImageType = this.mimeType.startsWith("image/"); // CTalk added
        const isVideoType = this.mimeType.startsWith("video/"); // CTalk added
        if (isImageType) {
            preview = (
                <img
                    className="mx_UploadConfirmDialog_imagePreview"
                    src={this.objectUrl}
                    alt={this.props.file.name} // CTalk added
                    aria-labelledby={fileId}
                />
            );
        } else if (isVideoType) {
            preview = (
                <video
                    className="mx_UploadConfirmDialog_imagePreview"
                    src={this.objectUrl}
                    autoPlay={false}
                    playsInline
                    controls={false}
                    onLoadedMetadata={this.onLoadedMetadata}
                />
            );
        } else {
            // CTalk edited
            placeholder = (
                <div className="ctalk_UploadFile_iconWrapper">
                    <FileIcon
                        className="mx_UploadConfirmDialog_fileIcon"
                        height={25}
                        width={25}
                    />
                </div>
            );
        }

        let uploadAllButton: JSX.Element | undefined;
        if (this.props.currentIndex + 1 < this.props.totalFiles) {
            uploadAllButton = <button onClick={this.onUploadAllClick}>{_t("upload_file|upload_all_button")}</button>;
        }

        // CTalk added
        const isShowDescription = this.props.totalFiles === 1;
        let description;
        let uploadButton;
        let fileInfo;
        if (!isShowDescription) {
            uploadButton = <DialogButtons
                primaryButton={_t('action|upload')}
                hasCancel={false}
                onPrimaryButtonClick={this.onUploadClick}
                focus={true}
            >
               {uploadAllButton}
            </DialogButtons>;
        } else {
            description = <CaptionComposer
                sendMessage={this.onSendWithDescription}
            />;
        }

        // CTalk added
        const isMediaPreviewMode = this.state.mode === 'media' && description;
        const showFileInfo = !isShowDescription || !isMediaPreviewMode;
        if (showFileInfo) {
            fileInfo = <div className="ctalk_UploadFile_Info">
                <div className="ctalk_UploadFile_Name">{ this.props.file.name }</div>
                <div className="ctalk_UploadFile_Size">{ fileSize(this.props.file.size) }</div>
            </div>;
        }

        return (
            <BaseDialog
                className="mx_UploadConfirmDialog"
                fixedWidth={false}
                onFinished={this.onCancelClick}
                title={title}
                contentId="mx_Dialog_content"
            >
                <div id="mx_Dialog_content">
                    <div className={classNames("mx_UploadConfirmDialog_previewOuter", {
                        ctalk_UploadFile_fileMode: this.state.mode === 'file',
                    })}>
                        {
                            isMediaPreviewMode && isImageType &&
                            <img
                                src={this.objectUrl}
                                className="ctalk_UploadMedia_Backdrop"
                                alt="this.props.file.name"
                            />
                        }
                        <div className="mx_UploadConfirmDialog_previewInner">
                            {preview && <div className={classNames('ctalk_PreviewMedia', {
                                    ctalk_UploadFile_iconWrapper: showFileInfo,
                                })}>{preview}</div>}
                            {
                                // CTalk added
                                isMediaPreviewMode && this.state.videoLength &&
                                <span className="ctalk_VideoLength">
                                    { formatSeconds(this.state.videoLength) }
                                </span>
                            }
                            <div
                                id={fileId}
                                className={classNames({
                                    ctalk_UploadFile_InfoWrapper: showFileInfo,
                                })}
                            >
                                {placeholder}
                                {fileInfo}
                            </div>
                        </div>
                    </div>
                </div>
                {description}
                {uploadButton}
            </BaseDialog>
        );
    }
}
