import React, { FC, useCallback } from "react";
import { aboveLeftOf, useContextMenu } from "matrix-react-sdk/src/components/structures/ContextMenu";
import { CallType } from "matrix-js-sdk/src/webrtc/call";
import AccessibleButton, { ButtonEvent } from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import IconizedContextMenu, {
    IconizedContextMenuOption,
    IconizedContextMenuOptionList,
} from "matrix-react-sdk/src/components/views/context_menus/IconizedContextMenu";
import { _t } from "matrix-react-sdk/src/languageHandler";
import { Room } from "matrix-js-sdk/src/models/room";
import { logger } from "matrix-js-sdk/src/logger";
import { CTalkClient } from "@ctalk/CTalkClient";

import { ICallConfig } from "../../interfaces/rooms/ICallConfig";
import LegacyCallHandler from "../../../LegacyCallHandler";

/**
 * Button for starting video calls, supporting both legacy 1:1 calls, Jitsi
 * widgets, and native group calls. If multiple calling options are available,
 * this shows a menu to pick between them.
 */
interface VideoCallDMButtonProps {
    room: Room;
    text: string;
    ckClient: CTalkClient;
    callType: CallType;
}

export const CallBackButton: FC<VideoCallDMButtonProps> = (props) => {
    const { room, text, callType, ckClient } = props;
    const [menuOpen, buttonRef, openMenu, closeMenu] = useContextMenu();
    let config: ICallConfig;
    const startJitsiCall = useCallback(async () => {
        await LegacyCallHandler.instance.handleDirectMessageCall(room?.roomId, true, callType);
    }, [room, callType]);

    const startMatrixCall = useCallback(async () => {
        await LegacyCallHandler.instance.handleDirectMessageCall(room?.roomId, false, callType);
    }, [room, callType]);

    const onClick = async (ev: ButtonEvent): Promise<void> => {
        ev.preventDefault();
        const userInCall = LegacyCallHandler.instance.checkUserInCall(room.roomId);
        if (userInCall) {
            return;
        }
        try {
            const res = await ckClient.getConfigCallDirectOptions();
            config = {
                jitsiMeet: res.jitsiMeet,
                matrixTurnServer: res.matrixTurnServer,
            };
            if (config.jitsiMeet && config.matrixTurnServer) {
                openMenu();
            } else if (config.jitsiMeet) {
                await startJitsiCall();
            } else {
                await startMatrixCall();
                return;
            }
        } catch (e) {
            logger.error('Cannot get call direct options', e);
            await startMatrixCall();
        }
    };

    const onJitsiClick = useCallback(async (ev: ButtonEvent) => {
        ev.preventDefault();
        closeMenu();
        await startJitsiCall();
    }, [closeMenu, startJitsiCall]);

    const onMatrixTurnClick = useCallback(async (ev: ButtonEvent) => {
        ev.preventDefault();
        await startMatrixCall();
        closeMenu();
    }, [closeMenu, startMatrixCall]);

    let menu: JSX.Element | null = null;
    if (menuOpen) {
        const buttonRect = buttonRef.current!.getBoundingClientRect();
        menu = <IconizedContextMenu {...aboveLeftOf(buttonRect)} onFinished={closeMenu}>
            <IconizedContextMenuOptionList>
                <IconizedContextMenuOption
                    label={
                        callType === CallType.Video
                            ? _t("CTALK_VIDEO_CALL_TURN")
                            :_t("CTALK_VOICE_CALL_TURN")
                    }
                    onClick={onMatrixTurnClick}
                />
                <IconizedContextMenuOption
                    label={
                        callType === CallType.Video
                            ? _t("CTALK_VIDEO_CALL_JITSI")
                            : _t("CTALK_VOICE_CALL_JITSI")
                    }
                    onClick={onJitsiClick}
                />
            </IconizedContextMenuOptionList>
        </IconizedContextMenu>;
    }

    return <>
        <AccessibleButton
            inputRef={buttonRef}
            className="mx_LegacyCallEvent_content_button mx_LegacyCallEvent_content_button_callBack ctalk_CallEvent_content_button"
            onClick={onClick}
            kind="primary"
        >
            <span> { text } </span>
        </AccessibleButton>
       {menu}
    </>;
};
