import React from "react";
import { Icon as WarningIcon } from "matrix-react-sdk/res/img/feather-customised/warning-triangle.svg";
import { IRangeTime } from "@ctalk/interfaces/rooms/IBotForm";
import { formatMillisecondsToDate, millisecondsToTimeString } from "@ctalk/helpers/DateHelper";
import TextWithTooltip from "matrix-react-sdk/src/components/views/elements/TextWithTooltip";

import { _t } from "../../../../../languageHandler";
import { Icon as TimerIcon } from "../../../../../../res/themes/element/icons/ios-timer.svg";

interface IProps {
    label: string;
    expireTime: number;
    tooltipDisplayTime?: number;
    rangeTime?: IRangeTime;
    needToShowLessThan?: boolean;
    expiredLabel?: string;
    roundUp?: boolean;
}

export default class CTimeExpire extends React.Component<IProps> {
    public constructor(props: IProps) {
        super(props);
    }

    public render(): React.ReactNode {
        const {
            tooltipDisplayTime,
            expireTime,
            label,
            rangeTime,
            needToShowLessThan,
            expiredLabel,
            roundUp,
        } = this.props;
        const needToShowTooltip = tooltipDisplayTime && expireTime > 0;
        let content;

        if (expireTime > 0) {
            content = (
                <>
                    <TimerIcon width="1.5em" height="1.5em" className="ctalk_time_expire_icon" />
                    <div className="ctalk_time_expire_info">
                        {label}: {millisecondsToTimeString(expireTime, rangeTime, _t, needToShowLessThan, roundUp)}
                    </div>
                </>
            );
        } else if (expiredLabel) {
            content = (
                <>
                    <WarningIcon width="1.2em" height="1.2em" className="ctalk_time_expire_warning_icon" />
                    <div className="ctalk_time_expire_info">
                        {expiredLabel}
                    </div>
                </>
            );
        }

        const renderContent = (
            <div className="ctalk_time_expire">
                {content}
            </div>
        );

        return (
            <>
                {
                    needToShowTooltip ?
                        <TextWithTooltip tooltip={formatMillisecondsToDate(tooltipDisplayTime ?? 0)}>
                            {renderContent}
                        </TextWithTooltip> :
                        renderContent
                }
            </>
        );
    }
}
