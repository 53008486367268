import React from "react";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { EventType, Room } from "matrix-js-sdk/src/matrix";
import { RoomEvent } from "matrix-js-sdk/src/models/room";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import MatrixClientContext from "matrix-react-sdk/src/contexts/MatrixClientContext";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import TextWithTooltip from "matrix-react-sdk/src/components/views/elements/TextWithTooltip";
import Modal from "matrix-react-sdk/src/Modal";
import { Icon as WarningIcon } from "matrix-react-sdk/res/img/feather-customised/warning-triangle.svg";
import { Icon as RetryIcon } from "matrix-react-sdk/res/img/compound/retry-16px.svg";
import { EFormErrorCode, EFormRelType, EFormStatus } from "@ctalk/enums/bot-form.enum";
import CTimeExpire from "@ctalk/components/views/messages/form_components/CTimeExpire";
import CFormViewResponseDialog from "@ctalk/views/dialogs/CFormViewResponseDialog";
import { IBotFormValue } from "@ctalk/models/bot-form.interface";
import CFormSubmitDialog from "@ctalk/views/dialogs/CFormSubmitDialog";
import CFormInfo from "@ctalk/components/views/messages/form_components/CFormInfo";
import { ECustomMsgType } from "@ctalk/enums/custom-event";
import { closeAllModals } from "@ctalk/helpers/ModalHelper";
import { getLatestEventInfo } from "@ctalk/helpers/BotFormHelper";
import { logger } from "matrix-js-sdk/src/logger";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import { CTalkClient } from "@ctalk/CTalkClient";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";

import { IBodyProps } from "../../../components/views/messages/IBodyProps";
import { _t } from "../../../languageHandler";
import { Icon as SeenIcon } from "../../../../res/themes/element/icons/seen.svg";
import { RoomBotFormStore } from "../../../stores/RoomBotFormStore";

enum EButtonType {
    ViewResponse,
    Retry,
    ReSubmit,
    AddResponse,
}

interface IStatusActionProps {
    formStatus: EFormStatus;
    mxEvent: MatrixEvent;
    eventsUntilSubmitted: MatrixEvent[];
}

interface IStatusActionState {
    expireTime: number;
    tooltipDisplayTime: number;
    submitting: boolean;
    formStatus: EFormStatus;
    needToShowResubmit: boolean;
}

const RESUBMIT_DISPLAY_DURATION = 180000; // 3 Min

class FormStatusAction extends React.Component<IStatusActionProps, IStatusActionState> {
    private timeoutExpiredId: ReturnType<typeof setTimeout> | null = null;
    private timeoutReSubmitId: ReturnType<typeof setTimeout> | null = null;

    private ckClient: CTalkClient;

    public constructor(props: IStatusActionProps) {
        super(props);
        this.ckClient = CTalkClientPeg.get();

        this.state = {
            expireTime: 0,
            tooltipDisplayTime: 0,
            submitting: false,
            formStatus: EFormStatus.INITIAL,
            needToShowResubmit: false,
        };
    }

    public componentDidMount(): void {
        const content = this.props.mxEvent.getContent();
        this.setState({
            tooltipDisplayTime: content.expired,
            formStatus: this.props.formStatus,
        });
        this.updateExpireTime();
        this.handleFormExpire();
    }

    public componentDidUpdate(prevProps: IStatusActionProps): void {
        if (prevProps.formStatus === this.props.formStatus) {
            return;
        }
        if (this.state.formStatus !== EFormStatus.EXPIRED) {
            this.setState({ formStatus: this.props.formStatus });
            if (this.props.formStatus === EFormStatus.SUBMITTING){
                this.handleResubmittingButton();
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.timeoutExpiredId) {
            clearTimeout(this.timeoutExpiredId);
            this.timeoutExpiredId = null;
        }
        if (this.timeoutReSubmitId) {
            clearTimeout(this.timeoutReSubmitId);
            this.timeoutReSubmitId = null;
        }
    }

    private updateExpireTime(): void {
        const content = this.props.mxEvent.getContent();
        const expireTime = content.expired - new Date().getTime();
        this.setState({
            expireTime,
        });
    }

    private handleFormExpire(): void {
        const content = this.props.mxEvent.getContent();
        const remainingTime = content.expired - new Date().getTime();
        if (remainingTime > 0) {
            this.timeoutExpiredId = setTimeout(() => {
                this.setState({
                    formStatus: EFormStatus.EXPIRED,
                    needToShowResubmit: false,
                    expireTime: 0,
                });
            }, remainingTime);
        } else {
            this.setState({
                formStatus: EFormStatus.EXPIRED,
                expireTime: 0,
            });
        }
    }

    private handleResubmittingButton(): void {
        if (this.timeoutReSubmitId) {
            return;
        }
        const submittingEvent = this.getSubmittingEvent(this.props.eventsUntilSubmitted);
        const resubmitReminderTime = submittingEvent ? submittingEvent.getTs() + RESUBMIT_DISPLAY_DURATION : 0;
        const remainingTime = resubmitReminderTime - new Date().getTime();
        if (remainingTime > 0) {
            this.setState({
                needToShowResubmit: false,
            });
            this.timeoutReSubmitId = setTimeout(() => {
                this.setState({
                    needToShowResubmit: true,
                });
            }, remainingTime);
        } else {
            this.setState({
                needToShowResubmit: true,
            });
        }
    }

    private onAddResponse = (): void => {
        this.updateExpireTime();
        Modal.createDialog(
            CFormSubmitDialog, {
                mxEvent: this.props.mxEvent,
            },
            undefined, /*className=*/
            false, /*isPriority=*/
            true, /*isStatic=*/
        );
    }

    private onViewResponse = (): void => {
        let submittingEvent;
        if (this.state.formStatus === EFormStatus.SUBMITTED) {
            // If form submitted, data submitted are in response
            submittingEvent = getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.SUBMITTED)?.event;
        } else {
            submittingEvent = this.getSubmittingEvent(this.props.eventsUntilSubmitted);
        }
        if (!submittingEvent) {
            Modal.createDialog(ErrorDialog, {
                title: _t("ctalk|bot_form|error|submit_response_failed"),
                description: _t("ctalk|bot_form|error|event_notfound"),
            });
            return;
        }
        if (this.state.formStatus !== EFormStatus.EXPIRED) {
            this.updateExpireTime();
        }
        Modal.createDialog(
            CFormViewResponseDialog, {
                eventsUntilSubmitted: this.props.eventsUntilSubmitted,
                mxEvent: this.props.mxEvent,
                submittingEvent,
                onFinished: async (response) => {
                    // Handle something
                },
            },
            undefined, /*className=*/
            false, /*isPriority=*/
            true, /*isStatic=*/
        );
    }

    private onReSubmit = async (): Promise<void> => {
        const rootEvent = this.props.mxEvent;
        const roomId = rootEvent.getRoomId();
        const botId = rootEvent.getSender();
        const submitEvent = this.getSubmittingEvent(this.props.eventsUntilSubmitted)
        const eventId  = submitEvent?.getId();
        if (!submitEvent) {
            Modal.createDialog(ErrorDialog, {
                title: _t("ctalk|bot_form|error|submit_response_failed"),
                description: _t("ctalk|bot_form|error|event_notfound"),
            });
            return;
        }
        try {
            this.setState({
                submitting: true,
            });
            await this.ckClient.botFormResubmit({
                botId: botId!,
                roomId: roomId!,
                eventId: eventId!,
            });
            this.setState({
                submitting: false,
                needToShowResubmit: false,
            });
            this.updateExpireTime();
            setTimeout(() => {
                if (this.state.formStatus === EFormStatus.SUBMITTING) {
                    this.setState({
                        needToShowResubmit: true,
                    });
                }
            }, RESUBMIT_DISPLAY_DURATION);
        } catch (e) {
            // TODO update error description
            Modal.createDialog(ErrorDialog, {
                title: _t("ctalk|bot_form|error|re_submit_response_failed"),
                description: _t("ctalk|bot_form|error|event_notfound"),
            });
        }
    }

    private onRetry = (): void => {
        const event = this.getSubmittingEvent(this.props.eventsUntilSubmitted);
        const response = event?.getContent().response as IBotFormValue[];
        if (!event || !response) {
            Modal.createDialog(ErrorDialog, {
                title: _t("ctalk|bot_form|error|submit_response_failed"),
                description: _t("ctalk|bot_form|error|event_notfound"),
            });
            return;
        }
        const failedEvent = getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.FAILED);
        if (failedEvent ) {
            const failedContent = failedEvent.event.getContent();
            if (
                failedContent.errcode === EFormErrorCode.DECRYPT_FAIL
                || !Object.values(EFormErrorCode).includes(failedContent.errcode)
            ) {
                this.sendDummyEvent(event);
            }
        }

        this.updateExpireTime();
        Modal.createDialog(
            CFormSubmitDialog, {
                mxEvent: this.props.mxEvent,
                response,
                onFinished: async (response) => {
                    // Handle something
                },
            },
            undefined, /*className=*/
            false, /*isPriority=*/
            true, /*isStatic=*/
        );
    }

    private async sendDummyEvent(event: MatrixEvent): Promise<void> {
        await MatrixClientPeg.safeGet().sendEvent(
            event.getRoomId()!,
            EventType.RoomMessage,
            {
                msgtype: ECustomMsgType.CBotDummy,
            },
        );
    }

    private getSubmittingEvent(events: MatrixEvent[]): MatrixEvent | null{
        const submittingEvent = getLatestEventInfo(events, EFormStatus.SUBMITTING);
        return submittingEvent ? submittingEvent.event : null;
    }

    private isSubmitted(): boolean {
        const submittedEvent = getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.SUBMITTED);
        return !!submittedEvent;
    }

    private isLatestEventWithStatus(status: EFormStatus): boolean {
        const events = this.props.eventsUntilSubmitted;
        const lastEvent = events[events.length - 1];
        return lastEvent && lastEvent.getContent().status === status;
    }

    private displayViewResponseButton(): boolean {
        return !!getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.SUBMITTED);
    }

    private getFailedTooltip(): React.ReactNode {
        const failedEvent = getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.FAILED);
        if (!failedEvent) {
            return <></>;
        }
        const failedContent = failedEvent.event.getContent();
        const detail = failedContent.error;
        let header: string;
        switch (failedContent.errcode) {
            case EFormErrorCode.DECRYPT_FAIL:
                header = _t("ctalk|bot_form|error|form_decryption_failed");
                break;
            case EFormErrorCode.VALIDATE_FAIL:
                header = _t("ctalk|bot_form|error|form_validation_failed");
                break;
            case EFormErrorCode.CALLBACK_FAIL:
                header = _t("ctalk|bot_form|error|form_callback_failed");
                break;
            default:
                header = "";
                break;
        }
        return (
            <div>
                <div>
                    {header}
                </div>
                <div>
                    {detail}
                </div>
            </div>
        )
    }

    private renderStatus = (): React.ReactNode => {
        let content;
        switch (this.state.formStatus) {
            case EFormStatus.SUBMITTING:
                content = this.formStatus(EFormStatus.SUBMITTING);
                break;
            case EFormStatus.SUBMITTED:
                content = this.formStatus(EFormStatus.SUBMITTED);
                break;
            case EFormStatus.FAILED:
                content = this.formStatus(EFormStatus.FAILED);
                break;
            case EFormStatus.EXPIRED:
                content = this.formExpiredStatus();
                break;
            default:
                content = <></>;
                break;
        }

        return (
            <div className="ctalk_Form_Body_status">
                <div className="ctalk_Form_Body_status_item">
                    {content}
                </div>
            </div>
        );
    }

    private renderAction = (): React.ReactNode => {
        let content;
        switch (this.state.formStatus) {
            case EFormStatus.INITIAL:
                content = this.formButton(EButtonType.AddResponse);
                break;
            case EFormStatus.SUBMITTING:
                if (this.state.needToShowResubmit) {
                    content = this.formButton(EButtonType.ReSubmit);
                }
                break;
            case EFormStatus.EXPIRED:
            case EFormStatus.SUBMITTED:
                if (this.displayViewResponseButton()) {
                    content = this.formButton(EButtonType.ViewResponse);
                }
                break;
            case EFormStatus.FAILED:
                content = this.formButton(EButtonType.Retry);
                break;
            default:
                content = <></>;
                break;
        }

        return (
            <div className="ctalk_Form_Action">
                {content}
            </div>
        );
    }

    private formStatus = (status: EFormStatus): React.ReactNode => {
        switch (status) {
            case EFormStatus.SUBMITTING:
                return (
                    <>
                        {_t("ctalk|bot_form|status|submitting")}
                    </>
                );
            case EFormStatus.SUBMITTED:
                return (
                    <>
                        <SeenIcon
                            className="ctalk_Form_icon ctalk_Form_status_icon"
                            width="1.2em"
                            height="1.2em"
                        />
                        {_t("ctalk|bot_form|status|submitted")}
                    </>
                );
            case EFormStatus.FAILED:
                return (
                    <div className="ctalk_Form_failed">
                        <TextWithTooltip
                            tooltip={this.getFailedTooltip()}
                        >
                            <WarningIcon
                                className="ctalk_Form_error_icon ctalk_Form_status_icon"
                                width="1em"
                                height="1em"
                            />
                            {_t("ctalk|bot_form|status|failed")}
                        </TextWithTooltip>
                    </div>
                );
            default:
                return <></>;
        }
    }

    private formExpiredStatus = (): React.ReactNode => {
        if (this.isLatestEventWithStatus(EFormStatus.SUBMITTED)) {
            return this.formStatus(EFormStatus.SUBMITTED);
        } else if (this.isLatestEventWithStatus(EFormStatus.FAILED)) {
            return this.formStatus(EFormStatus.FAILED);
        } else {
            return <></>;
        }
    }

    private formButton = (type: EButtonType): React.ReactNode => {
        switch (type) {
            case EButtonType.ViewResponse:
                return (
                    <AccessibleButton
                        className="ctalk_Form_Add_Response"
                        kind="primary_outline"
                        onClick={this.onViewResponse}
                    >
                        {_t("ctalk|bot_form|button_view_response")}
                    </AccessibleButton>
                );
            case EButtonType.Retry:
                return (
                    <AccessibleButton
                        className="ctalk_Form_Add_Response"
                        kind="primary"
                        onClick={this.onRetry}
                    >
                        <RetryIcon
                            className="ctalk_Form_icon_retry"
                            width="1em"
                            height="1em"
                        />
                        {_t("ctalk|bot_form|button_retry")}
                    </AccessibleButton>
                );
            case EButtonType.ReSubmit:
                return (
                    <AccessibleButton
                        className="ctalk_Form_Add_Response"
                        kind="primary"
                        disabled={this.state.submitting}
                        onClick={this.onReSubmit}
                    >
                        <RetryIcon
                            className="ctalk_Form_icon_retry"
                            width="1em"
                            height="1em"
                        />
                        {_t("ctalk|bot_form|button_re_submit")}
                    </AccessibleButton>
                );
            case EButtonType.AddResponse:
                return (
                    <AccessibleButton
                        className="ctalk_Form_Add_Response"
                        kind="primary"
                        onClick={this.onAddResponse}
                    >
                        {_t("ctalk|bot_form|button_add_your_response")}
                    </AccessibleButton>
                );
            default:
                return <></>;
        }
    };

    public render(): React.ReactNode {
        const expiredTranslatedKey= this.isSubmitted() ? "confirmation_is_expired" : "form_expired";
        return (
            <>
                {this.renderStatus()}
                {this.props.children}
                {this.renderAction()}
                <CTimeExpire
                    label={_t("ctalk|bot_form|expired_in")}
                    expireTime={this.state.expireTime}
                    tooltipDisplayTime={this.state.tooltipDisplayTime}
                    expiredLabel={_t(`ctalk|bot_form|${expiredTranslatedKey}`)}
                    needToShowLessThan={true}
                />
            </>
        );
    }
}

interface IState {
    isSubmitted: boolean;
    formStatus: EFormStatus;
    allRelationEvents: MatrixEvent[];
    eventsUntilSubmitted: MatrixEvent[];
    previewFileExpired?: boolean;
}

export default class CFormBody extends React.Component<IBodyProps, IState> {
    private client: MatrixClient;
    private timeoutExpiredId: ReturnType<typeof setTimeout> | null = null;
    public context!: React.ContextType<typeof MatrixClientContext>;
    private readonly room: Room | null;
    private isMounted = false;

    public constructor(props: IBodyProps) {
        super(props);
        this.client = MatrixClientPeg.get()!;
        const roomId = this.props.mxEvent.getRoomId();
        this.room = this.client.getRoom(roomId);
        this.state = {
            isSubmitted: false,
            formStatus: EFormStatus.NONE,
            allRelationEvents: [],
            eventsUntilSubmitted: [],
        };
    }

    public componentDidMount(): void {
        this.initialData();
    }

    public componentWillUnmount(): void {
        this.isMounted = false;
        this.room?.removeListener(RoomEvent.Timeline, this.onRoomTimeline);
        if (this.timeoutExpiredId) {
            clearTimeout(this.timeoutExpiredId);
            this.timeoutExpiredId = null;
        }
    }

    private async initialData(): Promise<void> {
        this.isMounted = true;
        await this.fetchResponses();
        this.handlePreviewFiles();
        if (
            this.state.formStatus !== EFormStatus.SUBMITTED
            && this.state.formStatus !== EFormStatus.EXPIRED
        ) {
            this.room?.on(RoomEvent.Timeline, this.onRoomTimeline);
        }
    }

    private handlePreviewFiles(): void {
        const content =  this.props.mxEvent.getContent();
        if (content?.files?.length) {
            const submittedEvent = getLatestEventInfo(this.state.eventsUntilSubmitted, EFormStatus.SUBMITTED);
            const viewResponseExpired = submittedEvent?.event.getContent()?.view_response_expired;
            const time = viewResponseExpired ?? content.expired;
            const expireTime = time - Date.now();
            if (expireTime > 0) {
                this.setState({
                    previewFileExpired: false,
                });
                this.timeoutExpiredId = setTimeout(() => {
                    this.setState({
                        previewFileExpired: true,
                    });
                    const currentModal = (Modal as any)?.getCurrentModal();
                    if (currentModal?.elem?.props?.isPreviewFileModal) {
                        currentModal?.close();
                        Modal.createDialog(ErrorDialog, {
                            title: _t("ctalk|bot_form|files_expired_title"),
                            description: _t("ctalk|bot_form|files_expired_description"),
                        });
                    }
                }, expireTime);
            } else {
                this.setState({
                    previewFileExpired: true,
                });
            }
        }
    }

    private onRoomTimeline = (
        mxEvent: MatrixEvent, room?: Room,
    ): void => {
        this.handleUpdateEvent(mxEvent);
    }

    private async handleUpdateEvent(mxEvent: MatrixEvent): Promise<void> {
        let evType = mxEvent.getType();
        if (evType === EventType.RoomMessageEncrypted) {
            await this.decryptEvents([mxEvent])
            // after decrypted -> get new evType
            evType = mxEvent.getType();
        }

        const content = mxEvent.getContent();
        const relatesTo = mxEvent.getWireContent()?.["m.relates_to"];
        if (
            evType === EventType.RoomMessage
            && content?.msgtype === ECustomMsgType.CForm
            && relatesTo?.event_id === this.props.mxEvent.getId()
        ) {
            if (!this.isMounted) return;
            // If current form status is SUBMITTED -> don't update status
            if (
                this.state.formStatus === EFormStatus.SUBMITTED
                || content.status === EFormStatus.INITIAL
            ) {
                return;
            }
            RoomBotFormStore.instance.addNewRelationForEvent(this.props.mxEvent.getRoomId()!, this.props.mxEvent.getId()!, mxEvent);
            this.setState((prevState) => ({
                eventsUntilSubmitted: [...prevState.eventsUntilSubmitted, mxEvent],
            }));
            this.handleEventStatus(this.state.eventsUntilSubmitted);
            if (content.status === EFormStatus.SUBMITTED) {
                this.handlePreviewFiles();
            }
        }
        // Handle close all modal when formStatus is submitted
        if (content.status === EFormStatus.SUBMITTED) {
            closeAllModals();
        }
    }

    private async fetchResponses(): Promise<void> {
        const event = this.props.mxEvent;
        const instance = RoomBotFormStore.instance;
        let relations;
        try {
            const eventInfo = await instance.getEventRelations(event.getRoomId()!, event.getId()!)
            if (eventInfo) {
                relations = eventInfo.relations;
            } else {
                const { events } = await this.client.relations(
                    event.getRoomId()!,
                    event.getId()!,
                    EFormRelType.FormUpdate,
                );
                instance.setNewRelations(event.getRoomId()!, event.getId()!, events);
                relations = events;
            }
            await this.decryptEvents(relations);
            await this.handleEventStatus(relations);
        } catch (error) {
            logger.error("Error fetching event relations:", error);
        }
    }

    private handleEventStatus(events: MatrixEvent[]): void {
        const comparator = (a: MatrixEvent, b: MatrixEvent): number => {
            return a.getTs() - b.getTs();
        };
        events.sort(comparator);
        const { status, eventsUntilSubmitted } = this.getFormEvent(events);
        this.setState({
            allRelationEvents: events,
            formStatus: status,
            eventsUntilSubmitted,
        });
    }

    private async decryptEvents(events: MatrixEvent[]): Promise<void> {
        const maxRetries = 3;
        let retryCount = 0;

        while (retryCount < maxRetries) {
            try {
                await Promise.all(events.map((e) => this.client.decryptEventIfNeeded(e)));
                break;
            } catch (error) {
                console.error(`Decryption attempt failed. Retrying... (Attempt ${retryCount + 1}/${maxRetries})`);
                retryCount++;
            }
        }
        if (retryCount === maxRetries) {
            console.error(`Decrypt events are reached maximum decryption retries (${maxRetries}).`);
        }
    }

    private getFormEvent(events: MatrixEvent[]): {
        status: EFormStatus,
        eventsUntilSubmitted: MatrixEvent[],
    } {
        const isExpired = this.isExpired();
        let status = isExpired ? EFormStatus.EXPIRED : EFormStatus.INITIAL;

        if (!events.length) {
            return { status, eventsUntilSubmitted: [] };
        }
        const submittedIndex = events.findIndex(e => e.getContent().status === EFormStatus.SUBMITTED);
        const eventsUntilSubmitted = submittedIndex !== -1 ? events.slice(0, submittedIndex + 1) : events;
        if (!isExpired) {
            status = submittedIndex !== -1 ? EFormStatus.SUBMITTED : events[events.length - 1].getContent().status;
        }
        return { status, eventsUntilSubmitted };
    }

    private isExpired (): boolean {
        const content = this.props.mxEvent.getContent();
        return content.expired - new Date().getTime() <= 0
    }

    public render(): React.ReactNode {
        return (
            <div className="ctalk_Form_Body">
                <FormStatusAction
                    formStatus={this.state.formStatus}
                    mxEvent={this.props.mxEvent}
                    eventsUntilSubmitted={this.state.eventsUntilSubmitted}
                >
                    <CFormInfo
                        mxEvent={this.props.mxEvent}
                        previewFileExpired={this.state.previewFileExpired}
                    />
                </FormStatusAction>
            </div>
        );
    }
}
