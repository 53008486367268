import SdkConfig from "matrix-react-sdk/src/SdkConfig";

export function clearStorageIntegrationsWidgets(): void {
    const widgetsUrls = SdkConfig.get<any>('integrations_widgets_urls');
    if (widgetsUrls?.length > 0) {
        const widgetsUrlOrigin = new URL(widgetsUrls[0]).origin;
        // Ctalk add new action to widget sticker
        const iframe: any = document.querySelector('iframe[title="Stickerpack"]');
        iframe?.contentWindow?.postMessage({
            "api": "toWidget",
            "widgetId": "",
            "action": "clear_local_storage",
            "requestId": "",
        }, widgetsUrlOrigin);
    }
}
