import { ETimeLevel, ETimeUnit } from "@ctalk/enums/bot-form.enum";
import { IRangeTime } from "@ctalk/interfaces/rooms/IBotForm";

const DEFAULT_RANGE_TIME: IRangeTime = {
    from: ETimeLevel.HOUR,
    to: ETimeLevel.DAY,
}

export function millisecondsToTimeString(
    ms: number,
    rangeTime = DEFAULT_RANGE_TIME,
    _t: any,
    needToShowLessThan = false,
    roundUp = false,
): string {
    const arrUnitTime = millisecondsToArrayUnitTime(ms, rangeTime, needToShowLessThan, roundUp);
    return arrUnitTime.map((item:any) => {
        const unitTime = _t(`ctalk|bot_form|unit_time_${item.unit}`, {
            s: item.plural ? 's' : ''
        });
        const isLessThan = item.isLessThan && arrUnitTime.length === 1 ? `< ` : '';
        return `${isLessThan}${item.value} ${unitTime}`;
    }).join(" ");
}

export function millisecondsToArrayUnitTime(
    ms: number,
    rangeTime = DEFAULT_RANGE_TIME,
    needToShowLessThan = false,
    roundUp = false,
): any {
    const timeData = [];
    for (let level = rangeTime.to; level >= rangeTime.from; level--) {
        let value = 0;
        let unit = '';

        switch (level) {
            case ETimeLevel.YEAR:
                value = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));
                unit = ETimeUnit.YEAR;
                ms %= (1000 * 60 * 60 * 24 * 365);
                break;
            case ETimeLevel.MONTH:
                value = Math.floor(ms / (1000 * 60 * 60 * 24 * 30));
                unit = ETimeUnit.MONTH;
                ms %= (1000 * 60 * 60 * 24 * 30);
                break;
            case ETimeLevel.DAY:
                value = Math.floor(ms / (1000 * 60 * 60 * 24));
                unit = ETimeUnit.DAY;
                ms %= (1000 * 60 * 60 * 24);
                break;
            case ETimeLevel.HOUR:
                value = Math.floor(ms / (1000 * 60 * 60));
                unit = ETimeUnit.HOUR;
                ms %= (1000 * 60 * 60);
                break;
            case ETimeLevel.MINUTE:
                value = Math.floor(ms / (1000 * 60));
                unit = ETimeUnit.MINUTE;
                ms %= (1000 * 60);
                break;
            case ETimeLevel.SECOND:
                value = Math.floor(ms / 1000);
                unit = ETimeUnit.SECOND;
                ms %= 1000;
                break;
        }
        const hasValueLessThanFrom = value === 0 && level === rangeTime.from && ms > 0;
        if (value > 0 || hasValueLessThanFrom) {
            if (roundUp && value > 0 && ms > 0) {
                value+= 1;
            }
            timeData.push({
                unit: unit,
                plural: value > 1,
                value: value === 0 ? 1 : value,
                isLessThan: needToShowLessThan && hasValueLessThanFrom,
            });
        }
    }
    return timeData;
}

export function formatMillisecondsToDate(ms: number, format = 'dd/MM/yyyy hh:mm:ss'): string {
    const date = new Date(ms);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    format = format.replace('dd', day);
    format = format.replace('MM', month);
    format = format.replace('yyyy', year);
    format = format.replace('hh', hours);
    format = format.replace('mm', minutes);
    format = format.replace('ss', seconds);

    return format;
}

export function formatDate(date: Date, today?: Date, locale = 'en-US'): string{
    if(!today) {
        today = new Date();
    }
    [date, today].forEach((d) => d.setHours(0, 0, 0, 0));
    // today.setHours(0, 0, 0, 0);
    // date.setHours(0, 0, 0, 0);

    if (date.getTime() === today.getTime()) {
        return 'today';
    }

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long'
    };

    if(date.getFullYear() !== today.getFullYear()) {
        options.year = 'numeric';
    }

    return new Intl.DateTimeFormat(locale, options).format(date);
}

export function formatDateTime(date: Date, now?: Date, locale = 'en-US'): string[] {
    if(!now) {
        now = new Date();
    }
    if (date.toDateString() === now.toDateString()) {
        return ['today_at', '', new Intl.DateTimeFormat(locale, {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
        }).format(date)];
    }
    return ['date_at_time', new Intl.DateTimeFormat(locale, {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(date), new Intl.DateTimeFormat(locale, {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }).format(date)];
}
