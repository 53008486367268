import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import { UPDATE_EVENT } from "matrix-react-sdk/src/stores/AsyncStore";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { IContent, Room } from "matrix-js-sdk/src/matrix";
// CTalk imported
import { logger } from "matrix-js-sdk/src/logger";
import { ERoomMessageEventType, IGetWithPaginationDto } from "@ctalk/interfaces/rooms/IRoomMessageEvent";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";
import { CTalkClient } from "@ctalk/CTalkClient";
import { hasLinks } from "@ctalk/helpers/RoomEventInfoHelper";
import { getRetention, isRoomEnableRetention } from "@ctalk/components/views/AutoDelete";

// CTalk added
import { RightPanelStatusStore } from "./right-panel/RightPanelStatusStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState { }

export interface IPagination {
    from: number;
    to: number;
    total: number;
}

interface IEventInfo {
    eventIds: string[];
    pagination: IPagination;
    originalEvents: MatrixEvent[];
    dataLoaded: boolean;
}

export interface IRoomEvents {
    [ERoomMessageEventType.LINK]?: IEventInfo;
    [ERoomMessageEventType.FILE]?: IEventInfo;
    [ERoomMessageEventType.GIF]?: IEventInfo;
    [ERoomMessageEventType.MEDIA]?: IEventInfo;
}

const initPagination: IPagination = {
    from: 1,
    to: 10,
    total: 0,
};

export class RoomEventInfoStore extends AsyncStoreWithClient<IState> {
    private static readonly internalInstance = ((): RoomEventInfoStore => {
        const instance: RoomEventInfoStore = new RoomEventInfoStore();
        instance.start();
        return instance;
    })();

    private roomMap = new Map<string, IRoomEvents>(); // Key is room ID
    private ckClient: CTalkClient;
    public constructor() {
        super(defaultDispatcher, {
            widgetMap: new Map(),
        });
        this.ckClient = CTalkClientPeg.get();
    }

    private initRoom(roomId: string): void {
        const roomEventMap: { [key in ERoomMessageEventType]: IEventInfo } = {} as any;
        for (const eventType of Object.values(ERoomMessageEventType)) {
            roomEventMap[eventType] = {
                pagination: { ...initPagination },
                eventIds: [],
                originalEvents: [],
                dataLoaded: false,
            };
        }
        this.roomMap.set(roomId, { ...roomEventMap });
    }

    private checkAndRemoveOriginalEvent(ev: MatrixEvent, type: ERoomMessageEventType): boolean {
        const relation = ev.getRelation();
        if (relation) {
            const existedRelationEvent = this.eventIdExitedByType(
                ev.getRoomId()!,
                type,
                relation.event_id!,
            );
            if (existedRelationEvent) {
                this.deleteOriginalEvent(
                    ev.getRoomId()!,
                    type,
                    relation.event_id!,
                );
                return true;
            }
        }
        return false;
    }

    private handleRedactionEvent(ev: MatrixEvent, type: ERoomMessageEventType): void {
        if (ev.getType() === "m.room.redaction") {
            const eventId = ev.event?.redacts;
            const existedRedactsEvent = this.eventIdExitedByType(
                ev.getRoomId()!,
                type,
                eventId!,
            );
            if (existedRedactsEvent) {
                this.deleteRoomEventByType(
                    ev.getRoomId()!,
                    type,
                    eventId!,
                );
            }
        }
    }

    private handleNonRedactionEvent(ev: MatrixEvent, clearContent: any, type: ERoomMessageEventType): void {
        const hasDescriptionLinks = hasLinks(clearContent['description'] || clearContent.body);
        if (hasDescriptionLinks) {
            const existedEvent = this.eventIdExitedByType(
                ev.getRoomId()!,
                type,
                ev.getId()!,
            );
            if (!existedEvent) {
                this.addNewEventId(
                    ev.getRoomId()!,
                    type,
                    ev.getId()!,
                );
            }
        }
    }

    protected async onReady(): Promise<void> {
        this.emit(UPDATE_EVENT, null); // emit for all rooms
    }

    protected async onAction(payload: ActionPayload): Promise<void> {
        // We don't actually do anything here
        return;
    }

    public static get instance(): RoomEventInfoStore {
        return RoomEventInfoStore.internalInstance;
    }

    public getEventInfo(roomId: string): IRoomEvents | undefined {
        if (!this.roomMap.has(roomId)) {
            this.initRoom(roomId);
        }
        return this.roomMap.get(roomId);
    }

    public getEventIdsByType(roomId: string, type: ERoomMessageEventType): string[] {
        const roomEventInfo = this.roomMap.get(roomId);
        if (!roomEventInfo) {
            return [];
        }
        return roomEventInfo[type]?.eventIds ?? [];
    }

    public addNewEventIds(roomId: string, type: ERoomMessageEventType, eventIds: string[]): void {
        const oldEventIds = this.getEventIdsByType(roomId, type);
        if (!(oldEventIds && oldEventIds.length > eventIds.length)) {
            this.roomMap.set(roomId, {
                [type]: {
                    eventIds: eventIds,
                    pagination: {
                        from: 1,
                        to: 10,
                        total: 0,
                    },
                    originalEvents: [],
                },
            });
        }
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     * Check event id existed by type
     * @param roomId
     * @param type
     * @param eventId
     */
    public eventIdExitedByType(roomId: string, type: ERoomMessageEventType, eventId: string): boolean {
        const eventIds = this.getEventIdsByType(roomId, type);
        return eventIds.includes(eventId);
    }

    /**
     * Delete original event
     * @param roomId
     * @param type
     * @param eventId
     * @param eventInfo
     */
    public deleteOriginalEvent(roomId: string, type: ERoomMessageEventType, eventId: string, eventInfo?: IEventInfo): void {
        if (!eventInfo) {
            eventInfo = this.getEventInfo(roomId)?.[type];
        }
        if (!eventInfo) {
            return; // Exit the function if eventInfo is still undefined
        }
        eventInfo.originalEvents = eventInfo.originalEvents.filter(event => event.getId() !== eventId);
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     * delete room event by type
     * @param roomId
     * @param type
     * @param eventId
     */
    public deleteRoomEventByType(roomId: string, type: ERoomMessageEventType, eventId: string): void {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.eventIds = eventInfo.eventIds.filter((id: string) => id !== eventId);
        eventInfo.originalEvents = eventInfo.originalEvents.filter((event: MatrixEvent) => event.getId() !== eventId);
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     * delete room event
     * @param roomId
     * @param eventId
     */
    public deleteRoomEvent(roomId: string, eventId: string): void {
        const eventInfo = this.getEventInfo(roomId);
        if (!eventInfo) {
            return;
        }
        Object.keys(eventInfo).forEach((event) => {
            const eventData = eventInfo[event as keyof IRoomEvents];
            if (eventData) {
                eventData.eventIds = eventData.eventIds.filter((id: string) => id !== eventId);
            }
        });
        this.roomMap.set(roomId, { ...eventInfo });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }


    /**
     * reset data in room by roomId
     * @param roomId
     */
    public resetRoomEvent(roomId: string): void {
        this.initRoom(roomId);
    }

    /**
     * reset data in room by roomId
     * @param roomId
     */
    public resetRoomEventByType(roomId: string, type: ERoomMessageEventType): void {
        const roomEventMap: { [key in ERoomMessageEventType]: IEventInfo } = {} as any;
        roomEventMap[type] = {
            pagination: { ...initPagination },
            eventIds: [],
            originalEvents: [],
            dataLoaded: false,
        };
        this.roomMap.set(roomId, { ...roomEventMap });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     * Reset data in room by roomId
     *
     * @param roomId
     * @param type
     */
    public resetEventIds(roomId: string, type: ERoomMessageEventType): void {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.eventIds = [];
        eventInfo.pagination = {
            from: 1,
            to: 10,
            total: 0,
        };
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
    }

    /**
     * For call api load new data
     * @param roomId
     * @param type
     * @param eventIds
     * @param pagination
     */
    public setNewEvent(roomId: string, type: ERoomMessageEventType, eventIds: string[], pagination: IPagination): void {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        this.roomMap.set(roomId, {
            [type]: {
                eventIds: eventIds,
                pagination,
                originalEvents: eventInfo?.originalEvents,
                dataLoaded: true,
            },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     * For send new event, not call api get data
     * @param roomId
     * @param type
     * @param eventId
     */
    public addNewEventId(roomId: string, type: ERoomMessageEventType, eventId: string): void {
        // If you haven't opened the LinkPanel, you don't need to emit data
        if (!this.getEventInfo(roomId)?.[type]?.dataLoaded) {
            return;
        }
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.eventIds = [eventId, ...eventInfo.eventIds];
        // Do not call api get data, total + 1 when new event sent success
        eventInfo.pagination.total += 1;
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    public addPaginationEventIds(roomId: string, type: ERoomMessageEventType, eventIds: string[]): void {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.eventIds = [...eventInfo.eventIds, ...eventIds];
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    /**
     *
     * @param roomId
     * @param type
     * @param eventIds
     * @param pagination
     */
    public setPaginationEvent(roomId: string, type: ERoomMessageEventType, eventIds: string[], pagination: IPagination): void {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.eventIds = [...eventInfo.eventIds, ...eventIds];
        eventInfo.pagination = pagination;
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
        this.emit(UPDATE_EVENT, this.getEventInfo(roomId));
    }

    public setOriginalEvents(roomId: string, type: ERoomMessageEventType, eventTimeline: MatrixEvent): void {
        if (!eventTimeline) {
            return;
        }
        if (this.getOriginalEvents(roomId, type, eventTimeline.getId()!)) {
            return;
        }
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        eventInfo.originalEvents = [...eventInfo.originalEvents, eventTimeline];
        this.roomMap.set(roomId, {
            [type]: { ...eventInfo },
        });
    }

    public getOriginalEventsByType(roomId: string, type: ERoomMessageEventType): MatrixEvent[] {
        return this.getEventInfo(roomId)?.[type]?.originalEvents ?? [];
    }

    /**
     * Get original events
     * @param roomId
     * @param type
     * @param eventId
     */
    public getOriginalEvents(roomId: string, type: ERoomMessageEventType, eventId: string): MatrixEvent | undefined {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        return eventInfo?.originalEvents.find(e => e.getId() === eventId);
    }

    /**
     * Get pagination dto with size
     * @param roomId
     * @param type
     * @param size
     */
    public getPaginationDto(roomId: string, type: ERoomMessageEventType, size = 10): IGetWithPaginationDto | undefined {
        const eventInfo = this.getEventInfo(roomId)?.[type];
        if (!eventInfo) {
            return;
        }
        if (eventInfo.pagination.total === eventInfo.eventIds.length) {
            return;
        }
        const from = eventInfo.eventIds.length;
        return {
            roomId,
            type,
            from: from + 1, // start to next item
            to: from + size,
        };
    }

    /**
     * @param roomId
     * @param type
     */
    public getPagination(roomId: string, type: ERoomMessageEventType): IPagination | undefined {
        return this.getEventInfo(roomId)?.[type]?.pagination;
    }

    public async handleMessageHasLinks(event: MatrixEvent, newContent?: IContent): Promise<void> {
        if (!newContent) {
            return;
        }
        try {
            const type = ERoomMessageEventType.LINK;

            const eventIdExisted = await this.ckClient.getEventByIdAndType(
                event.getId()!,
                type,
            );

            const hasLinksInContent = hasLinks(newContent.formatted_body || newContent.body);

            // api put(event.getId(),type, hasLinksInContent)
            if (eventIdExisted && hasLinksInContent) {
                this.deleteOriginalEvent(
                    event.getRoomId()!,
                    type,
                    event.getId()!,
                );
            } else if (eventIdExisted) {
                this.ckClient.deleteRoomMessageEventByType(
                    event.getId()!,
                    type,
                );
            } else if (hasLinksInContent) {
                this.ckClient.createRoomMessageEvent({
                    eventId: event.getId()!,
                    roomId: event.getRoomId()!,
                    type,
                });
            }
        } catch (e) {
            logger.error(e);
        }
    }

    public handleMessageLinks(ev: MatrixEvent): void {
        const type = ERoomMessageEventType.LINK;

        if (this.checkAndRemoveOriginalEvent(ev, type)) {
            return;
        }

        const clearContent = ev.getClearContent() || ev.getContent();
        const panelOpened = RightPanelStatusStore.instance.getStatusPanel(type);

        if (panelOpened) {
            this.handleRedactionEvent(ev, type);
            this.handleNonRedactionEvent(ev, clearContent, type);
        }
    }

    public handleRetentionMessages(room: Room, type = ERoomMessageEventType.LINK): void {
        const retention = getRetention(room);
        if (!isRoomEnableRetention(retention)) {
            return;
        }
        this.resetRoomEventByType(room.roomId, type);
    }
}
