import React, { ChangeEvent } from "react";
import DialogButtons from "matrix-react-sdk/src/components/views/elements/DialogButtons";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { EFormType } from "@ctalk/enums/bot-form.enum";
import CRadio from "@ctalk/components/views/messages/form_components/CRadio";
import CFile from "@ctalk/components/views/messages/form_components/CFile";
import CTextarea from "@ctalk/components/views/messages/form_components/CTextarea";
import CFormInfo from "@ctalk/components/views/messages/form_components/CFormInfo";
import Modal from "matrix-react-sdk/src/Modal";
import SubmitProgressingDialog from "@ctalk/views/dialogs/SubmitProgressingDialog";
import { IBotFormValue, IFormValid } from "@ctalk/models/bot-form.interface";
import CTimeExpire from "@ctalk/components/views/messages/form_components/CTimeExpire";
import { compareEncryptFileContent, eventHasExpired, IEncryptFileContent } from "@ctalk/helpers/BotFormHelper";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import { SettingsSubsectionText } from "matrix-react-sdk/src/components/views/settings/shared/SettingsSubsection";
import StyledCheckbox from "matrix-react-sdk/src/components/views/elements/StyledCheckbox";

import BaseDialog from "../../../components/views/dialogs/BaseDialog";
import { _t } from "../../../languageHandler";

interface IProps {
    mxEvent: MatrixEvent;
    response?: IBotFormValue[];
    onFinished: (success: boolean) => void;
}

interface IState {
    dataForm: IBotFormValue[];
    expired: number;
    tooltipDisplayTime: number;
    isSubmitDisabled: boolean;
    requiredFields: IFormValid[];
    responseReviewed: boolean;
    dataResponse?: IBotFormValue[];
}

const formBodyTypes = new Map<string, typeof React.Component>([
    [EFormType.Radio, CRadio],
    [EFormType.File, CFile],
    [EFormType.Textarea, CTextarea],
]);

export default class CFormSubmitDialog extends React.Component<IProps, IState> {
    private bodyTypes = new Map<string, typeof React.Component>(formBodyTypes.entries());

    public constructor(props: IProps) {
        super(props);
        this.state = {
            dataForm: [],
            expired: 0,
            tooltipDisplayTime: 0,
            dataResponse: this.props.response ?? [],
            isSubmitDisabled: true,
            responseReviewed: false,
            requiredFields: [],
        };
    }

    public componentDidMount(): void {
        const content = this.props.mxEvent.getContent();
        const requiredFields: IFormValid[] = [];
        content.form.forEach((item: any) => {
            if (item.required) {
                requiredFields.push({
                    name: item.name,
                    isValid: false,
                });
            }
        });
        this.setState({
            expired: content.expired - new Date().getTime(),
            tooltipDisplayTime: content.expired,
            requiredFields,
        });
    }

    private onCancel = (): void => {
        this.props.onFinished(false);
    };

    private onSubmitResponse = async (): Promise<void> => {
        if (eventHasExpired(this.props.mxEvent)) {
            Modal.createDialog(ErrorDialog, {
                title: _t("ctalk|bot_form|error|submit_response_failed"),
                description: _t("ctalk|bot_form|error|form_has_expired"),
                onFinished: () => {
                    this.props.onFinished(true);
                }
            });
            return;
        }
        Modal.createDialog(SubmitProgressingDialog, {
            dataForm: this.state.dataForm,
            dataResponse: this.state.dataResponse,
            mxEvent: this.props.mxEvent,
            onFinished: async (response) => {
                if (response) {
                    this.props.onFinished(true);
                }
            },
        });
    };

    private onValueChange = (event: IBotFormValue): void => {
        const { name, value, type } = event;
        const { dataForm } = this.state;

        const dataIndex = dataForm
            .findIndex((item) => item.name === name);

        if (dataIndex !== -1) {
            const updatedDataForm = [...dataForm];
            updatedDataForm[dataIndex].value = value;
            if (type === EFormType.File && !value?.length) {
                // Remove field file if not have value
                updatedDataForm?.splice(dataIndex, 1);
            }
            this.setState({
                dataForm: updatedDataForm,
            });
        } else {
            this.setState({
                dataForm: [...dataForm, { name, value, type }],
            });
        }
    }

    private onFileNoteResponseChange = (event: IBotFormValue): void => {
        const { name, value, type } = event;
        const { dataResponse } = this.state;
        if (!dataResponse) {
            return;
        }
        const dataIndex = dataResponse.findIndex((item) => item.name === name);
        if (dataIndex !== -1) {
            const updatedDataForm = [...dataResponse];
            const fileResponseValue = updatedDataForm[dataIndex].value;
            const index = fileResponseValue.findIndex((item: IEncryptFileContent) => compareEncryptFileContent(item, value));
            fileResponseValue[index] = value.note;
            updatedDataForm[dataIndex].value = fileResponseValue;
            if (type === EFormType.File && !value?.length) {
                // Remove field file if not have value
                updatedDataForm?.splice(dataIndex, 1);
            }
            this.setState({
                dataResponse: updatedDataForm,
            });
        } else {
            this.setState({
                dataResponse: [...dataResponse, { name, value, type }],
            });
        }
    }

    private onValidationField = (data: IFormValid): void => {
        const { requiredFields } = this.state;
        if (!requiredFields.length) {
            this.setState({
                isSubmitDisabled: false,
            });
            return;
        }
        let isSubmitDisabled = true;
        const { name, isValid } = data;
        const dataIndex = requiredFields
            .findIndex((item) => item.name === name);
        const updatedField = [...requiredFields];
        if (dataIndex !== -1) {
            updatedField[dataIndex].isValid = isValid;
        }
        const validFields= updatedField.map(field => field.isValid);
        isSubmitDisabled = !validFields.every(v => v);
        this.setState({
            requiredFields: updatedField,
            isSubmitDisabled
        });
    }

    private onFileResponseRemoved = (event: IBotFormValue): void => {
        const { name, value } = event;
        const { dataResponse } = this.state;
        if (!dataResponse) {
            return;
        }
        const dataIndex = dataResponse
            .findIndex((item) => item.name === name);

        if (dataIndex !== -1) {
            const updatedDataForm = [...dataResponse];
            const responseValue = updatedDataForm[dataIndex].value;
            const valueIndex = responseValue.findIndex((item: IEncryptFileContent) => compareEncryptFileContent(item, value));

            if (valueIndex !== -1) {
                responseValue.splice(valueIndex, 1);
            }
            updatedDataForm[dataIndex].value = responseValue;
            this.setState({
                dataResponse: updatedDataForm,
            });
        }
    }

    private onResponseReviewedChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            responseReviewed: event.target.checked,
        });
    }

    public render(): React.ReactNode {
        const content = this.props.mxEvent.getContent();
        const { dataResponse } = this.state;
        const renderedComponents: React.ReactNode[] = [];

        content?.form.forEach((component: any, index: number) => {
            if (component?.type && this.bodyTypes.has(component?.type)) {
                const FormType = this.bodyTypes.get(component.type);
                const responseValue = dataResponse?.find(x => x.name === component.name);
                if (FormType) {
                    const key = `${component.name}_${index}`;
                    renderedComponents.push(
                        <FormType
                            key={key}
                            responseValue={responseValue}
                            {...component}
                            onValueChange={this.onValueChange}
                            onValidationField={this.onValidationField}
                            onFileResponseRemoved={this.onFileResponseRemoved}
                            onFileNoteResponseChange={this.onFileNoteResponseChange}
                        />
                    );
                }
            }
        });

        return (
            <BaseDialog
                onFinished={this.onCancel}
                title={_t("ctalk|bot_form|edit_submit_title")}
                className="ctalk_Confirmation_Form"
            >
                <CFormInfo
                    mxEvent={this.props.mxEvent}
                />
                <CTimeExpire
                    label={_t("ctalk|bot_form|expired_in")}
                    expireTime={this.state.expired}
                    tooltipDisplayTime={this.state.tooltipDisplayTime}
                    needToShowLessThan={true}
                />
                {renderedComponents}
                <StyledCheckbox
                    checked={this.state.responseReviewed}
                    onChange={this.onResponseReviewedChange}
                    className="mx_SidebarUserSettingsTab_checkbox ctalk_form_submit_checkbox"
                >
                    <SettingsSubsectionText>
                        {_t("ctalk|bot_form|checkbox_carefully")}
                    </SettingsSubsectionText>
                </StyledCheckbox>
                <DialogButtons
                    primaryButton={_t("ctalk|bot_form|button_send_response")}
                    onPrimaryButtonClick={this.onSubmitResponse}
                    hasCancel={true}
                    onCancel={this.onCancel}
                    focus={false}
                    primaryDisabled={this.state.isSubmitDisabled || !this.state.responseReviewed}
                    primaryButtonClass="primary"
                />
            </BaseDialog>
        );
    }
}
