/*
Copyright 2019 New Vector Ltd
Copyright 2019 Michael Telatynski <7t3chguy@gmail.com>
Copyright 2023 The Matrix.org Foundation C.I.C.


Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from "react";
import { RoomEvent, Room, RoomStateEvent, MatrixEvent, EventType } from "matrix-js-sdk/src/matrix";
import TabbedView, { Tab } from "matrix-react-sdk/src/components/structures/TabbedView";
import { _t, _td } from "matrix-react-sdk/src/languageHandler";
import AdvancedRoomSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/AdvancedRoomSettingsTab";
import RolesRoomSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/RolesRoomSettingsTab";
import GeneralRoomSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/GeneralRoomSettingsTab";
import SecurityRoomSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/SecurityRoomSettingsTab";
import NotificationSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/NotificationSettingsTab";
import BridgeSettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/room/BridgeSettingsTab";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { UIFeature } from "matrix-react-sdk/src/settings/UIFeature";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { VoipRoomSettingsTab } from "matrix-react-sdk/src/components/views/settings/tabs/room/VoipRoomSettingsTab";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import { NonEmptyArray } from "matrix-react-sdk/src/@types/common";
import { PollHistoryTab } from "matrix-react-sdk/src/components/views/settings/tabs/room/PollHistoryTab";
import ErrorBoundary from "matrix-react-sdk/src/components/views/elements/ErrorBoundary";
import { PeopleRoomSettingsTab } from "matrix-react-sdk/src/components/views/settings/tabs/room/PeopleRoomSettingsTab";
// CTalk imported
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import { SettingStoreEvent } from "@ctalk/enums/setting-store.enum";
import WhitelistedIPRoomSettingsTab from "@ctalk/components/views/settings/tabs/room/whitelist-ip/WhitelistedIPRoomSettingsTab";
import { isServerNoticeRoom } from "@ctalk/helpers/RoomHelper";
import { hasPermission } from "@ctalk/roles/PermissionView";

// CTalk added
import { IPowerLevelsContent } from '../right_panel/UserInfo';
import { getDeviceSettings } from "../../../utils/DeviceSettings";

export const enum RoomSettingsTab {
    General = "ROOM_GENERAL_TAB",
    People = "ROOM_PEOPLE_TAB",
    Voip = "ROOM_VOIP_TAB",
    Security = "ROOM_SECURITY_TAB",
    Roles = "ROOM_ROLES_TAB",
    Notifications = "ROOM_NOTIFICATIONS_TAB",
    Bridges = "ROOM_BRIDGES_TAB",
    Advanced = "ROOM_ADVANCED_TAB",
    PollHistory = "ROOM_POLL_HISTORY_TAB",
    // CTalk added
    WhitelistedIP = "ROOM_WHITELISTED_IP_TAB"
}

interface IProps {
    roomId: string;
    onFinished: (success?: boolean) => void;
    initialTabId?: string;
}

interface IState {
    room: Room;
}

class RoomSettingsDialog extends React.Component<IProps, IState> {
    private dispatcherRef?: string;

    public constructor(props: IProps) {
        super(props);

        const room = this.getRoom();
        this.state = { room };
    }

    public componentDidMount(): void {
        this.dispatcherRef = dis.register(this.onAction);
        MatrixClientPeg.safeGet().on(RoomEvent.Name, this.onRoomName);
        MatrixClientPeg.safeGet().on(RoomStateEvent.Events, this.onStateEvent);
        // CTalk added to check hidden tab real time
        MatrixClientPeg.safeGet().on(RoomStateEvent.Update, this.onRoomStateUpdate);
        this.onRoomName();
    }

    public componentDidUpdate(): void {
        if (this.props.roomId !== this.state.room.roomId) {
            const room = this.getRoom();
            this.setState({ room });
        }
    }

    public componentWillUnmount(): void {
        if (this.dispatcherRef) {
            dis.unregister(this.dispatcherRef);
        }

        MatrixClientPeg.get()?.removeListener(RoomEvent.Name, this.onRoomName);
        MatrixClientPeg.get()?.removeListener(RoomStateEvent.Events, this.onStateEvent);
        // CTalk added to check hidden tab real time
        MatrixClientPeg.get()?.removeListener(RoomStateEvent.Update, this.onRoomStateUpdate);
    }

    /**
     * Get room from client
     * @returns Room
     * @throws when room is not found
     */
    private getRoom(): Room {
        const room = MatrixClientPeg.safeGet().getRoom(this.props.roomId)!;

        // something is really wrong if we encounter this
        if (!room) {
            throw new Error(`Cannot find room ${this.props.roomId}`);
        }
        return room;
    }

    // CTalk added to check hidden tab real time
    private onRoomStateUpdate = () => {
        this.forceUpdate();
    };

    private onAction = (payload: ActionPayload): void => {
        // When view changes below us, close the room settings
        // whilst the modal is open this can only be triggered when someone hits Leave Room
        if (payload.action === Action.ViewHomePage) {
            this.props.onFinished(true);
        }
    };

    private onRoomName = (): void => {
        // rerender when the room name changes
        this.forceUpdate();
    };

    /**
     * CTalk added
     */
    private isDirectMessage = (): boolean => {
        return !!DMRoomMap.shared().getUserIdForRoomId(this.props.roomId);
    };

    private onStateEvent = (event: MatrixEvent): void => {
        if (event.getType() === EventType.RoomJoinRules) this.forceUpdate();
    };

    private isServerNotice = (): boolean => {
        return isServerNoticeRoom(this.props.roomId);
    };

    private getTabs(): NonEmptyArray<Tab<RoomSettingsTab>> {
        const tabs: Tab<RoomSettingsTab>[] = [];
        // CTalk added
        const blockNonAdminInvites = getDeviceSettings(SettingStoreEvent.blockNonAdminInvites);
        const client = MatrixClientPeg.safeGet();
        const room = client.getRoom(this.props.roomId);
        const powerLevelsEvent = room?.currentState.getStateEvents(EventType.RoomPowerLevels, "");
        const powerLevels = powerLevelsEvent && powerLevelsEvent.getContent() as IPowerLevelsContent;
        const roomMember = room?.getMember(client?.getUserId() || '');

        if ((!blockNonAdminInvites || (!this.isDirectMessage()  && !this.isServerNotice()))) { // CTalk added
            tabs.push(
                new Tab(
                    RoomSettingsTab.General,
                    _td("common|general"),
                    "mx_RoomSettingsDialog_settingsIcon",
                    <GeneralRoomSettingsTab room={this.state.room} />,
                    "RoomSettingsGeneral",
                ),
            );
            if (SettingsStore.getValue("feature_ask_to_join") && this.state.room.getJoinRule() === "knock") {
                tabs.push(
                    new Tab(
                        RoomSettingsTab.People,
                        _td("common|people"),
                        "mx_RoomSettingsDialog_peopleIcon",
                        <PeopleRoomSettingsTab room={this.state.room} />,
                    ),
                );
            }
            if (SettingsStore.getValue("feature_group_calls")) {
                tabs.push(
                    new Tab(
                        RoomSettingsTab.Voip,
                        _td("settings|voip|title"),
                        "mx_RoomSettingsDialog_voiceIcon",
                        <VoipRoomSettingsTab room={this.state.room} />,
                    ),
                );
            }
            /* CTalk moved this to below
            tabs.push(
                new Tab(
                    RoomSettingsTab.Security,
                    _td("room_settings|security|title"),
                    "mx_RoomSettingsDialog_securityIcon",
                    <SecurityRoomSettingsTab room={this.state.room} closeSettingsFn={() => this.props.onFinished(true)} />,
                    "RoomSettingsSecurityPrivacy",
                ),
            );
            */
            if (hasPermission(roomMember, powerLevels, 'm.room.power_levels', 'events')) { // CTalk added to check hidden tab
                tabs.push(
                    new Tab(
                        RoomSettingsTab.Roles,
                        _td("room_settings|permissions|title"),
                        "mx_RoomSettingsDialog_rolesIcon",
                        <RolesRoomSettingsTab room={this.state.room}/>,
                        "RoomSettingsRolesPermissions",
                    ),
                );
            }
            /* CTalk moved this to below
            tabs.push(
                new Tab(
                    RoomSettingsTab.Notifications,
                    _td("notifications|enable_prompt_toast_title"),
                    "mx_RoomSettingsDialog_notificationsIcon",
                    (
                        <NotificationSettingsTab
                            roomId={this.state.room.roomId}
                            closeSettingsFn={() => this.props.onFinished(true)}
                        />
                    ),
                    "RoomSettingsNotifications",
                ),
            );
            */

            if (SettingsStore.getValue("feature_bridge_state")) {
                tabs.push(
                    new Tab(
                        RoomSettingsTab.Bridges,
                        _td("room_settings|bridges|title"),
                        "mx_RoomSettingsDialog_bridgesIcon",
                        <BridgeSettingsTab room={this.state.room} />,
                        "RoomSettingsBridges",
                    ),
                );
            }

            tabs.push(
                new Tab(
                    RoomSettingsTab.PollHistory,
                    _td("right_panel|polls_button"),
                    "mx_RoomSettingsDialog_pollsIcon",
                    <PollHistoryTab
                        room={this.state.room}
                        onFinished={(): void => this.props.onFinished(true)}
                    />,
                ),
            );

            if (
                SettingsStore.getValue(UIFeature.AdvancedSettings) &&
                hasPermission(roomMember, powerLevels, 'm.room.power_levels', 'events') // CTalk added to check hidden tab
            ) {
                tabs.push(
                    new Tab(
                        RoomSettingsTab.Advanced,
                        _td("common|advanced"),
                        "mx_RoomSettingsDialog_warningIcon",
                        (
                            <AdvancedRoomSettingsTab
                                room={this.state.room}
                                closeSettingsFn={(): void => this.props.onFinished(true)}
                            />
                        ),
                        "RoomSettingsAdvanced",
                    ),
                );
            }
        }
        // CTalk added
        tabs.push(
            new Tab(
                RoomSettingsTab.Security,
                _td("room_settings|security|title"),
                "mx_RoomSettingsDialog_securityIcon",
                <SecurityRoomSettingsTab
                    room={this.state.room}
                    closeSettingsFn={(): void => this.props.onFinished(true)}
                />,
                "RoomSettingsSecurityPrivacy",
            ),
        );
        tabs.push(
            new Tab(
                RoomSettingsTab.Notifications,
                _td("notifications|enable_prompt_toast_title"),
                "mx_RoomSettingsDialog_notificationsIcon",
                (
                    <NotificationSettingsTab
                        roomId={this.state.room.roomId}
                        closeSettingsFn={(): void => this.props.onFinished(true)}
                    />
                ),
                "RoomSettingsNotifications",
            ),
        );

        if (
            !this.isDirectMessage() &&
            !this.isServerNotice() &&
            hasPermission(room?.getMember(client.getUserId()), powerLevels, 'config_whitelisted_ips')
        ) {
            tabs.push(new Tab(
                RoomSettingsTab.WhitelistedIP,
                _td("CTALK_WHITELISTED_IPS"),
                "mx_UserSettingsDialog_sidebarIcon",
                <WhitelistedIPRoomSettingsTab
                    client={client}
                    roomId={this.props.roomId}
                    closeSettingsFn={(): void => this.props.onFinished(true)}
                />,
            ));
        }


        return tabs as NonEmptyArray<Tab<RoomSettingsTab>>;
    }

    public render(): React.ReactNode {
        const roomName = this.state.room.name;
        return (
            <BaseDialog
                className="mx_RoomSettingsDialog"
                hasCancel={true}
                onFinished={this.props.onFinished}
                title={_t("room_settings|title", { roomName })}
            >
                <div className="mx_SettingsDialog_content">
                    <TabbedView
                        tabs={this.getTabs()}
                        initialTabId={this.props.initialTabId}
                        screenName="RoomSettings"
                    />
                </div>
            </BaseDialog>
        );
    }
}

const WrappedRoomSettingsDialog: React.FC<IProps> = (props) => (
    <ErrorBoundary>
        <RoomSettingsDialog {...props} />
    </ErrorBoundary>
);

export default WrappedRoomSettingsDialog;
