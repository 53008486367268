import { RoomMember, User } from "matrix-js-sdk/src/matrix";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";

const DEACTIVATED_NAME = "Deactivated User";

export async function isDeletedUser(userId: string): Promise<boolean> {
    // Call API to check info user
    const cli = MatrixClientPeg.safeGet();
    const profile = await cli.getProfileInfo(userId).catch(() => { return {}; });
    // profile { } -> user deleted
    return !profile || !Object.keys(profile).length;
}

export function isDeactivatedUser(user: User | RoomMember): boolean {
    const rawDisplayName = user?.rawDisplayName;
    if (!rawDisplayName) {
        return false;
    }
    return rawDisplayName === DEACTIVATED_NAME;
}
