import React from "react";
import { ICResponseItem, IRadioValue } from "@ctalk/models/bot-form.interface";
import { EFormType } from "@ctalk/enums/bot-form.enum";
import classNames from "classnames";
import TextWithTooltip from "matrix-react-sdk/src/components/views/elements/TextWithTooltip";

import { _t } from "../../../../../languageHandler";
import PreviewFile from "../../elements/PreviewFile";

interface IProps extends ICResponseItem {
    viewResponseExpired?: number;
    previewFileExpired?: boolean;
}

export default class CResponseItem extends React.Component<IProps, {}> {

    public constructor(props: IProps) {
        super(props);
    }

    private fileRendered = (): React.ReactNode => {
        const fileCount = this.props.responseValue?.length ?? 0;
        const className = classNames("ctalk_response_file_text", {
            ctalk_response_file_line: fileCount > 0,
        });

        return (
            <>
                <div className={className}>
                    {
                        fileCount === 1
                            ? _t("ctalk|bot_form|edit_count_selected_file", { count: fileCount})
                            : _t("ctalk|bot_form|edit_plural_count_selected_file", { count: fileCount})
                    }
                </div>
                {
                    // TODO define type
                    this.props.responseValue && this.props.responseValue.map((value: any, index: number) => (
                        <div
                            className="ctalk_response_file_item"
                            key={`${index}_${value.filename}`}
                        >
                            {
                                this.props.previewFileExpired ?
                                <TextWithTooltip tooltip={value.filename}>
                                    <div className="ctalk_response_file_name">
                                        {value.filename}
                                    </div>
                                </TextWithTooltip> :
                                <PreviewFile
                                    file={value}
                                    fileName={value.filename}
                                    note={value.note}
                                    isNotShortenFileName={true}
                                />
                            }
                            <div className="ctalk_response_file_note">
                                {value.note}
                            </div>
                        </div>
                    ))
                }
            </>
        );
    }

    private getValueByType = (): React.ReactNode => {
        const { type, values, responseValue } = this.props;
        switch (type) {
            case EFormType.Radio:
                // eslint-disable-next-line no-case-declarations
                const value = values?.find((v: IRadioValue) => v.value === responseValue);
                return (
                    <div className="ctalk_response_item_content">
                        {value?.label}
                    </div>
                );
            case EFormType.File:
                return this.fileRendered();
            case EFormType.Textarea:
                return (
                    <div className="ctalk_response_item_content">
                        {responseValue}
                    </div>
                );
            default:
                return <></>;
        }
    }

    public render(): React.ReactNode {
        return (
            <div className={`ctalk_response_item ctalk_response_item_${this.props.type} ctalk_response_item_text`}>
                <div className="ctalk_response_item_label">
                    {this.props.label}
                </div>
                {this.getValueByType()}
            </div>
        );
    }
}
