import React, { useEffect, useState } from "react";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import { _t } from "matrix-react-sdk/src/languageHandler";
import Field from "matrix-react-sdk/src/components/views/elements/Field";
import DialogButtons from "matrix-react-sdk/src/components/views/elements/DialogButtons";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";
import { MatrixClient } from "matrix-js-sdk/src/client";
import classNames from "classnames";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";

import { ETypeDialog } from "./WhitelistedIPRoomSettingsTab";

export const IPV4PATTERN =
    // eslint-disable-next-line max-len
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IPV6PATTERN =
    // eslint-disable-next-line max-len
    /^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$|^(?=(?:.*?[0-9a-fA-F]{1,4}:){2,})[0-9a-fA-F]{1,4}(?::[0-9a-fA-F]{1,4}){1,6}$/;

const initValidations = {
    ip: {
        isValid: false,
        messageErr: '',
    },
    name: {
        isValid: false,
        messageErr: '',
    },
};

interface IProps {
    client: MatrixClient;
    typeOpen: ETypeDialog;
    roomId: string;
    recordUpdate?: any;
    onFinished(): void;
}

export default function WhitelistedIPRoomSettingsDialog(props: IProps): React.FC<IProps> {
    const { client, typeOpen, roomId, recordUpdate } = props;
    const [loading, setLoading] = useState(false);
    const [validations, setValidations] = useState(initValidations);
    const [record, setRecord] = useState({
        ip: '',
        name: '',
        roomId: '',
    });
    const [err, setErr] = useState('');
    const ckClient = CTalkClientPeg.get();

    useEffect(() => {
        if (typeOpen === ETypeDialog.EDIT || typeOpen === ETypeDialog.DELETE) {
            setRecord({
                ...recordUpdate,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSave = (): void => {
        setLoading(true);
        switch (typeOpen) {
            case ETypeDialog.CREATE: {
                const newRecord = {
                    ip: record.ip.trim(),
                    name: record.name?.trim(),
                    createdAt: new Date(),
                    createdBy: client.getRoom(roomId)?.myUserId,
                    roomId: roomId,
                };
                ckClient.createRoomWhiteListIp(newRecord, roomId).then(() => {
                    props.onFinished(true);
                }).catch(error => {
                    if (error?.errcode === 'M_RECORD_ALREADY_EXISTS') {
                        setValidations({
                            ...validations,
                            ip: {
                                isValid: true,
                                messageErr: _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP_EXISTED"),
                            },
                        });
                    } else {
                        setErr(_t("ctalk|error|failed_to_request"));
                    }
                }).finally(() => {
                    setLoading(false);
                });
                break;
            }
            case ETypeDialog.EDIT: {
                const recordEdit = {
                    ...record,
                    name: record.name?.trim(),
                };
                ckClient.updateRoomWhiteListIp(
                    recordEdit,
                    recordEdit.roomId,
                    recordEdit.ip,
                ).then(() => {
                    props.onFinished(true);
                }).catch(error => {
                    if (error?.errcode === 'M_NOT_FOUND') {
                        setErr(_t("CTALK_WHITELISTED_IPS_WAS_DELETED"));
                    } else {
                        setErr(error?.error);
                    }
                }).finally(() => {
                    setLoading(false);
                });
                break;
            }
            case ETypeDialog.DELETE: {
                ckClient.deleteRoomWhiteListIp(record.roomId, record.ip).then(() => {
                    props.onFinished(true);
                }).catch(error => {
                    if (error?.errcode === 'M_NOT_FOUND') {
                        setErr(_t("CTALK_WHITELISTED_IPS_WAS_DELETED"));
                    } else {
                        setErr(error?.error);
                    }
                }).finally(() => {
                    setLoading(false);
                });
                break;
            }
            default:
                break;
        }
    };

    const onCancel = (): void => {
        if (err?.length) {
            props.onFinished(true);
        } else {
            props.onFinished(false);
        }
    };

    const checkIsValid = (value, field): boolean => {
        switch (field) {
            case 'ip':
                return (
                    !value.length ||
                    value.length > 255 ||
                    (/^\s*$/.test(value?.trim())) ||
                    (!IPV6PATTERN.test(value) &&
                    !IPV4PATTERN.test(value))
                );
            case 'name':
                return (
                    !value.length ||
                    value.length > 64 ||
                    (/^\s*$/.test(value?.trim()))
                );
            default:
                return false;
        }
    };

    const handleMessageErr = (value, field): string => {
        if (!value.length && field === 'ip') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP_REQUIRED");
        }
        if (/^\s*$/.test(value?.trim()) && field === 'ip') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP_NOT_NULL");
        }
        if (value.length > 255 && field === 'ip') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP_LENGTH");
        }
        if ((!IPV4PATTERN.test(value) && !IPV6PATTERN.test(value)) && field === 'ip') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP_FORMAT");
        }
        if (!value.length && field === 'name') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_NAME_REQUIRED");
        }
        if (value.length > 64 && field === 'name') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_NAME_LENGTH");
        }
        if (/^\s*$/.test(value?.trim()) && field === 'name') {
            return _t("CTALK_WHITELISTED_IPS_DIALOG_FIELD_NAME_NOT_NULL");
        }
    };

    const onValueChange = (value, field): void => {
        const valueInput = value;
        setRecord({
            ...record,
            [field]: valueInput,
        });
        setValidations({
            ...validations,
            [field]: {
                isValid: checkIsValid(valueInput, field),
                messageErr: handleMessageErr(valueInput, field),
            },
        });
    };

    const ipAddressClassName = classNames("ctalk_input_required", {
        "ctalk_input_ip_address_disabled": typeOpen === ETypeDialog.EDIT,
    });

    return (
        <BaseDialog
            title={
                err.length ? _t("ctalk|error|failed_to_request") :
                    typeOpen === ETypeDialog.CREATE && _t('CTALK_WHITELISTED_IPS_ADD_DIALOG_TITLE') ||
                    typeOpen === ETypeDialog.EDIT && _t('CTALK_WHITELISTED_IPS_UPDATE_DIALOG_TITLE') ||
                    typeOpen === ETypeDialog.DELETE && _t('CTALK_WHITELISTED_IPS_DELETE_IP', { ip: record?.ip })
            }
            hasCancel={false}
            className="mx_DialogActionWhitelist"
            onFinished={props.onFinished}
            fixedWidth={false}
        >
            {
                !err.length ? <>
                    {
                        (typeOpen === ETypeDialog.CREATE || typeOpen === ETypeDialog.EDIT) &&
                        <>
                            <Field
                                id="name"
                                type="text"
                                label={_t('CTALK_WHITELISTED_IPS_DIALOG_FIELD_NAME')}
                                autoComplete="off"
                                onChange={(e): void => onValueChange(e.target.value, e.target.id)}
                                onClick={(e): void => onValueChange(e.target.value, e.target.id)}
                                value={record.name}
                                className="ctalk_input_required"
                            />
                            <div className="invalidText">
                                { validations.name.messageErr }
                            </div>
                            <Field
                                id="ip"
                                type="text"
                                label={_t('CTALK_WHITELISTED_IPS_DIALOG_FIELD_IP')}
                                autoComplete="off"
                                onChange={(e): void => onValueChange(e.target.value, e.target.id)}
                                onClick={(e): void => onValueChange(e.target.value, e.target.id)}
                                disabled={typeOpen === ETypeDialog.EDIT}
                                value={record.ip}
                                style={{
                                    color: typeOpen === ETypeDialog.EDIT ? "inherit" : undefined,
                                }}
                                className={ipAddressClassName}
                            />
                            <div className="invalidText">
                                { validations.ip.messageErr }
                            </div>
                        </>
                    }
                    {
                        typeOpen === ETypeDialog.DELETE && <>
                            { _t('CTALK_WHITELISTED_IPS_DELETE_CONFIRM') }
                        </>
                    }
                    <DialogButtons
                        primaryButton={
                            loading ? <InlineSpinner /> :
                                (
                                    (typeOpen === ETypeDialog.CREATE || typeOpen === ETypeDialog.EDIT) &&
                                    _t("action|save") ||
                                    typeOpen === ETypeDialog.DELETE && _t("action|delete")
                                )
                        }
                        primaryButtonClass={typeOpen === ETypeDialog.DELETE && "mx_DialogButtonDelete"}
                        primaryDisabled={
                            loading ||
                            validations.ip.isValid ||
                            validations.name.isValid ||
                            !record.ip ||
                            !record.name
                        }
                        cancelButton={_t("action|cancel")}
                        hasCancel={true}
                        onPrimaryButtonClick={onSave}
                        onCancel={onCancel}
                    />
                </> : <>
                    <div className="invalidText">
                       {err}
                    </div>
                    <DialogButtons
                        primaryButton={_t('action|ok')}
                        hasCancel={false}
                        onPrimaryButtonClick={onCancel}
                    />
                </>
            }
        </BaseDialog>
    );
}
