import {
    EncryptedFile,
    IMediaEventContent,
    IMediaEventInfo
} from "matrix-react-sdk/src/customisations/models/IMediaEventContent";
import { IFileValue } from "@ctalk/components/views/messages/form_components/CFile";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { EFormStatus } from "@ctalk/enums/bot-form.enum";

export interface IEncryptFileContent {
    url: string;
    key: string;
    iv: string;
    hashed: string;
    filename: string;
    note: string;
    mime_type: string;
}

export interface ILatestEventInfo {
    event: MatrixEvent;
    index: number;
}

export interface IBotEncryptedFile extends EncryptedFile {
    filename: string;
}

export function compactEncryptFileContent(
    data: Omit<IMediaEventContent, "info"> & { info: Partial<IMediaEventInfo> },
    fileVales: IFileValue,
): IEncryptFileContent | undefined {
    const {file, body} = data;
    if (!file) {
        return;
    }
    return {
        url: file.url,
        key: file.key?.k,
        iv: file.iv,
        hashed: file.hashes?.sha256,
        filename: body,
        note: fileVales.note,
        mime_type: fileVales.file.type,
    }
}

export function deCompactEncryptFileContent (compact: IEncryptFileContent): IBotEncryptedFile {
    return {
        url:compact.url,
        v: "v2",
        key: {
            alg: "A256CTR",
            ext: true,
            k: compact.key,
            key_ops: ["encrypt", "decrypt"],
            kty: "oct"
        },
        iv: compact.iv,
        hashes:{
            sha256: compact.hashed,
        },
        filename: compact.filename,
    }
}

export function compareEncryptFileContent(obj1: IEncryptFileContent, obj2: IEncryptFileContent): boolean {
    // Destructure objects to remove 'note' property without modifying the original objects
    const { note: note1, ...dataA } = obj1;
    const { note: note2, ...dataB } = obj2;

    // Sort keys of objects
    const sortedDataA = sortObjectKeys(dataA);
    const sortedDataB = sortObjectKeys(dataB);

    // Convert objects to JSON strings for comparison
    const str1 = JSON.stringify(sortedDataA);
    const str2 = JSON.stringify(sortedDataB);

    // Compare the JSON strings
    return str1 === str2;
}

export function getLatestEventInfo(events: MatrixEvent[], status: EFormStatus): ILatestEventInfo | null {
    for (let i = events.length - 1; i >= 0; i--) {
        if (events[i].getContent().status === status) {
            return {
                event: events[i],
                index: i,
            };
        }
    }
    return null;
}

function sortObjectKeys(obj: any): any {
    const sorted: any = {};
    Object.keys(obj).sort().forEach(key => {
        sorted[key] = obj[key];
    });
    return sorted;
}

export function eventHasExpired(mxEvent: MatrixEvent): boolean {
    const content = mxEvent.getContent();
    const expireTime = content.expired - new Date().getTime();
    return expireTime <= 0;
}
