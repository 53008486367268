import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    padding: '8px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    background: 'inherit',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
                menuItem: {
                    fontSize: '16px',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: 'inherit',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "width": '70px !important',
                    "border": 'unset !important',
                    "backgroundColor": 'inherit !important',
                    "&.Mui-disabled": {
                        backgroundColor: 'transparent !important',
                        color: 'rgb(87 76 76 / 83%)!important',
                        border: 'unset !important',
                    },
                },
                colorError: {
                    color: '#ff0000 !important',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 5000,
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                ul: {
                    flexWrap: 'nowrap',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    'border': 'unset !important',
                    'background': 'unset !important',
                    'marginBottom': 'unset',
                    "&.Mui-disabled": {
                        backgroundColor: 'transparent !important',
                        color: 'rgb(87 76 76 / 83%)!important',
                        border: 'unset !important',
                    },
                    "&.Mui-selected": {
                        backgroundColor: 'rgb(85 73 73 / 12%)!important',
                    },
                },
            },
        },
    },
});

export default muiTheme;
