import React, { createRef, RefObject } from "react";
import Emoji from "matrix-react-sdk/src/components/views/emojipicker/Emoji";
import { Emoji as IEmoji, getEmojiFromUnicode } from "@matrix-org/emojibase-bindings";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import * as recent from "matrix-react-sdk/src/emojipicker/recent";
import RoomContext from "matrix-react-sdk/src/contexts/RoomContext";
import { Icon as EmojiAddIcon } from "matrix-react-sdk/res/img/element-icons/room/message-bar/emoji.svg";
import { Relations } from "matrix-js-sdk/src/models/relations";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { FocusComposerPayload } from "matrix-react-sdk/src/dispatcher/payloads/FocusComposerPayload";
import { EventType, RelationType } from "matrix-js-sdk/src/@types/event";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { filterBoolean } from 'matrix-react-sdk/src/utils/arrays';
import { SettingStoreEvent } from '@ctalk/enums/setting-store.enum';

import SettingsStore from "../../../settings/SettingsStore";

interface IProps {
    mxEvent: MatrixEvent;
    reactions?: Relations | null | undefined;
    onMoreClick: () => void;
    onChoose: () => void;
    inputRef: RefObject<HTMLElement>;
}

interface IState {
    selectedEmojis: Set<string>;
    left?: number;
}

export class ReactionsMenu extends React.Component<IProps, IState> {
    public static contextType = RoomContext;
    public static MAX_QUICK_EMOJI = 7;
    private readonly reactionsMenuRef: RefObject<HTMLDivElement>;
    private reactions: IEmoji[] = [];
    public context!: React.ContextType<typeof RoomContext>;

    public constructor(props: IProps, context: React.ContextType<typeof RoomContext>) {
        super(props, context);
        this.state = {
            selectedEmojis: new Set(Object.keys(this.getReactions())),
        };
        this.reactionsMenuRef = createRef<HTMLDivElement>();
        this.initReactions();
    }

    protected initReactions(): void {
        const frequentlySetting = SettingsStore.getValue(SettingStoreEvent.reactionsMenuFrequentlyUsed);
        const defaultReactions: IEmoji[] = ["👍", "👎", "😄", "🎉", "😕", "❤️", "🚀", "👀"].map((eu) => getEmojiFromUnicode(eu)!);
        let reactions: IEmoji[] = [];
        if (!frequentlySetting) {
            reactions = [...defaultReactions];
        } else {
            const recentlyUsed: IEmoji[] = filterBoolean<IEmoji>(recent.get(7).map((emoji) => {
                const data = getEmojiFromUnicode(emoji);
                if (!data) {
                    return;
                }
                return data;
            }));
            reactions = [...recentlyUsed, ...defaultReactions];
        }
        this.reactions = reactions.slice(0, ReactionsMenu.MAX_QUICK_EMOJI);
    }

    public componentDidMount(): void {
        const bounding = this.reactionsMenuRef.current?.getBoundingClientRect() || {} as DOMRect;
        if ((bounding.left + bounding.width) > window.innerWidth) {
            const left = window.innerWidth - (bounding.left + bounding.width) - 10;
            const parent = this.reactionsMenuRef.current?.parentElement?.getBoundingClientRect();
            this.setState({
                left: Math.max(left, (parent?.width || 0) - bounding.width),
            } as IState);
        }
    }

    private getReactions(): Record<string, string> {
        if (!this.props.reactions) {
            return {};
        }
        const userId = MatrixClientPeg.safeGet().getSafeUserId()!;
        const myAnnotations = this.props.reactions.getAnnotationsBySender()?.[userId] || [];
        return Object.fromEntries([...myAnnotations]
            .filter(event => !event.isRedacted())
            .map(event => [event.getRelation()?.key, event.getId()]));
    }

    /**
     * This method cloned from ReactionPicker.onChoose(reaction: string);
     *
     * @param reaction
     */
    private reactEvent = (reaction: string): boolean => {
        const myReactions = this.getReactions();
        if (myReactions.hasOwnProperty(reaction)) {
            if (this.props.mxEvent.isRedacted() || !this.context.canSelfRedact) return false;

            MatrixClientPeg.safeGet().redactEvent(this.props.mxEvent.getRoomId()!, myReactions[reaction]);
            dis.dispatch<FocusComposerPayload>({
                action: Action.FocusAComposer,
                context: this.context.timelineRenderingType,
            });
            // Tell the emoji picker not to bump this in the more frequently used list.
            return false;
        } else {
            MatrixClientPeg.safeGet().sendEvent(this.props.mxEvent.getRoomId()!, EventType.Reaction, {
                "m.relates_to": {
                    rel_type: RelationType.Annotation,
                    event_id: this.props.mxEvent.getId(),
                    key: reaction,
                },
            });
            dis.dispatch({ action: "message_sent" });
            dis.dispatch<FocusComposerPayload>({
                action: Action.FocusAComposer,
                context: this.context.timelineRenderingType,
            });
            return true;
        }
    };

    private onEmojiClick = (emoji: IEmoji): void => {
        if (this.reactEvent(emoji.unicode)) {
            recent.add(emoji.unicode);
        }
        this.props.onChoose();
    };

    private onMouseEnter = (): void => {
    };

    private onMouseLeave = (): void => {
    };

    public render(): React.ReactNode {
        const theme = SettingsStore.getValue("theme");
        return <div
            id="ctalk_ContextualMenu_Reactions"
            className="ctalk_MenuReactions_container"
            style={{ left: this.state.left }}
            ref={this.reactionsMenuRef}
            data-mx-theme={theme}
        >
            <span ref={this.props.inputRef} style={{ left: '-10px', position: 'absolute' }} />
            <ul className="ctalk_MenuReactions_wrapper">
                {
                    this.reactions.map(emoji => (
                        <Emoji
                            key={emoji.hexcode}
                            emoji={emoji}
                            selectedEmojis={this.state.selectedEmojis}
                            onClick={(): void => this.onEmojiClick(emoji)}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                    ))
                }<li role="menuitem" className="mx_AccessibleButton mx_EmojiPicker_item_wrapper">
                    <div className="mx_EmojiPicker_item" onClick={this.props.onMoreClick}>
                        <EmojiAddIcon width={24} height={24} />
                    </div>
                </li>
            </ul>
        </div>;
    }
}
