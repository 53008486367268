export function _tPlural(key: string, size: number): string {
    return key + (size > 1 ? '_PLURAL' : '');
}

export function normalizeNbsp(inputString: string): string {
    const regExp = /(?<!^|">|&nbsp;|<br>|<br>\n)(&nbsp;)(?!&nbsp;)/g;
    return inputString.replace(regExp, ' ');
}

export function preventMarkdown(text: string): string {
    if (/^(\d+)\.\s/.test(text)) {
        return text.replace(/^(\d+)\.\s/, '$1.&nbsp;');
    } else if (/^[-*+]\s/.test(text)) {
        return text.replace(/^([-*+])\s/, '$1&nbsp;');
    } else if (/^(#{1,6})\s/.test(text)) {
        return text.replace(/^(#{1,6})\s/, '$1&nbsp;');
    } else if (/^(-{2,})/.test(text)) {
        return text.replace(/^(-{2,})/, '&nbsp;$1');
    } else if (/^(={2,})/.test(text)) {
        return text.replace(/^(={2,})/, '&nbsp;$1');
    } else {
        return text;
    }
}

// Function to get file extension from mimetype
// Follow https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export function getFileExtension(mimetype: string): string {
    const mimeMap: { [key: string]: string } = {
        // Video types
        'video/mp4': 'mp4',
        'video/quicktime': 'mov',
        'video/x-msvideo': 'avi',
        'video/mpeg': 'mpeg',
        'video/ogg': 'ogv',
        'video/webm': 'webm',
        'video/3gpp': '3gp',
        'video/3gpp2': '3g2',
        'video/mp2t': 'ts',

        // Audio types
        'audio/mpeg': 'mp3',
        'audio/wav': 'wav',
        'audio/ogg': 'oga',
        'audio/mp4': 'm4a',
        'audio/aac': 'aac',
        'audio/midi': 'mid',
        'audio/x-midi': 'midi',
        'audio/webm': 'weba',

        // Image types
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp',
        'image/apng': 'apng',
        'image/avif': 'avif',
        'image/svg+xml': 'svg',

        // Document types
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/epub+zip': 'epub',
        'application/x-abiword': 'abw',
        'application/x-freearc': 'arc',
        'application/x-tar': 'tar',
        'application/x-bzip': 'bz',
        'application/x-bzip2': 'bz2',
        'application/x-7z-compressed': '7z',
        'application/zip': 'zip',
        'application/x-rar-compressed': 'rar',
        'application/x-httpd-php': 'php',
        'application/xml': 'xml',
        'application/vnd.visio': 'vsd',
        'application/ld+json': 'jsonld',
        'application/java-archive': 'jar',
        'application/x-sh': 'sh',
        'application/x-csh': 'csh',
        'application/x-cdf': 'cda',
        'application/vnd.apple.installer+xml': 'mpkg',
        'application/vnd.ms-fontobject': 'eot',
        'application/vnd.mozilla.xul+xml': 'xul',
        'application/rtf': 'rtf',

        // Text types
        'text/plain': 'txt',
        'text/html': 'html',
        'text/css': 'css',
        'text/csv': 'csv',
        'text/calendar': 'ics',
        'text/javascript': 'js',

        // Font types
        'font/otf': 'otf',
        'font/ttf': 'ttf',
        'font/woff': 'woff',
        'font/woff2': 'woff2',

        // Other
        'application/octet-stream': 'bin',
        'application/gzip': 'gz'
    };

    return mimeMap[mimetype] || '';
}

// Function to check if a filename already has an extension
export function hasExtension(fileName: string): boolean {
    return /^[^.][\w\-. ()]*[^.]$/.test(fileName) && /\.[^.]+$/.test(fileName);
}

// Function to create filename from body and mimetype
export function createFileName(body: string, mimetype: string): string {
    if (hasExtension(body)) {
        return body;
    }
    const extension = getFileExtension(mimetype);
    return extension ? `${body}.${extension}` : body;
}

export function convertDtoToQueryString(dto: Record<string, any>): string {
    const queryParts = [];

    for (const key in dto) {
        if (dto[key] !== undefined) {
            queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(dto[key])}`);
        }
    }

    return queryParts.join('&');
}

/**
 * Shorten string.
 * @param {string} str input string
 * @param {number} max characters
 * @param {string} condition customize the separator
 *
 * @returns {string}
 *
 * @example
 * console.log( truncate("123456789abcde") ); // 123...bcde (using built-in defaults)
 * console.log( truncate("123456789abcde", 8) ); // 12...cde (max of 8 characters)
 * console.log( truncate("123456789abcde", 12, "_") ); // 12345_9abcde (customize the separator)
 */
export function truncate(str: string, max?: number, sep?: string): string {
    // Default to 10 characters
    max = max || 20;

    const len = str.length;
    if (len > max) {
        // Default to elipsis
        sep = sep || "...";

        const seplen = sep.length;

        // If separator is larger than the character limit,
        // well then we don't want to just show the separator,
        // so just show right-hand side of the string.
        if (seplen > max) {
        return str.substring(len - max);
        }

        // Half the difference between max and string length.
        // Multiply negative because small minus big.
        // Must account for length of separator too.
        const n = -0.5 * (max - len - seplen);

        // This gives us the centerline.
        const center = len / 2;

        const front = str.substring(0, center - n);
        const back = str.substring(len - center + n); // without second arg, will automatically go to end of line.

        return front + sep + back;
    }

    return str;
}

export function normalizeInput(value: string): string {
    if (!value) {
        return "";
    }
    return value.normalize("NFC");
};

export function removeMarkdown(value: string): string {
    return value
        .replace(/^>.*\n\n/, '') // Remove block quote content
        .replace(/^#{1,6}\s+/gm, '') // Remove Markdown headers
        .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1') // Remove Markdown links
        .replace(/\*\*(.*?)\*\*/g, '$1') // Remove bold formatting
        .replace(/\*(.*?)\*/g, '$1') // Remove italic formatting
        .replace(/__(.*?)__/g, '$1') // Remove bold formatting (double underscore)
        .replace(/_(.*?)_/g, '$1') // Remove italic formatting (underscore)
        .replace(/`([^`]*)`/g, '$1') // Remove inline code
        .replace(/```[\s\S]*?```/g, '') // Remove code blocks
        .replace(/^\s*>[\s\S]*?^\s*(?=^[^\s>])/gm, '') // Remove Multiple line quote
        .replace(/^\s*>+\s*/gm, '') // Remove quotes
        .replace(/\\/g, '') // Remove backslashes
        .replace(/\n{2,}/g, '\n') // Convert multiple blank lines to one blank line
        .replace(/<\/?del>/gi, '') // Remove <del> tag
        .trim(); // Removes extra whitespace at the beginning and end of the string
}
