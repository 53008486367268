/*
Copyright 2015 - 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { ReactNode } from "react";
import { decode } from "blurhash";
import { logger } from "matrix-js-sdk/src/logger";
import { _t, TranslationKey } from "matrix-react-sdk/src/languageHandler";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";
import { mediaFromContent } from "matrix-react-sdk/src/customisations/Media";
import { BLURHASH_FIELD } from "matrix-react-sdk/src/utils/image-media";
import { IMediaEventContent } from "matrix-react-sdk/src/customisations/models/IMediaEventContent";
import { IBodyProps } from "matrix-react-sdk/src/components/views/messages/IBodyProps";
import MFileBody from "matrix-react-sdk/src/components/views/messages/MFileBody";
import { ImageSize, suggestedSize as suggestedVideoSize } from "matrix-react-sdk/src/settings/enums/ImageSize";
import RoomContext, { TimelineRenderingType } from "matrix-react-sdk/src/contexts/RoomContext";
// import MediaProcessingError from "matrix-react-sdk/src/components/views/messages/shared/MediaProcessingError";
// CTalk added
import classNames from "classnames";
import { downloadError } from "@ctalk/utils/message-error";
import { Layout } from "matrix-react-sdk/src/settings/enums/Layout";
import { getForwardedEventInfo, isSafari } from "@ctalk/utils/helper";

import MediaProcessingError from "./shared/MediaProcessingError";

interface IState {
    decryptedUrl: string | null;
    decryptedThumbnailUrl: string | null | any;
    decryptedBlob: Blob | null;
    error?: any;
    fetchingData: boolean;
    posterLoading: boolean;
    blurhashUrl: string | null;
    // CTalk added
    layout: string;
    autoPlayVideo: boolean;
    showButtonPlaceholder: boolean;
}

const FILE_DELETED = 'deleted';
const WIDTH_SIZE_VIDEO_NARROW = 320;

export default class MVideoBody extends React.PureComponent<IBodyProps, IState> {
    public static contextType = RoomContext;
    public context!: React.ContextType<typeof RoomContext>;

    private videoRef = React.createRef<HTMLVideoElement>();
    private sizeWatcher?: string;

    public constructor(props: IBodyProps) {
        super(props);

        this.state = {
            fetchingData: false,
            decryptedUrl: null,
            decryptedThumbnailUrl: null,
            decryptedBlob: null,
            error: null,
            posterLoading: false,
            blurhashUrl: null,
            // CTalk added
            layout: SettingsStore.getValue("layout"),
            autoPlayVideo: SettingsStore.getValue("autoplayVideo") as boolean,
            showButtonPlaceholder: localStorage.getItem("mx_ShowVideo_" + this.props.mxEvent.getId()) !== "true",
        };
    }

    private getContentUrl(): string | undefined {
        const content = this.props.mxEvent.getContent<IMediaEventContent>();
        // During export, the content url will point to the MSC, which will later point to a local url
        if (this.props.forExport) return content.file?.url ?? content.url;
        const media = mediaFromContent(content);
        if (media.isEncrypted) {
            return this.state.decryptedUrl ?? undefined;
        } else {
            return media.srcHttp ?? undefined;
        }
    }

    private hasContentUrl(): boolean {
        const url = this.getContentUrl();
        return !!url && !url.startsWith("data:");
    }

    public getThumbUrl(): string | null {
        // there's no need of thumbnail when the content is local
        if (this.props.forExport) return null;

        const content = this.props.mxEvent.getContent<IMediaEventContent>();
        const media = mediaFromContent(content);

        if (media.isEncrypted && this.state.decryptedThumbnailUrl) {
            return this.state.decryptedThumbnailUrl;
        } else if (this.state.posterLoading) {
            return this.state.blurhashUrl;
        }
        // CTalk comment for bug show thumb video Can't download yet
        // } else if (media.hasThumbnail) {
        //     return media.thumbnailHttp;
        // }
        else {
            return null;
        }
    }

    private loadBlurhash(): void {
        try {
            const info = this.props.mxEvent.getContent()?.info;
            if (!info[BLURHASH_FIELD]) return;

            const canvas = document.createElement("canvas");

            // CTalk force setting size image Normal
            const { w: width, h: height } = suggestedVideoSize(ImageSize.Normal, {
                w: info.w,
                h: info.h,
            });

            canvas.width = width;
            canvas.height = height;

            const pixels = decode(info[BLURHASH_FIELD], width, height);
            const ctx = canvas.getContext("2d")!;
            const imgData = ctx.createImageData(width, height);
            imgData.data.set(pixels);
            ctx.putImageData(imgData, 0, 0);

            this.setState({
                blurhashUrl: canvas.toDataURL(),
                posterLoading: true,
            });

            const content = this.props.mxEvent.getContent<IMediaEventContent>();
            const media = mediaFromContent(content);
            if (media.hasThumbnail) {
                const image = new Image();
                image.onload = (): void => {
                    this.setState({ posterLoading: false });
                };
                image.src = media.thumbnailHttp!;
            }
        } catch (e) {
            logger.error("Failed to load blurhash", e);
        }
    }

    public async componentDidMount(): Promise<void> {
        this.sizeWatcher = SettingsStore.watchSetting("Images.size", null, () => {
            this.forceUpdate(); // we don't really have a reliable thing to update, so just update the whole thing
        });

        // If the video is deleted, stop calling the API to retrieve the file
        this.checkVideoDeleted();

        const showVideoPlayholder = !this.state.autoPlayVideo
            && localStorage.getItem("mx_ShowVideo_" + this.props.mxEvent.getId()) !== "true";

        if (showVideoPlayholder) {
            this.loadBlurhash();
        } else {
            this.downloadVideo();
        }
    }
    private checkVideoDeleted(): void {
        const videoDeleted =
            localStorage.getItem("mx_ShowVideo_" + this.props.mxEvent.getId()) === FILE_DELETED;
        if (videoDeleted) {
            this.setState({
                error: FILE_DELETED,
            });
        }
    }
    private checkMediaExist = async (): Promise<void> => {
        try {
            this.setState({
                showButtonPlaceholder: false,
            });
            localStorage.setItem("mx_ShowVideo_" + this.props.mxEvent.getId(), "true");

            // Start: Get Decrypted URL
            // This step also verifies if the media exists. If a 404 error code is returned, it throws an exception.
            // This indicates that the media has likely been deleted.
            const decryptedUrl = await this.props.mediaEventHelper!.sourceUrl.value;
            // End: Get Decrypted URL
            this.setState({
                decryptedUrl: decryptedUrl,
            });

            this.downloadVideo();
        } catch (err) {
            this.setState({
                error: err,
            });
        }
    }

    private downloadVideo = async (): Promise<void> => {
        if (this.props.mediaEventHelper?.media.isEncrypted && this.state.decryptedUrl === null) {
            try {
                const thumbnailUrl = await this.props.mediaEventHelper.thumbnailUrl.value;
                if (this.state.autoPlayVideo) {
                    logger.log("Preloading video");
                    this.setState({
                        decryptedUrl: await this.props.mediaEventHelper!.sourceUrl.value,
                        decryptedThumbnailUrl: thumbnailUrl,
                        decryptedBlob: await this.props.mediaEventHelper.sourceBlob.value,
                        showButtonPlaceholder: false,
                    });
                    this.props.onHeightChanged?.();
                } else {
                    logger.log("NOT preloading video");
                    const content = this.props.mxEvent.getContent<IMediaEventContent>();

                    let mimetype = content?.info?.mimetype;

                    // clobber quicktime muxed files to be considered MP4 so browsers
                    // are willing to play them
                    if (mimetype == "video/quicktime") {
                        mimetype = "video/mp4";
                    }

                    this.setState({
                        // For Chrome and Electron, we need to set some non-empty `src` to
                        // enable the play button. Firefox does not seem to care either
                        // way, so it's fine to do for all browsers.
                        decryptedUrl: `data:${mimetype},`,
                        decryptedThumbnailUrl: thumbnailUrl || `data:${mimetype},`,
                        decryptedBlob: null,
                        showButtonPlaceholder: false,
                    });
                }
            } catch (err) {
                logger.warn("Unable to decrypt attachment: ", err);
                // Set a placeholder image when we can't decrypt the image.
                this.setState({
                    error: err,
                });
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.sizeWatcher) SettingsStore.unwatchSetting(this.sizeWatcher);
    }

    private videoOnPlay = async (): Promise<void> => {
        if (this.hasContentUrl() || this.state.fetchingData || this.state.error) {
            // We have the file, we are fetching the file, or there is an error.
            return;
        }
        this.setState({
            // To stop subsequent download attempts
            fetchingData: true,
        });
        if (!this.props.mediaEventHelper!.media.isEncrypted) {
            this.setState({
                error: "No file given in content",
            });
            return;
        }

        try {
            this.setState(
                {
                    decryptedUrl: await this.props.mediaEventHelper!.sourceUrl.value,
                    decryptedBlob: await this.props.mediaEventHelper!.sourceBlob.value,
                    fetchingData: false,
                },
                () => {
                    if (!this.videoRef.current) return;
                    this.videoRef.current.play();
                },
            );
        } catch (err) {
            this.setState({
                error: err
            });
        }
        this.props.onHeightChanged?.();
    };

    protected get showFileBody(): boolean {
        return (
            this.context.timelineRenderingType !== TimelineRenderingType.Room &&
            this.context.timelineRenderingType !== TimelineRenderingType.Pinned &&
            this.context.timelineRenderingType !== TimelineRenderingType.Search
        );
    }

    private getFileBody = (): ReactNode => {
        if (this.props.forExport) return null;
        return this.showFileBody && <MFileBody {...this.props} showGenericPlaceholder={false} />;
    };

    public render(): React.ReactNode {
        const content = this.props.mxEvent.getContent();
        const autoplay = !this.props.inhibitInteraction && SettingsStore.getValue("autoplayVideo");

        const layout = SettingsStore.getValue("layout");
        const isForwardedEvent = !!getForwardedEventInfo(this.props.mxEvent.getContent());

        // CTal added
        // CTalk force setting size image Normal
        // const size = SettingsStore.getValue("Images.size") as ImageSize;
        const size = ImageSize.Normal;
        const maxSize = size === ImageSize.Large;

        let aspectRatio;
        if (content.info?.w && content.info?.h) {
            aspectRatio = `${content.info.w}/${content.info.h}`;
        }
        // CTalk force setting size image Normal
        const { w: maxWidth, h: maxHeight } = suggestedVideoSize(ImageSize.Normal, {
            w: content.info?.w,
            h: content.info?.h,
        });

        // CTalk added
        const styleMVideo = { maxWidth, maxHeight, aspectRatio } as any;
        if (maxWidth < 320 && content['description']) {
            styleMVideo['maxWidth'] = WIDTH_SIZE_VIDEO_NARROW;
            styleMVideo['minWidth'] = WIDTH_SIZE_VIDEO_NARROW;
        }
        if (isForwardedEvent) {
            styleMVideo['height'] = '100%';
            styleMVideo['minWidth'] = WIDTH_SIZE_VIDEO_NARROW;
        }

        // HACK: This div fills out space while the video loads, to prevent scroll jumps
        const spaceFiller = <div style={{ width: maxWidth, height: maxHeight }} />;

        let fileError;
        if (this.state.error !== null) {
            // CTalk added
            let errorMessages;
            let isDeleted = false;
            if (downloadError(this.state.error) || this.state.error === FILE_DELETED) {
                errorMessages = _t("ctalk|error|media_deleted" as TranslationKey);
                localStorage.setItem("mx_ShowVideo_" + this.props.mxEvent.getId(), FILE_DELETED);
                isDeleted = true;
            } else {
                errorMessages = _t("timeline|m.video|error_decrypting");
            }
            fileError = (
                <MediaProcessingError
                    className="mx_MVideoBody ctalk_Body_error"
                    isDeleted={isDeleted}
                >
                    {errorMessages}
                </MediaProcessingError>
            );
        }

        // Important: If we aren't autoplaying and we haven't decrypted it yet, show a video with a poster.
        if (!this.props.forExport && content.file !== undefined && this.state.decryptedUrl === null && autoplay) {
            // Need to decrypt the attachment
            // The attachment is decrypted in componentDidMount.
            // For now show a spinner.
            return (
                <span className="mx_MVideoBody">
                    <div className="mx_MVideoBody_container" style={styleMVideo}>
                        <InlineSpinner />
                    </div>
                    {spaceFiller}
                </span>
            );
        }

        const contentUrl = this.getContentUrl();
        const thumbUrl = this.getThumbUrl();
        let poster: string | undefined;
        let preload = "metadata";
        if (content.info && thumbUrl) {
            poster = thumbUrl;
            preload = "none";
        }

        const fileBody = this.getFileBody();
        // CTalk added
        const className = classNames('mx_MVideoBody_container', {
            ctalk_MVideoBody_container: !!content['description'],
            ctalk_MVideoBody_no_description: !content['description'],
            ctalk_MVideoBody_Placeholder: this.state.showButtonPlaceholder,
            ctalk_MVideoBody_Placeholder_Safari: this.state.showButtonPlaceholder && isSafari(),
            // ctalk_MVideoBody_forward: isForwardedEvent,
        });

        return (
            <div className="ctalk_MVideoBody"> {/* CTalk added */}
                <span className="mx_MVideoBody">
                    {
                        fileError
                        ? <p className="ctalk_MVideoBody_error">{fileError}</p>
                        : <div className={className} style={styleMVideo}>
                            {
                                this.state.showButtonPlaceholder
                                    ?   <div className="ctalk_MVideoBody_Placeholder_button" onClick={this.checkMediaExist}>
                                        <div className="ctalk_MVideoBody_Placeholder_button_download" />
                                    </div>
                                    : <></>
                            }
                            {
                                this.state.showButtonPlaceholder && isSafari()
                                    ? <img src={poster} alt={content.body} />
                                    : <video
                                        className="mx_MVideoBody"
                                        ref={this.videoRef}
                                        src={contentUrl}
                                        title={content.body}
                                        controls={!this.props.inhibitInteraction}
                                        // Disable downloading as it doesn't work with e2ee video,
                                        // users should use the dedicated Download button in the Message Action Bar
                                        controlsList="nodownload"
                                        preload={preload}
                                        muted={autoplay}
                                        autoPlay={autoplay}
                                        poster={this.state.showButtonPlaceholder ? poster : this.state.decryptedThumbnailUrl}
                                        onPlay={this.videoOnPlay}

                                    />
                            }
                            {spaceFiller}
                        </div>
                    }
                    {fileBody}
                    { /* CTalk added */
                        content['description']
                        && <p
                            className="ctalk_MVideoBody_description"
                            style={{
                                maxWidth: layout === Layout.Bubble ? styleMVideo['maxWidth'] - 24 : 'unset',
                                width: maxSize ? 'unset' : '100%',
                            }}
                        >
                            { content['description'] }
                        </p>
                    }
                </span>
            </div>
        );
    }
}
