import EventEmitter from "events";
import { ComponentClass } from "matrix-react-sdk/src/@types/common";
import { IToast } from "matrix-react-sdk/src/stores/ToastStore";

declare global {
    interface Window {
        mxGlobalToastStore: GlobalToastStore;
    }
}
/**
 * Holds the active toasts
 */
export default class GlobalToastStore extends EventEmitter {
    private toasts: IToast<any>[] = [];
    // The count of toasts which have been seen & dealt with in this stack
    // where the count resets when the stack of toasts clears.
    private countSeen = 0;

    public static instance(): GlobalToastStore {
        if (!window.mxGlobalToastStore) window.mxGlobalToastStore = new GlobalToastStore();
        return window.mxGlobalToastStore;
    }

    public reset(): void {
        this.toasts = [];
        this.countSeen = 0;
    }

    /**
     * Add or replace a toast
     * If a toast with the same toastKey already exists, the given toast will replace it
     * Toasts are always added underneath any toasts of the same priority, so existing
     * toasts stay at the top unless a higher priority one arrives (better to not change the
     * toast unless necessary).
     *
     * @param {object} newToast The new toast
     */
    public addOrReplaceToast<C extends ComponentClass>(newToast: IToast<C>): void {
        const oldIndex = this.toasts.findIndex(t => t.key === newToast.key);
        if (oldIndex === -1) {
            let newIndex = this.toasts.length;
            while (newIndex > 0 && this.toasts[newIndex - 1].priority < newToast.priority) --newIndex;
            this.toasts.splice(newIndex, 0, newToast);
        } else {
            this.toasts[oldIndex] = newToast;
        }
        this.emit('update');
    }

    public dismissToast(key): void {
        if (this.toasts[0] && this.toasts[0].key === key) {
            this.countSeen++;
        }

        const length = this.toasts.length;
        this.toasts = this.toasts.filter(t => t.key !== key);
        if (length !== this.toasts.length) {
            if (this.toasts.length === 0) {
                this.countSeen = 0;
            }

            this.emit('update');
        }
    }
    public getToasts(): IToast<any>[] {
        return this.toasts;
    }

    public getCountSeen(): number {
        return this.countSeen;
    }
}
