import { CTalkClient } from './CTalkClient';

export interface ICTalkClientPeg {
    get(): CTalkClient;
    createClient(): void;
}

export function createCTalkClient(): CTalkClient {
    // TODO check indexDB/localStorage/MemoryStore for client store
    return new CTalkClient();
}

class CTalkClientPegClass implements ICTalkClientPeg {
    private ctalkClient: CTalkClient = null;

    public get(): CTalkClient {
        if (!this.ctalkClient) {
            this.createClient();
        }
        return this.ctalkClient;
    }

    public createClient(): CTalkClient {
        this.ctalkClient = createCTalkClient();
    }
}

export const CTalkClientPeg: ICTalkClientPeg = new CTalkClientPegClass();

if (!window.ckCTalkClientPeg) {
    window.ckCTalkClientPeg = CTalkClientPeg;
}
