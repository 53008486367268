import PlatformPeg from "matrix-react-sdk/src/PlatformPeg";

import ElectronPlatform, { platformFriendlyName } from "../../vector/platform/ElectronPlatform";
import { EPlatform } from "../enums/user-settings.enum";
import WebPlatform from "../../vector/platform/WebPlatform";

export function getPlatform(): EPlatform {
    const platform = PlatformPeg.get();
    if (platform instanceof WebPlatform) {
        return EPlatform.Web;
    }
    if (platform instanceof ElectronPlatform) {
        const os = platformFriendlyName();
        if (os === 'macOS') {
            return EPlatform.MacOS;
        }
        if (os === 'Windows') {
            return EPlatform.Windows;
        }
    }
}
