import React, { createRef } from "react";
import FocusLock from "react-focus-lock";
import { _t } from "matrix-react-sdk/src/languageHandler";
import AccessibleTooltipButton from "matrix-react-sdk/src/components/views/elements/AccessibleTooltipButton";
import { KeyBindingAction } from "matrix-react-sdk/src/accessibility/KeyboardShortcuts";
import { getKeyBindingsManager } from "matrix-react-sdk/src/KeyBindingsManager";

interface IProps {
    src: string; // the source of the video being displayed
    name?: string; // the main title ('name') for the video
    description?: string;
    isNotAllowDownload?: boolean;
    isPreviewFileModal?: boolean;
    onFinished(): void;
}

export default class VideoView extends React.Component<IProps, {}> {
    private videoRef = React.createRef<HTMLVideoElement>();

    public constructor(props: IProps) {
        super(props);
    }

    private focusLock = createRef<any>();
    private videoWrapper = createRef<HTMLDivElement>();

    private onKeyDown = (ev: KeyboardEvent): void => {
        const action = getKeyBindingsManager().getAccessibilityAction(ev);
        switch (action) {
            case KeyBindingAction.Escape:
                ev.stopPropagation();
                ev.preventDefault();
                this.props.onFinished();
                break;
        }
    };

    private onDownloadClick = (): void => {
        const a = document.createElement("a");
        a.href = this.props.src;
        if (this.props.name) a.download = this.props.name;
        a.target = "_blank";
        a.rel = "noreferrer noopener";
        a.click();
    };

    public render(): React.ReactNode {

        return (
            <FocusLock
                returnFocus={true}
                lockProps={{
                    "onKeyDown": this.onKeyDown,
                    "role": "dialog",
                    "aria-label": _t("lightbox|title"),
                }}
                className="mx_ImageView"
                ref={this.focusLock}
            >
                <div className="mx_ImageView_panel">
                    <div className="mx_ImageView_toolbar">
                        {
                            !this.props.isNotAllowDownload
                            && <AccessibleTooltipButton
                                className="mx_ImageView_button mx_ImageView_button_download"
                                title={_t("action|download")}
                                onClick={this.onDownloadClick}
                            />
                        }
                        <AccessibleTooltipButton
                            className="mx_ImageView_button mx_ImageView_button_close"
                            title={_t("action|close")}
                            onClick={this.props.onFinished}
                        />
                    </div>
                </div>
                <div
                    className="mx_ImageView_image_wrapper"
                    ref={this.videoWrapper}
                    onMouseDown={this.props.onFinished}
                >
                    <video
                        className="mx_MVideoBody"
                        ref={this.videoRef}
                        src={this.props.src}
                        title={this.props.name}
                        controls={true}
                        controlsList="nodownload"
                        preload={"none"}
                        muted={true}
                        autoPlay={true}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        // poster={poster}
                        // onPlay={this.videoOnPlay}
                    />
                </div>
                {
                    this.props.description
                    && <div className="ctalk_ImageView_description">
                        { this.props.description }
                    </div>
                }
            </FocusLock>
        );
    }
}
