import { Room } from "matrix-js-sdk/src/models/room";
import { RoomMember } from "matrix-js-sdk/src/models/room-member";
import { EventType, MatrixClient, MatrixEvent } from "matrix-js-sdk/src/matrix";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import { DefaultTagID } from "matrix-react-sdk/src/stores/room-list/models";

import { isBotUser } from "../utils/helper";
import { ERoomRole } from "../enums/room-role.enum";
import LegacyCallHandler from "matrix-react-sdk/src/LegacyCallHandler";
import { CallState } from "matrix-js-sdk/src/webrtc/call";

const SORT_REGEX = /[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+/g;
const sortNames = new Map<RoomMember, string>(); // RoomMember -> sortName

export function isDirectMessage(roomId: string): boolean {
    return !!DMRoomMap.shared().getUserIdForRoomId(roomId);
}

export function getMemberLeaveRoomInDirectMessage(room: Room): RoomMember {
    const isDm = isDirectMessage(room.roomId);
    if (!room || !isDm) return null;
    const cli = MatrixClientPeg.get();
    const allMembers = Object.values(room.currentState.members);

    allMembers.forEach((member) => {
        // work around a race where you might have a room member object
        // before the user object exists. This may or may not cause
        // https://github.com/vector-im/vector-web/issues/186
        if (!member.user) {
            member.user = cli?.getUser(member.userId);
        }

        sortNames.set(
            member,
            (member.name[0] === '@' ? member.name.slice(1) : member.name).replace(SORT_REGEX, ""),
        );

        // XXX: this user may have no lastPresenceTs value!
        // the right solution here is to fix the race rather than leave it as 0
    });

    const membersLeave = allMembers.filter(member => member.membership === 'leave');
    return membersLeave[0];
}

export function getBotInDirectMessage(room: Room): RoomMember | undefined {
    const isDm = isDirectMessage(room.roomId);
    if (!room || !isDm) return null;
    const allMembers = Object.values(room.currentState.members);
    return allMembers.find(member => isBotUser(member.userId));
}

export function setLatestPinnedStateEvents(cli: MatrixClient, room: Room): void {
    if (!room) {
        return;
    }
    cli.getStateEvent(room.roomId, EventType.RoomPinnedEvents, "").then((res) => {
        room.currentState.setStateEvents([new MatrixEvent({
            type: EventType.RoomPinnedEvents,
            state_key: "",
            content: { ['pinned']: res.pinned },
            event_id: "$fake" + Date.now(),
            room_id: room.roomId,
            user_id: cli.getUserId(),
        })]);
    });
}

export function isMemberInRoom(cli: MatrixClient, roomId: string, userId: string): boolean {
    const room = cli.getRoom(roomId);
    if (!room) {
        return false;
    }
    const roomMembers = room.getJoinedMembers()?.map((member) => {
        return member.userId;
    });
    return roomMembers?.includes(userId);
}

export async function forceLeaveRoomDeleted(cli: MatrixClient, roomId: string) {
    const room = cli?.getRoom(roomId);
    // Handle leave room on local with deleted room
    room?.updateMyMembership("leave");
    // Remove room on local store
    await cli?.store.removeRoom(roomId);
    await cli?.store.save(true);
    // Handle clear data sync in indexDB (clear data room stuck)
    const db = await new Promise((resolve, reject) => {
        const dbReq = indexedDB.open('matrix-js-sdk:riot-web-sync');
        dbReq.onerror = reject;
        dbReq.onsuccess = () => resolve(dbReq.result);
    }) as any;
    const txn = db!.transaction(['sync'], 'readwrite') as IDBTransaction;
    txn!.objectStore('sync').clear();
}

export function forceHangUpOrRejectLegacyCall(room_id: string): void {
    const activeCall = LegacyCallHandler.instance.getCallForRoom(room_id);
    if (activeCall?.state === CallState.Ringing) {
        LegacyCallHandler.instance.hangupOrReject(room_id, true);
    }
    if (activeCall?.state === CallState.Connected) {
        LegacyCallHandler.instance.hangupOrReject(room_id, false);
    }
}

export function isActiveLegacyCall(room_id: string): boolean {
    const activeCall = LegacyCallHandler.instance.getCallForRoom(room_id);
    return activeCall?.state === CallState.Ringing || activeCall?.state === CallState.Connected;
}

export function isRoomAdmin(cli: MatrixClient, room: Room): boolean {
    const me = room?.getMember(cli.getUserId());
    if (!me) return false;
    return me.powerLevel === ERoomRole.ADMIN;
}

export function isUserRoleInRoom(role: ERoomRole, member?: RoomMember | null): boolean {
    if (!member) return false;
    return member.powerLevel === role;
}

export function isServerNoticeRoom(roomId: string, cli?: MatrixClient): boolean {
    if (!cli) {
        cli = MatrixClientPeg.get();
    }
    return !!cli.getRoom(roomId)?.tags[DefaultTagID.ServerNotice];
}

export function isMemberJoinedAndInvited(cli: MatrixClient, roomId: string): boolean {
    const room = cli.getRoom(roomId);
    if (!room) {
        return false;
    }
    const selfMembership = room.getMyMembership();
    const statuses = ['invite', 'join'];
    return statuses.includes(selfMembership);
}
