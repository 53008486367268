import React from "react";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import { _t } from "matrix-react-sdk/src/languageHandler";

interface IProps {
    onFinished: (success: boolean) => void;
    title?: string;
    description?: React.ReactNode;
    button?: string;
    focus?: boolean;
    headerImage?: string;
}

interface IState {
    onFinished: (success: boolean) => void;
}

export default class CTalkErrorDialog extends React.Component<IProps, IState> {
    public static defaultProps = {
        focus: true,
        title: null,
        description: null,
        button: null,
    };

    private onClick = (): void => {
        this.props.onFinished(true);
    };

    public render(): React.ReactNode {
        return (
            <BaseDialog
                className="mx_ErrorDialog ctalk_errorDialog"
                onFinished={this.props.onFinished}
                title={this.props.title || _t('common|error')}
                headerImage={this.props.headerImage}
                contentId='mx_Dialog_content'
            >
                <div className="mx_Dialog_content" id='mx_Dialog_content'>
                    { this.props.description || _t('error|dialog_description_default') }
                </div>
                <div className="mx_Dialog_buttons">
                    <button className="mx_Dialog_primary" onClick={this.onClick} autoFocus={this.props.focus}>
                        { this.props.button || _t('action|ok') }
                    </button>
                </div>
            </BaseDialog>
        );
    }
}
