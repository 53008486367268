import React from "react";
import CBaseComponent, { IBaseFormState } from "@ctalk/components/views/messages/form_components/CBaseForm";
import { ICTextareaProps } from "@ctalk/models/bot-form.interface";
import { EFormType } from "@ctalk/enums/bot-form.enum";

import {_t} from "../../../../../languageHandler";

interface IState extends IBaseFormState {
    defaultValue: string;
}

export default class CTextarea extends CBaseComponent<ICTextareaProps, IState> {
    public constructor(props: ICTextareaProps) {
        super(props);
        this.state = {
            ...this.state,
            defaultValue: '',
        };
    }

    public componentDidMount(): void {
        const defaultValue = this.props.responseValue?.value ?? '';
        this.updateStateAndValidate({
            defaultValue,
            value: defaultValue,
        });
    }

    private onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.props.onValueChange({
            name: this.props.name,
            type: EFormType.Textarea,
            value: event.target.value,
        });
        this.updateStateAndValidate({
            value: event.target.value,
        });
    };

    protected renderInput(): React.ReactNode {
        return (
            <>
                <textarea
                    className="ctalk_textarea_item"
                    id={this.props.name}
                    placeholder={_t("ctalk|bot_form|edit_textarea_placeholder")}
                    name={this.props.name}
                    maxLength={this.props.limit}
                    rows={5}
                    cols={40}
                    onChange={this.onChange}
                    defaultValue={this.state.defaultValue}
                />
                <div className="ctalk_input_count">
                    {this.state.value.length}/{this.props.limit}
                </div>
            </>
        );
    }
}
