import { MatrixEvent } from 'matrix-js-sdk/src/matrix';
import { getSenderName } from 'matrix-react-sdk/src/utils/event/getSenderName';
import { _t, TranslationKey } from 'matrix-react-sdk/src/languageHandler';
import { formatDuration, formatPreciseDuration } from 'matrix-react-sdk/src/DateUtils';
import SdkConfig from 'matrix-react-sdk/src/SdkConfig';
export const DAY_MS = 86400000;
export const WEEK_MS = 604800000;
export const MONTH_MS = 2592000000;

const ONE_DAY = "1";
const THREE_DAYS = "3";
const ONE_WEEK = "7";
const TWO_WEEKS = "14";
const ONE_MONTH = "30";
const SIX_MONTHS = "180";
const ONE_YEAR = "365";

export interface AutoDeleteOptions {
    value: string;
    label: string | null;
    options?: { [key: string]: string };
}
export function getDefaultOptions(): AutoDeleteOptions[] {
    return [
        { value: dayToMs(ONE_DAY), label: "ctalk|settings|security|auto_delete_messages|one_day" },
        { value: dayToMs(THREE_DAYS), label: "ctalk|settings|security|auto_delete_messages|days_plural", options: {days: "3"} },
        { value: dayToMs(ONE_WEEK), label: "ctalk|settings|security|auto_delete_messages|one_week" },
        { value: dayToMs(TWO_WEEKS), label: "ctalk|settings|security|auto_delete_messages|weeks_plural", options: {weeks: "2"} },
        { value: dayToMs(ONE_MONTH), label: "ctalk|settings|security|auto_delete_messages|one_month" },
        { value: dayToMs(SIX_MONTHS), label: "ctalk|settings|security|auto_delete_messages|months_plural", options: {months: "6"} },
        { value: dayToMs(ONE_YEAR), label: "ctalk|settings|security|auto_delete_messages|one_year" },
    ];
}

export function msToTimeString(ms: number): string | undefined {
    const autoDeleteOption = getDefaultOptions().find(option => parseInt(option.value) === ms);
    if (autoDeleteOption) {
        return _t(autoDeleteOption.label as TranslationKey, autoDeleteOption.options ?? undefined);
    }
    if (ms > DAY_MS) {
        return _t("ctalk|time|custom_day",  { value: Math.round(ms/DAY_MS) });
    }
    if (ms < DAY_MS) {
        return formatPreciseDuration(ms);
    }
}

export function msToShortTimeStr(ms: number): string {
    const yearMS = parseInt(dayToMs(ONE_YEAR));
    const weeksMS = [parseInt(dayToMs(ONE_WEEK)), parseInt(dayToMs(TWO_WEEKS))];
    if (ms >= yearMS) {
        return _t("ctalk|time|short_years",  { value: Math.round(ms/yearMS) });
    }
    if (ms >= MONTH_MS) {
        return _t("ctalk|time|short_months",  { value: Math.round(ms/MONTH_MS) });
    }
    if (weeksMS.includes(ms)) {
        return _t("ctalk|time|short_weeks",  { value: Math.round(ms/WEEK_MS) });
    }
    return formatDuration(ms);
}

export function textForRoomRetention(mxEvent: MatrixEvent): (() => string) | null {
    return () => {
        const maxLifetime = mxEvent.event.content?.max_lifetime;
        let senderName;
        if (!mxEvent.event.content?.hide_sender) {
            senderName = getSenderName(mxEvent);
        }
        if (+maxLifetime > 0) {
            return (
                mxEvent.event.content?.hide_sender ?
                    _t("ctalk|settings|security|auto_delete_messages|setting_change_hide_sender", { maxLifetime: msToTimeString(maxLifetime) }) :
                    _t("ctalk|settings|security|auto_delete_messages|setting_change", {
                        senderName,
                        maxLifetime: msToTimeString(maxLifetime),
                    })
                );
        }
        return (
            mxEvent.event.content?.hide_sender ?
                _t("ctalk|settings|security|auto_delete_messages|disable_setting_hide_sender") :
                _t("ctalk|settings|security|auto_delete_messages|disable_setting", {
                    senderName,
                })
            );
    }
}

export function textForAutoDelete(mxEvent: MatrixEvent): (() => string) | null {
    const showAutoDeleteEvent = SdkConfig.get<any>('setting_defaults')['Retention.showAutoDeleteEvent'];
    if (!showAutoDeleteEvent) {
        return null;
    }
    const content = mxEvent?.getContent();
    const timeParse = new Date(content?.expiredTs).toLocaleString()
    return () => `Auto delete: ${timeParse}`;
}

export function msToDay(ms: string): string {
    return Math.round(Number(ms)/DAY_MS).toString();
}

export function dayToMs(days: string): string {
    return Math.round(Number(days)*DAY_MS).toString();
}
