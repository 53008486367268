import React from "react";
import { IContent } from 'matrix-js-sdk/src/models/event';
import { EventType } from "matrix-js-sdk/src/@types/event";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { logger } from "matrix-js-sdk/src/logger";
import { _t } from "matrix-react-sdk/src/languageHandler";
import StyledCheckbox from "matrix-react-sdk/src/components/views/elements/StyledCheckbox";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import Modal from "matrix-react-sdk/src/Modal";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import {
    Box, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    ThemeProvider,
} from "@mui/material";
import { areObjectsEqual } from "@ctalk/helpers/ObjectHelper";
import { ERoomRole } from "@ctalk/enums/room-role.enum";

import muiTheme from "../../../../customisations/helpers/MuiThemeCustom";
import {
    IPowerLevelDescriptor,
    parseIntWithDefault,
    plEventsToShow,
} from "../../../../components/views/settings/tabs/room/RolesRoomSettingsTab";
import PowerSelector from "../../../../components/views/elements/PowerSelector";

const EVENTS_LEVEL_PREFIX = "event_levels_";
const NOTIFICATION_LEVEL_PREFIX = "notifications_levels_";
const NOTIFICATION_PREFIX = "notifications";

const TABLE_KEY_MAPPING = [
    "events_default",
    EVENTS_LEVEL_PREFIX + "m.reaction",
    EVENTS_LEVEL_PREFIX + "m.room.pinned_events",
    EVENTS_LEVEL_PREFIX + "m.room.redaction",
    "redact",
    "invite",
    "ban",
    "kick",
    EVENTS_LEVEL_PREFIX + "im.vector.modular.widgets",
    NOTIFICATION_LEVEL_PREFIX + "room",
    "view_members",
    "view_system_messages",
    EVENTS_LEVEL_PREFIX + "m.room.name",
    EVENTS_LEVEL_PREFIX +"m.room.avatar",
    EVENTS_LEVEL_PREFIX + "m.room.topic",
    EVENTS_LEVEL_PREFIX + "m.room.canonical_alias",
    "state_default",
    EVENTS_LEVEL_PREFIX + "m.room.history_visibility",
    "config_whitelisted_ips",
    EVENTS_LEVEL_PREFIX + "m.room.tombstone",
];

const DROPDOWN_KEY_MAPPING = [
    EVENTS_LEVEL_PREFIX + "m.room.power_levels",
];

interface IDynamicProperties {
    [key: string]: any;
}

interface IPermissionContainer {
    dropdownPermissions: IPermission[];
    tablePermissions: IPermission[];
}

interface IPermission {
    key: string;
    label: string;
    powerLevel: number;
}

interface IRowSelectorProps {
    permission: IPermission;
    disabled: boolean;
    currentUserLevel: number;
    onChangePowerLevel(permission: IPermission): void;
}

export class RowSelector extends React.Component<IRowSelectorProps, any> {
    private handleChangePowerLevel = (powerLevel: ERoomRole): void => {
        const { permission } = this.props;
        this.props.onChangePowerLevel({
            ...permission,
            powerLevel,
        });
    };

    private onOwnerChecked = (): void => {
        this.handleChangePowerLevel(ERoomRole.OWNER);
    };

    private onAdminChecked = (): void => {
        if (this.props.permission.powerLevel <= ERoomRole.ADMIN) {
            this.handleChangePowerLevel(ERoomRole.OWNER);
            return;
        }
        this.handleChangePowerLevel(ERoomRole.ADMIN);
    };

    private onMemberChecked = (): void => {
        if (this.props.permission.powerLevel === 0) {
            this.handleChangePowerLevel(ERoomRole.ADMIN);
            return;
        }
        this.handleChangePowerLevel(ERoomRole.DEFAULT);
    };

    public render(): React.ReactNode {
        const { powerLevel } = this.props.permission;
        return (
            <>
                <TableCell
                    component="th"
                    scope="row"
                    sx={{
                        fontSize: "0.8em",
                    }}
                >
                    { this.props.permission.label }
                </TableCell>
                <TableCell>
                    <StyledCheckbox
                        disabled={this.props.disabled || this.props.currentUserLevel <= ERoomRole.OWNER}
                        checked={powerLevel <= ERoomRole.OWNER}
                        onChange={this.onOwnerChecked}
                    />
                </TableCell>
                <TableCell align="center">
                    <StyledCheckbox
                        disabled={this.props.disabled || this.props.currentUserLevel <= ERoomRole.ADMIN}
                        checked={powerLevel <= ERoomRole.ADMIN}
                        onChange={this.onAdminChecked}
                    />
                </TableCell>
                <TableCell align="center">
                    <StyledCheckbox
                        disabled={this.props.disabled || this.props.currentUserLevel <= ERoomRole.DEFAULT}
                        checked={powerLevel === ERoomRole.DEFAULT}
                        onChange={this.onMemberChecked}
                    />
                </TableCell>
            </>
        );
    }
}

interface IDropdownSelectorProps {
    permission: IPermission;
    usersDefault: number;
    currentUserLevel: number;
    title: string;
    description: string;
    onUserPowerLevelChanged(permission: IPermission): void;
}
export class DropdownSelector extends React.Component<IDropdownSelectorProps, any> {
    private onUserPowerLevelChanged = (value: number): void => {
        this.props.onUserPowerLevelChanged({
            ...this.props.permission,
            powerLevel: value,
        });
    };

    private getDisabledDropdown = (): boolean => {
        return this.props.currentUserLevel !== ERoomRole.OWNER
            && this.props.permission.powerLevel >= this.props.currentUserLevel;
    };

    public render(): React.ReactNode {
        return (
            <Box>
                { this.props.title }
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box
                        width="70%"
                    >
                        { this.props.description }
                    </Box>
                    <Box
                        width="30%"
                    >
                        <PowerSelector
                            label=''
                            disabled={this.getDisabledDropdown()}
                            value={this.props.permission.powerLevel}
                            usersDefault={this.props.usersDefault}
                            maxValue={ERoomRole.OWNER}
                            key={this.props.permission.key}
                            onChange={this.onUserPowerLevelChanged}
                            currentUserLevel={this.props.currentUserLevel ?? ERoomRole.DEFAULT}
                            needToShowOwner={true}
                        />
                    </Box>

                </Box>
            </Box>
        );
    }
}

interface IProps {
    roomId: string;
    powerLevelDescriptors: Record<string, IPowerLevelDescriptor>;
    eventsLevels: IDynamicProperties;
    plEventsToLabels: IDynamicProperties;
    usersDefault: number;
    isSpaceRoom: boolean;
    plContent: IContent;
    canChangeLevels: boolean;
    currentUserLevel: number;
}

interface IState {
    tablePermissions: IPermission[];
    powerLevelContent: IContent;
    powerLevelContentOld: IContent;
    dropdownPermissions: IPermission[];
    loading: boolean;
}

export default class PermissionSelector extends React.Component<IProps, IState> {
    private client: MatrixClient;
    public constructor(props: IProps) {
        super(props);
        this.state = {
            tablePermissions: [],
            dropdownPermissions: [],
            powerLevelContent: {},
            powerLevelContentOld: {},
            loading: false,
        };
    }

    protected componentDidMount(): void {
        this.client = MatrixClientPeg.get();
        this.handleGetDataPermission();
    }

    protected componentDidUpdate(prevProps: IProps): void {
        if (!areObjectsEqual(this.props.plContent, prevProps.plContent)) {
            this.handleGetDataPermission();
        }
    }

    private handleGetDataPermission(): void {
        const room = this.client.getRoom(this.props.roomId);
        const powerLevelEvent = room?.currentState.getStateEvents(EventType.RoomPowerLevels, '');
        const powerLevelContent = powerLevelEvent ? (powerLevelEvent.getContent() || {}) : {};
        const permissionContainer = this.getPermissions();
        this.setState({
            tablePermissions: permissionContainer.tablePermissions,
            dropdownPermissions: permissionContainer.dropdownPermissions,
            powerLevelContent,
            powerLevelContentOld: powerLevelContent,
        });
    }

    private powerDescToPermissions(powerLevelDescriptors: Record<string, IPowerLevelDescriptor>): IPermission[] {
        const { plContent, isSpaceRoom } = this.props;
        return Object.keys(powerLevelDescriptors).map((key) => {
            const descriptor = powerLevelDescriptors[key];
            if (isSpaceRoom && descriptor.hideForSpace) {
                return null;
            }
            const keyPath = key.split('.');
            let currentObj = plContent;
            for (const prop of keyPath) {
                if (currentObj === undefined) {
                    break;
                }
                currentObj = currentObj[prop];
            }

            const value = parseIntWithDefault(currentObj, descriptor.defaultValue);
            // Check and convert object with key start with notifications
            if (key.startsWith(NOTIFICATION_PREFIX)) {
                key = NOTIFICATION_LEVEL_PREFIX + keyPath[keyPath.length - 1];
            }
            return {
                key,
                label: descriptor.desc,
                powerLevel: value,
            };
        }).filter(Boolean);
    }

    private eventsLevelsToPermissions(eventsLevels: IDynamicProperties): IPermission[] {
        const { isSpaceRoom, plEventsToLabels } = this.props;
        return Object.keys(eventsLevels).map((key, _) => {
            if (isSpaceRoom && plEventsToShow[key]?.hideForSpace) {
                return null;
            } else if (!isSpaceRoom && plEventsToShow[key]?.hideForRoom) {
                return null;
            }

            let label = plEventsToLabels[key];
            if (label) {
                label = _t(label);
            } else {
                label = _t("Send %(eventType)s events", { key });
            }
            return {
                key: EVENTS_LEVEL_PREFIX + key,
                label,
                powerLevel: eventsLevels[key],
            };
        }).filter(Boolean);
    }

    private sortAndFilterPermissions(permissions: IPermission[], sortOrder: string[]): IPermission[] {
        return sortOrder
            .map((item) =>
                permissions.find((per) => per.key === item) ||
                // add key if new permission don't have in db
                {
                    key: item,
                    powerLevel: ERoomRole.OWNER,
                    // ADD CTALK_ to translate, can be change prefix and toUpperCase for translate after upgrade
                    label: _t("CTALK_"+ item.toUpperCase()),
                },
            )
            .filter(Boolean);
    }

    private getPermissions(): IPermissionContainer {
        const { powerLevelDescriptors, eventsLevels } = this.props;
        const powerDescToPermissions = this.powerDescToPermissions(powerLevelDescriptors);
        const eventsLevelsToPermissions = this.eventsLevelsToPermissions(eventsLevels);
        const permission = [...powerDescToPermissions, ...eventsLevelsToPermissions];
        const dropdownPermissions = this.sortAndFilterPermissions(eventsLevelsToPermissions, DROPDOWN_KEY_MAPPING);
        const tablePermissions = this.sortAndFilterPermissions(permission, TABLE_KEY_MAPPING);
        return {
            dropdownPermissions,
            tablePermissions,
        };
    }

    private handleChangePowerLevel(permission: IPermission): void {
        // Clone the power levels just in case
        const powerLevelContent = Object.assign({}, this.state.powerLevelContent);
        const { key, powerLevel } = permission;

        if (key.startsWith(EVENTS_LEVEL_PREFIX)) {
            // deep copy "events" object, Object.assign itself won't deep copy
            powerLevelContent["events"] = Object.assign({}, powerLevelContent["events"] || {});
            powerLevelContent["events"][key.slice(EVENTS_LEVEL_PREFIX.length)] = powerLevel;
        } else if (key.startsWith(NOTIFICATION_LEVEL_PREFIX)) {
            powerLevelContent[NOTIFICATION_PREFIX] = Object.assign({}, powerLevelContent[NOTIFICATION_PREFIX] || {});
            powerLevelContent[NOTIFICATION_PREFIX][key.slice(NOTIFICATION_LEVEL_PREFIX.length)] = powerLevel;
        }else {
            const keyPath = key.split('.');
            let parentObj: IContent = {};
            let currentObj = powerLevelContent;
            for (const key of keyPath) {
                if (!currentObj[key]) {
                    currentObj[key] = {};
                }
                parentObj = currentObj;
                currentObj = currentObj[key];
            }
            parentObj[keyPath[keyPath.length - 1]] = powerLevel;
        }
        this.setState({
            powerLevelContent,
        });
    }

    private onDropdownChangePowerLevel = (permission: IPermission): IPermission | void => {
        this.handleChangePowerLevel(permission);
        const dropdownPermissions = this.state.dropdownPermissions;
        dropdownPermissions.map(per => {
            if (per.key === permission.key) {
                per.powerLevel = permission.powerLevel;
            }
            return permission;
        });
        this.setState({
            dropdownPermissions,
        });
    };

    private onTableChangePowerLevel = (permission: IPermission): IPermission | void => {
        this.handleChangePowerLevel(permission);
        const tablePermissions = this.state.tablePermissions;
        tablePermissions.map(per => {
            if (per.key === permission.key) {
                per.powerLevel = permission.powerLevel;
            }
            return permission;
        });
        this.setState({
            tablePermissions,
        });
    };

    private onSavePermissions = (): void => {
        this.setState({
            loading: true,
        });
        this.client.sendStateEvent(this.props.roomId, EventType.RoomPowerLevels, this.state.powerLevelContent)
            .catch(e => {
                logger.error(e);
                Modal.createDialog(ErrorDialog, {
                    title: _t("room_settings|permission|error_changing_pl_reqs_title"),
                    description: _t("room_settings|permission|error_changing_pl_reqs_description"),
                });
            })
            .finally(() => {
                this.setState(prevState => ({
                    loading: false,
                    powerLevelContentOld: prevState.powerLevelContent,
                }));
            });
    };

    public render(): React.ReactNode {
        return (
            <ThemeProvider theme={muiTheme}>
                <Box
                    pt={1}
                    ml={1}
                >
                    {
                        this.state.dropdownPermissions.map((permission) => (
                            this.props.canChangeLevels
                            && this.props.currentUserLevel >= permission.powerLevel
                            && <DropdownSelector
                                key={permission.key}
                                permission={permission}
                                usersDefault={this.props.usersDefault}
                                currentUserLevel={this.props.currentUserLevel}
                                title={_t(`CTALK_PERMISSION_TITLE_${permission.key}`)}
                                description={_t(`CTALK_PERMISSION_DESCRIPTION_${permission.key}`)}
                                onUserPowerLevelChanged={this.onDropdownChangePowerLevel}
                            />
                        ))
                    }
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: "1em",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        { _t("CTALK_PERMISSION") }
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: "1em",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        { _t("CTALK_OWNER") }
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: "1em",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        { _t("power_level|admin") }
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: "1em",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        { _t("CTALK_MEMBER") }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.tablePermissions.map((permission) => (
                                        <TableRow
                                            key={permission.key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <RowSelector
                                                permission={permission}
                                                currentUserLevel={this.props.currentUserLevel}
                                                onChangePowerLevel={this.onTableChangePowerLevel}
                                                disabled={
                                                    !this.props.canChangeLevels
                                                    || this.props.currentUserLevel < permission.powerLevel
                                                }
                                            />
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        pt={1}
                    >
                        <AccessibleButton
                            kind='primary'
                            disabled={
                                this.state.loading
                                || areObjectsEqual(this.state.powerLevelContent, this.state.powerLevelContentOld)
                            }
                            onClick={this.onSavePermissions}
                        >
                            { _t("action|save") }
                        </AccessibleButton>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
