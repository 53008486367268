export enum EUsersSettings {
    ALLOW_PLATFORM = 'allow_platform',
}

export enum EPlatform {
    Windows = 'windows',
    MacOS = 'macos',
    Web = 'web',
    iOS = 'ios',
    iPadOS = 'ipados',
}
