import React from "react";
import { IBaseFormBodyProps } from "@ctalk/models/bot-form.interface";
import classNames from "classnames";

export interface IBaseFormState {
    value: any;
}

abstract class CBaseComponent<T extends IBaseFormBodyProps, S extends IBaseFormState> extends React.Component<T, S> {
    protected constructor(props: T) {
        super(props);

        this.state = {
            value: '',
        } as S;
    }

    protected updateStateAndValidate(newState: Partial<S>): void {
        this.setState({
            ...this.state,
            ...newState,
        },() => {
            this.validate();
        });
    }

    public render(): React.ReactNode {
        const formClassName = classNames(`ctalk_Form ctalk_Form_${this.props.type}`);
        const labelClassName = classNames("ctalk_Form_label",{
            required: this.props.required,
        });

        return (
            <div className={formClassName}>
                <div className={labelClassName}>
                    {this.props.label}
                </div>
                {this.renderInput()}
            </div>
        );
    }

    protected validate(): void {
        const value: any = this.state.value;
        if (!this.props.required) {
            this.props.onValidationField({
                name: this.props.name,
                isValid: true,
            });
            return;
        }

        let isValid;
        if (typeof value === "string" || Array.isArray(value)) {
            isValid = !!value.length;
        } else {
            isValid = false;
        }
        this.props.onValidationField({
            name: this.props.name,
            isValid,
        });
    }

    protected renderInput(): React.ReactNode {
        throw new Error("Method 'renderInput' must be implemented.");
    }
}

export default CBaseComponent;
