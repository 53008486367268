/*
Copyright 2015 - 2021 The Matrix.org Foundation C.I.C.
Copyright 2018, 2019 Michael Telatynski <7t3chguy@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { ComponentProps, createRef, ReactNode } from "react";
import { Blurhash } from "react-blurhash";
import classNames from "classnames";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { logger } from "matrix-js-sdk/src/logger";
import { ClientEvent, ClientEventHandlerMap } from "matrix-js-sdk/src/matrix";
import MFileBody from "matrix-react-sdk/src/components/views/messages/MFileBody";
import Modal from "matrix-react-sdk/src/Modal";
import { _t } from "matrix-react-sdk/src/languageHandler";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import Spinner from "matrix-react-sdk/src/components/views/elements/Spinner";
import { Media, mediaFromContent } from "matrix-react-sdk/src/customisations/Media";
import { BLURHASH_FIELD, createThumbnail } from "matrix-react-sdk/src/utils/image-media";
import { ImageContent } from "matrix-react-sdk/src/customisations/models/IMediaEventContent";
import ImageView from "matrix-react-sdk/src/components/views/elements/ImageView";
// import { IBodyProps } from "matrix-react-sdk/src/components/views/messages/IBodyProps";
import { ImageSize, suggestedSize as suggestedImageSize } from "matrix-react-sdk/src/settings/enums/ImageSize";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import RoomContext, { TimelineRenderingType } from "matrix-react-sdk/src/contexts/RoomContext";
import { blobIsAnimated, mayBeAnimated } from "matrix-react-sdk/src/utils/Image";
// import { presentableTextForFile } from "matrix-react-sdk/src/utils/FileUtils";
import { createReconnectedListener } from "matrix-react-sdk/src/utils/connection";
// import MediaProcessingError from "matrix-react-sdk/src/components/views/messages/shared/MediaProcessingError";
import { DecryptError, DownloadError } from "matrix-react-sdk/src/utils/DecryptFile";
// CTalk imported
import { EventType } from "matrix-js-sdk/src/@types/event";
import { Layout } from "matrix-react-sdk/src/settings/enums/Layout";
import { getForwardedEventInfo } from "@ctalk/utils/helper";
import { downloadError, IErrorDownloaded } from "@ctalk/utils/message-error";

import { IBodyProps } from "./IBodyProps";
import MediaProcessingError from "./shared/MediaProcessingError";

enum Placeholder {
    NoImage,
    Blurhash,
}

interface IState {
    contentUrl: string | null;
    thumbUrl: string | null;
    isAnimated?: boolean;
    error?: unknown;
    imgError: boolean;
    imgLoaded: boolean;
    loadedImageDimensions?: {
        naturalWidth: number;
        naturalHeight: number;
    };
    hover: boolean;
    showImage: boolean;
    placeholder: Placeholder;
    // CTalk added
    animationJsonData?: any;
    isStoppedAnimation: boolean;
    theme: string;
}

// CTalk added
const MAX_IMAGE_SIZE = 320;
const THUMBNAIL_REPLY_SIZE = 44;
const REPLY_WIDTH_SIZE = 130;
const REPLY_HEIGHT_SIZE = 70;

export default class MImageBody extends React.Component<IBodyProps, IState> {
    public static contextType = RoomContext;
    public context!: React.ContextType<typeof RoomContext>;

    protected unmounted = true;
    private image = createRef<HTMLImageElement>();
    private timeout?: number;
    private sizeWatcher?: string;
    private reconnectedListener: ClientEventHandlerMap[ClientEvent.Sync];
    // CTalk added
    private themeWatcher: string;

    public constructor(props: IBodyProps) {
        super(props);

        this.reconnectedListener = createReconnectedListener(this.clearError);

        this.state = {
            contentUrl: null,
            thumbUrl: null,
            imgError: false,
            imgLoaded: false,
            hover: false,
            showImage: SettingsStore.getValue("showImages"),
            placeholder: Placeholder.NoImage,
            // CTalk added
            isStoppedAnimation: true,
            theme: SettingsStore.getValue("theme"),
        };
    }

    protected showImage(): void {
        localStorage.setItem("mx_ShowImage_" + this.props.mxEvent.getId(), "true");
        this.setState({ showImage: true });
        this.downloadImage();
    }

    protected onClick = (ev: React.MouseEvent): void => {
        if (ev.button === 0 && !ev.metaKey) {
            ev.preventDefault();
            if (!this.state.showImage) {
                this.showImage();
                return;
            }

            const content = this.props.mxEvent.getContent<ImageContent>();
            const httpUrl = this.state.contentUrl;
            if (!httpUrl) return;
            const params: Omit<ComponentProps<typeof ImageView>, "onFinished"> = {
                src: httpUrl,
                name: content.body && content.body.length > 0 ? content.body : _t("common|attachment"),
                mxEvent: this.props.mxEvent,
                permalinkCreator: this.props.permalinkCreator,
                // CTalk added
                description: content['description'],
            };

            if (content.info) {
                params.width = content.info.w;
                params.height = content.info.h;
                params.fileSize = content.info.size;
            }

            if (this.image.current) {
                const clientRect = this.image.current.getBoundingClientRect();

                params.thumbnailInfo = {
                    width: clientRect.width,
                    height: clientRect.height,
                    positionX: clientRect.x,
                    positionY: clientRect.y,
                };
            }

            Modal.createDialog(ImageView, params, "mx_Dialog_lightbox", undefined, true);
        }
    };

    protected onImageEnter = (e: React.MouseEvent<HTMLImageElement>): void => {
        this.setState({ hover: true });

        if (
            !this.state.contentUrl ||
            !this.state.showImage ||
            !this.state.isAnimated ||
            SettingsStore.getValue("autoplayGifs")
        ) {
            return;
        }
        const imgElement = e.currentTarget;
        imgElement.src = this.state.contentUrl;
    };

    protected onImageLeave = (e: React.MouseEvent<HTMLImageElement>): void => {
        this.setState({ hover: false });

        const url = this.state.thumbUrl ?? this.state.contentUrl;
        if (!url || !this.state.showImage || !this.state.isAnimated || SettingsStore.getValue("autoplayGifs")) {
            return;
        }
        const imgElement = e.currentTarget;
        imgElement.src = url;
    };

    private clearError = (): void => {
        MatrixClientPeg.get()?.off(ClientEvent.Sync, this.reconnectedListener);
        this.setState({ imgError: false });
    };

    /**
     * CTalk added
     * @private
     */
    private isSticker(): boolean {
        return this.props.mxEvent.getType() === EventType.Sticker;
    }

    private onImageError = (): void => {
        // If the thumbnail failed to load then try again using the contentUrl
        if (this.state.thumbUrl) {
            this.setState({
                thumbUrl: null,
            });
            return;
        }

        this.clearBlurhashTimeout();
        this.setState({
            imgError: true,
        });
        MatrixClientPeg.safeGet().on(ClientEvent.Sync, this.reconnectedListener);
    };

    private onImageLoad = (): void => {
        this.clearBlurhashTimeout();
        this.props.onHeightChanged?.();

        let loadedImageDimensions: IState["loadedImageDimensions"];

        if (this.image.current) {
            const { naturalWidth, naturalHeight } = this.image.current;
            // this is only used as a fallback in case content.info.w/h is missing
            loadedImageDimensions = { naturalWidth, naturalHeight };
        }
        this.setState({ imgLoaded: true, loadedImageDimensions });
    };

    protected getContentUrl(): string | null { // CTalk export for child
        // During export, the content url will point to the MSC, which will later point to a local url
        if (this.props.forExport) return this.media.srcMxc;
        return this.media.srcHttp;
    }

    private get media(): Media {
        return mediaFromContent(this.props.mxEvent.getContent());
    }

    protected getThumbUrl(): string | null { // CTalk export for child
        // FIXME: we let images grow as wide as you like, rather than capped to 800x600.
        // So either we need to support custom timeline widths here, or reimpose the cap, otherwise the
        // thumbnail resolution will be unnecessarily reduced.
        // custom timeline widths seems preferable.
        const thumbWidth = 800;
        const thumbHeight = 600;

        const content = this.props.mxEvent.getContent<ImageContent>();
        const media = mediaFromContent(content);
        const info = content.info;

        if (info?.mimetype === "image/svg+xml" && media.hasThumbnail) {
            // Special-case to return clientside sender-generated thumbnails for SVGs, if any,
            // given we deliberately don't thumbnail them serverside to prevent billion lol attacks and similar.
            return media.getThumbnailHttp(thumbWidth, thumbHeight, "scale");
        }

        // we try to download the correct resolution for hi-res images (like retina screenshots).
        // Synapse only supports 800x600 thumbnails for now though,
        // so we'll need to download the original image for this to work  well for now.
        // First, let's try a few cases that let us avoid downloading the original, including:
        //   - When displaying a GIF, we always want to thumbnail so that we can
        //     properly respect the user's GIF autoplay setting (which relies on
        //     thumbnailing to produce the static preview image)
        //   - On a low DPI device, always thumbnail to save bandwidth
        //   - If there's no sizing info in the event, default to thumbnail
        if (this.state.isAnimated || window.devicePixelRatio === 1.0 || !info || !info.w || !info.h || !info.size) {
            return media.getThumbnailOfSourceHttp(thumbWidth, thumbHeight);
        }

        // We should only request thumbnails if the image is bigger than 800x600 (or 1600x1200 on retina) otherwise
        // the image in the timeline will just end up resampled and de-retina'd for no good reason.
        // Ideally the server would pre-gen 1600x1200 thumbnails in order to provide retina thumbnails,
        // but we don't do this currently in synapse for fear of disk space.
        // As a compromise, let's switch to non-retina thumbnails only if the original image is both
        // physically too large and going to be massive to load in the timeline (e.g. >1MB).

        const isLargerThanThumbnail = info.w > thumbWidth || info.h > thumbHeight;
        const isLargeFileSize = info.size > 1024 * 1024; // 1mb

        if (isLargeFileSize && isLargerThanThumbnail) {
            // image is too large physically and byte-wise to clutter our timeline so,
            // we ask for a thumbnail, despite knowing that it will be max 800x600
            // despite us being retina (as synapse doesn't do 1600x1200 thumbs yet).
            return media.getThumbnailOfSourceHttp(thumbWidth, thumbHeight);
        }

        // download the original image otherwise, so we can scale it client side to take pixelRatio into account.
        return media.srcHttp;
    }

    protected async downloadImage(): Promise<void> { // CTalk export for child
        if (this.state.contentUrl) return; // already downloaded

        let thumbUrl: string | null;
        let contentUrl: string | null;
        if (this.props.mediaEventHelper?.media.isEncrypted) {
            try {
                [contentUrl, thumbUrl] = await Promise.all([
                    this.props.mediaEventHelper.sourceUrl.value,
                    this.props.mediaEventHelper.thumbnailUrl.value,
                ]);
            } catch (error) {
                if (this.unmounted) return;

                if (error instanceof DecryptError) {
                    logger.error("Unable to decrypt attachment: ", error);
                } else if (error instanceof DownloadError) {
                    logger.error("Unable to download attachment to decrypt it: ", error);
                } else {
                    logger.error("Error encountered when downloading encrypted attachment: ", error);
                }

                // Set a placeholder image when we can't decrypt the image.
                this.setState({ error });
                return;
            }
        } else {
            thumbUrl = this.getThumbUrl();
            contentUrl = this.getContentUrl();
        }

        const content = this.props.mxEvent.getContent<ImageContent>();
        let isAnimated = mayBeAnimated(content.info?.mimetype);

        // If there is no included non-animated thumbnail then we will generate our own, we can't depend on the server
        // because 1. encryption and 2. we can't ask the server specifically for a non-animated thumbnail.
        if (isAnimated && !SettingsStore.getValue("autoplayGifs")) {
            if (!thumbUrl || !content?.info?.thumbnail_info || mayBeAnimated(content.info.thumbnail_info.mimetype)) {
                const img = document.createElement("img");
                const loadPromise = new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
                img.crossOrigin = "Anonymous"; // CORS allow canvas access
                img.src = contentUrl ?? "";

                try {
                    await loadPromise;
                } catch (error) {
                    logger.error("Unable to download attachment: ", error);
                    this.setState({ error: error as Error });
                    return;
                }

                try {
                    const blob = await this.props.mediaEventHelper!.sourceBlob.value;
                    if (!(await blobIsAnimated(content.info?.mimetype, blob))) {
                        isAnimated = false;
                    }
                    if (isAnimated) {
                        const thumb = await createThumbnail(
                            img,
                            img.width,
                            img.height,
                            content.info?.mimetype ?? "image/jpeg",
                            false,
                        );
                        thumbUrl = URL.createObjectURL(thumb.thumbnail);
                    }
                } catch (error) {
                    // This is a non-critical failure, do not surface the error or bail the method here
                    logger.warn("Unable to generate thumbnail for animated image: ", error);
                }
            }
        }

        if (this.unmounted) return;
        this.setState({
            contentUrl,
            thumbUrl,
            isAnimated,
        });
    }

    private clearBlurhashTimeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    public componentDidMount(): void {
        this.unmounted = false;

        const showImage =
            this.state.showImage
            || localStorage.getItem("mx_ShowImage_" + this.props.mxEvent.getId()) === "true"
            || this.isSticker(); // CTalk added
        if (showImage) {
            // noinspection JSIgnoredPromiseFromCall
            this.downloadImage();
            this.setState({ showImage: true });
        } // else don't download anything because we don't want to display anything.

        // Add a 150ms timer for blurhash to first appear.
        if (this.props.mxEvent.getContent().info?.[BLURHASH_FIELD]) {
            this.clearBlurhashTimeout();
            this.timeout = window.setTimeout(() => {
                if (!this.state.imgLoaded || !this.state.imgError) {
                    this.setState({
                        placeholder: Placeholder.Blurhash,
                    });
                }
            }, 150);
        }

        this.sizeWatcher = SettingsStore.watchSetting("Images.size", null, () => {
            this.forceUpdate(); // we don't really have a reliable thing to update, so just update the whole thing
        });
        // CTalk added
        this.themeWatcher = SettingsStore.watchSetting('theme', null, (...[,,, value]) => {
            this.setState({
                theme: value,
            });
        });
    }

    public componentWillUnmount(): void {
        this.unmounted = true;
        MatrixClientPeg.get()?.off(ClientEvent.Sync, this.reconnectedListener);
        this.clearBlurhashTimeout();
        if (this.sizeWatcher) SettingsStore.unwatchSetting(this.sizeWatcher);
        if (this.themeWatcher) SettingsStore.unwatchSetting(this.themeWatcher); // CTalk added
        if (this.state.isAnimated && this.state.thumbUrl) {
            URL.revokeObjectURL(this.state.thumbUrl);
        }
    }

    /* CTalk hide this
    protected getBanner(content: ImageContent): ReactNode {
        // Hide it for the threads list & the file panel where we show it as text anyway.
        if (
            [TimelineRenderingType.ThreadsList, TimelineRenderingType.File].includes(this.context.timelineRenderingType)
        ) {
            return null;
        }

        return (
            <span className="mx_MImageBody_banner">
                {presentableTextForFile(content, _t("common|image"), true, true)}
            </span>
        );
    }
    */

    protected messageContent(
        contentUrl: string | null,
        thumbUrl: string | null,
        content: ImageContent,
        forcedHeight?: number,
        // CTalk added
        showDescription = true,
        type: EventType | string = '',
        inReplyChain?: boolean,
    ): ReactNode {
        if (!thumbUrl) thumbUrl = contentUrl; // fallback

        // magic number
        // edge case for this not to be set by conditions below
        let infoWidth = 500;
        let infoHeight = 500;
        let infoSvg = false;

        // CTalk added
        const layout = SettingsStore.getValue("layout");
        const isForwardedEvent = !!getForwardedEventInfo(this.props.mxEvent.getContent());

        if (content.info?.w && content.info?.h) {
            infoWidth = content.info.w;
            infoHeight = content.info.h;
            infoSvg = content.info.mimetype === "image/svg+xml";
        } else if (thumbUrl && contentUrl) {
            // Whilst the image loads, display nothing. We also don't display a blurhash image
            // because we don't really know what size of image we'll end up with.
            //
            // Once loaded, use the loaded image dimensions stored in `loadedImageDimensions`.
            //
            // By doing this, the image "pops" into the timeline, but is still restricted
            // by the same width and height logic below.
            if (!this.state.loadedImageDimensions) {
                let imageElement: JSX.Element;
                if (!this.state.showImage) {
                    imageElement = <HiddenImagePlaceholder
                        maxWidth={infoWidth}
                        height={infoHeight}
                        showImage={this.state.showImage}
                        theme={this.state.theme}
                        blurhash={this.props.mxEvent.getContent().info?.[BLURHASH_FIELD]}
                    />;
                } else {
                    imageElement = (
                        <img
                            style={{ display: "none" }}
                            src={thumbUrl}
                            ref={this.image}
                            alt={content.body}
                            onError={this.onImageError}
                            onLoad={this.onImageLoad}
                        />
                    );
                }
                return this.wrapImage(contentUrl, imageElement);
            }
            infoWidth = this.state.loadedImageDimensions.naturalWidth;
            infoHeight = this.state.loadedImageDimensions.naturalHeight;
        }

        // The maximum size of the thumbnail as it is rendered as an <img>,
        // accounting for any height constraints
        /* CTalk force setting size image Normal */
        // const size = SettingsStore.getValue("Images.size") as ImageSize; // CTalk added
        const size = ImageSize.Normal;
        let { w: maxWidth, h: maxHeight } = suggestedImageSize(
            // SettingsStore.getValue("Images.size") as ImageSize, // CTalk removed
            size,// CTalk added
            { w: infoWidth, h: infoHeight },
            forcedHeight ?? this.props.maxImageHeight,
        );

        // CTalk added
        if (inReplyChain) {
            maxWidth = forcedHeight!;
        }
        // const maxSize = size === ImageSize.Large;
        const maxSize = false;

        let img: JSX.Element | undefined;
        let placeholder: JSX.Element | undefined;
        let gifLabel: JSX.Element | undefined;

        if (!this.props.forExport && !this.state.imgLoaded) {
            const classes = classNames("mx_MImageBody_placeholder", {
                "mx_MImageBody_placeholder--blurhash": this.props.mxEvent.getContent().info?.[BLURHASH_FIELD],
            });

            placeholder = <div className={classes}>{this.getPlaceholder(maxWidth, maxHeight)}</div>;
        }

        let showPlaceholder = Boolean(placeholder);

        /* SET <img /> */
        if (thumbUrl && !this.state.imgError) {
            /* CTalk hide this
            // Restrict the width of the thumbnail here, otherwise it will fill the container
            // which has the same width as the timeline
            // mx_MImageBody_thumbnail resizes img to exactly container size
            img = (
                <img
                    className="mx_MImageBody_thumbnail"
                    src={thumbUrl}
                    ref={this.image}
                    alt={content.body}
                    onError={this.onImageError}
                    onLoad={this.onImageLoad}
                    onMouseEnter={this.onImageEnter}
                    onMouseLeave={this.onImageLeave}
                />
            );
            */

            // CTalk added
            const { isRightPanelPinnedMessage, isFilePanel } = this.props;
            const isRightPanel = isRightPanelPinnedMessage || isFilePanel;
            const isReply = !!this.props.mxEvent.getWireContent()?.["m.relates_to"]?.["m.in_reply_to"];

            if (
                type === EventType.Sticker ||
                !content['description'] && !isForwardedEvent && !isReply ||
                // (!content['description'] && size === ImageSize.Large && maxWidth > MAX_IMAGE_SIZE) ||
                !content['description'] && isForwardedEvent && inReplyChain
            ) {
                // Displays images in normal format
                img = (
                    <img
                        className="mx_MImageBody_thumbnail"
                        src={thumbUrl}
                        ref={this.image}
                        alt={content.body}
                        onError={this.onImageError}
                        onLoad={this.onImageLoad}
                        onMouseEnter={this.onImageEnter}
                        onMouseLeave={this.onImageLeave}
                    />
                );
            } else {
                // CTalk added
                const height = Math.max(maxHeight, THUMBNAIL_REPLY_SIZE);
                const isMiniHeightAndWidthIsBetween =
                    maxHeight < THUMBNAIL_REPLY_SIZE && // Height < 44px
                    maxWidth > THUMBNAIL_REPLY_SIZE && maxWidth < MAX_IMAGE_SIZE // 44px < width < 320px
                // Image forward and max size is greater than width
                const bgForwardAndWidth = isForwardedEvent && maxWidth < MAX_IMAGE_SIZE;
                const bgHeightBigger = height > MAX_IMAGE_SIZE && height >= maxWidth;

                const styleBackground = {
                    height: height as any,
                    width: content['description'] || bgHeightBigger || bgForwardAndWidth
                            ? MAX_IMAGE_SIZE : maxWidth as any,
                    maxHeight: height > MAX_IMAGE_SIZE ? MAX_IMAGE_SIZE : height as any,
                };

                const bnLargeAndWidth = maxSize && maxWidth === 800;
                const bnHeightSmallerAndCaption = maxWidth > height && maxWidth > MAX_IMAGE_SIZE && content['description'];
                const styleBanner = {
                    width: bnHeightSmallerAndCaption || bnLargeAndWidth
                        ? '100%' : 'unset',
                } as any;

                // Check is large mode
                if (maxSize) {
                    styleBackground.height = height >= maxWidth ? height : '100%';
                    styleBackground.width = height >= maxWidth && maxWidth < MAX_IMAGE_SIZE ? MAX_IMAGE_SIZE : '100%';
                    styleBackground.maxHeight = height >= MAX_IMAGE_SIZE ? MAX_IMAGE_SIZE : 'unset';
                }

                // Check is isReply and not contain description (VT-6478)
                if (isReply && !content['description']) {
                    styleBackground.height = 'unset';
                    styleBackground.maxHeight = 'unset';
                }

                if (isForwardedEvent) { // Customize width and height for Forward Event
                    styleBackground.height = maxWidth < MAX_IMAGE_SIZE && height <= maxWidth ? height : '100%';
                    styleBackground.width = maxWidth < MAX_IMAGE_SIZE ? MAX_IMAGE_SIZE : '100%';
                    // Check mini image with size < THUMBNAIL_REPLY_SIZE (50px)
                    if (height === THUMBNAIL_REPLY_SIZE && maxWidth < THUMBNAIL_REPLY_SIZE) {
                        styleBanner.height = height;
                    } else if (height === maxWidth) {
                        styleBanner.height = '100%';
                    } else if (!content['description'] && maxWidth > height) {
                        styleBanner.width = '100%';
                    }
                    // if isRightPanelPinnedMessage or isFilePanel overwrite css
                    if (isRightPanel && isMiniHeightAndWidthIsBetween && !content['description']) {
                        styleBanner.height = 'unset';
                        styleBanner.width = '100%';
                    } else if (isFilePanel && isMiniHeightAndWidthIsBetween && content['description']) {
                        // handle css for only file panel
                        styleBanner.width = 'auto';
                        styleBanner.height = height;
                    } else if(isRightPanel) {
                        styleBanner.height = '100%';
                        styleBanner.width = 'unset';
                    }

                } else {
                    if (isFilePanel) {
                        styleBanner.height = height;
                    }
                }

                if (inReplyChain) {
                    styleBanner['objectFit'] =
                        maxWidth < maxHeight && maxWidth > THUMBNAIL_REPLY_SIZE
                            ? 'cover' : 'contain';
                }
                const classNameBanner = classNames('mx_MImageBody_thumbnail ctalk_MImageBody_Banner', {
                    ctalk_MImageBody_Banner_Larger: maxWidth > MAX_IMAGE_SIZE || maxSize && maxWidth === 800,
                    ctalk_MImageBody_Banner_Reply: !!inReplyChain,
                    ctalk_MImageBody_Banner_Forward_Mini_Height:
                        isForwardedEvent &&
                        isMiniHeightAndWidthIsBetween,
                    ctalk_MImageBody_Banner_Forward_No_Des:
                        isForwardedEvent &&
                        !content['description'] &&
                        // is horizontal or square image with width < 320px
                        maxWidth < MAX_IMAGE_SIZE &&
                        maxHeight <= maxWidth,
                });
                const classNameBackground = classNames('mx_MImageBody_thumbnail ctalk_MImageBody_Background', {
                    ctalk_MImageBody_Background_Large: maxSize,
                });
                img = inReplyChain ? (
                    <img
                        className="mx_MImageBody_thumbnail"
                        src={thumbUrl}
                        ref={this.image}
                        alt={content.body}
                        onError={this.onImageError}
                        onLoad={this.onImageLoad}
                        onMouseEnter={this.onImageEnter}
                        onMouseLeave={this.onImageLeave}
                    />
                ) : (
                    <>
                        <div className="ctalk_MImageBody_Preview">
                            <img
                                className={classNameBanner}
                                style={styleBanner}
                                src={thumbUrl}
                                ref={this.image}
                                alt={content.body}
                                onError={this.onImageError}
                                onLoad={this.onImageLoad}
                                onMouseEnter={this.onImageEnter}
                                onMouseLeave={this.onImageLeave}
                            />
                            <img
                                draggable={false}
                                className={classNameBackground}
                                style={styleBackground}
                                src={thumbUrl}
                                ref={this.image}
                                alt={content.body}
                            />
                        </div>
                    </>
                );
            }
        }

        /* SET <img /> with setting placeholder */
        if (!this.state.showImage) {
            img = <HiddenImagePlaceholder
                // maxWidth={maxWidth} // CTalk removed
                maxWidth={Math.min(maxWidth, MAX_IMAGE_SIZE)} // CTalk edited
                // CTalk added
                height={maxHeight}
                showImage={this.state.showImage}
                theme={this.state.theme}
                blurhash={this.props.mxEvent.getContent().info?.[BLURHASH_FIELD]}
                inReplyChain={inReplyChain}
            />;
            showPlaceholder = false; // because we're hiding the image, so don't show the placeholder.
        }

        if (this.state.isAnimated && !SettingsStore.getValue("autoplayGifs") && !this.state.hover) {
            // XXX: Arguably we may want a different label when the animated image is WEBP and not GIF
            gifLabel = <p className="mx_MImageBody_gifLabel">GIF</p>;
        }

        // CTalk hide this
        // let banner: ReactNode | undefined;
        // if (this.state.showImage && this.state.hover) {
        //     banner = this.getBanner(content);
        // }

        // many SVGs don't have an intrinsic size if used in <img> elements.
        // due to this we have to set our desired width directly.
        // this way if the image is forced to shrink, the height adapts appropriately.
        // const sizing = infoSvg ? { maxHeight, maxWidth, width: maxWidth } : { maxHeight, maxWidth }; // CTalk removed

        // CTalk added
        const { isRightPanelPinnedMessage, isFilePanel } = this.props;
        const isRightPanel = isRightPanelPinnedMessage || isFilePanel;
        const sizing = infoSvg ?
            { maxHeight, maxWidth, width: maxWidth }
            : { maxWidth: maxSize && maxWidth > MAX_IMAGE_SIZE ? maxWidth : MAX_IMAGE_SIZE } as any;

        if (isRightPanel || isFilePanel) {
            sizing.maxHeight = 'unset';
        }

        if (!this.props.forExport) {
            placeholder = (
                <SwitchTransition mode="out-in">
                    <CSSTransition classNames="mx_rtg--fade" key={`img-${showPlaceholder}`} timeout={300}>
                        {showPlaceholder ? placeholder : <></> /* Transition always expects a child */}
                    </CSSTransition>
                </SwitchTransition>
            );
        }

        let fileError;
        if (this.state.error) {
            let errorText = _t("timeline|m.image|error");
            // CTalk added
            if (downloadError(this.state.error as IErrorDownloaded)) {
                errorText = _t("ctalk|error|media_deleted");
            } else if (this.state.error instanceof DecryptError) {
                errorText = _t("timeline|m.image|error_decrypting");
            } else if (this.state.error instanceof DownloadError) {
                errorText = _t("timeline|m.image|error_downloading");
            }

            // CTalk updated
            // return <MediaProcessingError className="mx_MImageBody">{errorText}</MediaProcessingError>;
            fileError = <MediaProcessingError
                className="mx_MImageBody ctalk_MImageBody_Error"
                isDeleted={true}
            >
                {errorText}
            </MediaProcessingError>;
        }

        // CTalk added
        let styles: any;

        /* RETURN <thumbnail /> with case: Sticker, NOT have description, in block reply */
        if (!fileError && (type === EventType.Sticker || !content['description'] || inReplyChain)) {
            styles = {
                height: maxHeight,
                width: maxWidth,
                aspectRatio: inReplyChain ? 1 : `${infoWidth}/${infoHeight}`, // Case reply set thumb to square
            };
            if (type !== EventType.Sticker) {
                if (!this.state.showImage) {
                    if (isForwardedEvent && !inReplyChain) {
                        styles['minWidth'] = isForwardedEvent ? MAX_IMAGE_SIZE : 'unset';
                    }
                    styles['maxWidth'] = inReplyChain ? forcedHeight : '100%';
                } else {
                    if (isForwardedEvent && !inReplyChain) {
                        styles = {
                            maxHeight,
                            maxWidth: maxWidth > MAX_IMAGE_SIZE || layout !== Layout.Bubble ? maxWidth : MAX_IMAGE_SIZE,
                            aspectRatio: maxWidth > MAX_IMAGE_SIZE || layout !== Layout.Bubble ?
                                `${infoWidth}/${infoHeight}` : 'unset',
                        };
                        if (isRightPanel) {
                            styles.maxHeight = 'unset';
                        }
                    }
                }
            } else { // Case sticker
                styles.height = forcedHeight;
                styles.width = forcedHeight;
            }

            // CTalk added
            const className = classNames('mx_MImageBody_thumbnail_container', {
                ctalk_MImageBody_thumbnail_container_Showing_Image:
                    this.state.showImage,
                ctalk_MImageBody_thumbnail_container:
                    !this.state.showImage
                    && (maxWidth < THUMBNAIL_REPLY_SIZE || maxWidth > maxHeight),
                ctalk_HiddenImagePlaceholder: !this.state.showImage && inReplyChain,
                ctalk_MImageBody_thumbnail_Reply:
                    this.state.showImage
                    && inReplyChain
                    && !isForwardedEvent
                    && maxWidth > 100
                    && maxWidth > maxHeight,
            });

            // CTalk added
            const thumbnail = (
                <div className={className} style={styles}>
                   {placeholder}
                    <div style={sizing}>
                       {img}
                       {gifLabel}
                    </div>

                    { /* HACK: This div fills out space while the image loads, to prevent scroll jumps */ }
                    { !this.props.forExport && !this.state.imgLoaded && (
                        <div style={{ height: maxHeight, width: maxWidth }} />
                    ) }

                    { this.state.hover && this.getTooltip() }
                </div>
            );
            return this.wrapImage(contentUrl, thumbnail);
        }

        /* RETURN <thumbnail /> with case: fileError, NOT Sticker, description, NOT in block reply */
        // CTalk added
        const classNameThumbnail = classNames('mx_MImageBody_thumbnail_container ctalk_MImageBody_thumbnail', {
            ctalk_HiddenImagePlaceholder: !this.state.showImage && inReplyChain,
            ctalk_MImageBody_Error: fileError,
        });
        const classNameImageBody = classNames('ctalk_MImageBody_image', {
            ctalk_MImageBody_image_ShowImage: this.state.showImage,
        });

        let thumbnail;
        let fileDescription;

        styles = infoSvg ? {
            maxHeight,
            maxWidth,
            aspectRatio: `${infoWidth}/${infoHeight}`,
        } : { maxWidth: maxSize && maxWidth > MAX_IMAGE_SIZE ? maxWidth : MAX_IMAGE_SIZE };

        if (layout !== Layout.Bubble) {
            styles['maxWidth'] = 'unset';
        }

        if(showDescription && content['description']) {
            fileDescription = <p className="ctalk_MImageBody_description">
                { content['description'] }
            </p>;
        }

        if(fileError) {
            thumbnail = (
                <div
                    className={classNameThumbnail}
                    style={styles}
                    data-layout={layout}
                >
                    <p className="ctalk_MImageBody_description">{fileError}</p>
                    {fileDescription}
                </div>
            );
        } else {
            thumbnail = (
                <div
                    // CTalk added
                    className={classNameThumbnail}
                    style={styles}
                    data-layout={layout}
                >
                    {placeholder}
                    <div
                        style={sizing}
                        // CTalk added
                        className={classNameImageBody}
                    >
                        <a href={contentUrl} target={this.props.forExport ? "_blank" : undefined} onClick={this.onClick}>
                            {img}
                            {gifLabel}
                            {/* banner */}
                        </a>
                    </div>

                    {/* HACK: This div fills out space while the image loads, to prevent scroll jumps */}
                    {!this.props.forExport && !this.state.imgLoaded && (
                        <div
                            style={{
                                height: maxHeight,
                                minHeight: !this.state.showImage && !inReplyChain ? REPLY_HEIGHT_SIZE : undefined, // CTalk added
                                width: !this.state.showImage && inReplyChain ? REPLY_WIDTH_SIZE /* CTalk added */ : maxWidth,
                            }}
                        />
                    )}
                    {fileDescription}
                    {this.state.hover && this.getTooltip()}
                </div>
            );
        }

        // return this.wrapImage(contentUrl, thumbnail); // CTalk removed

        // CTalk added
        return (
            <div>{thumbnail}</div>
        );
    }

    // Overridden by MStickerBody
    protected wrapImage(contentUrl: string | null | undefined, children: JSX.Element): ReactNode {
        if (contentUrl) {
            return (
                <a href={contentUrl} target={this.props.forExport ? "_blank" : undefined} onClick={this.onClick}>
                    {children}
                </a>
            );
        } else if (!this.state.showImage) {
            return (
                <div role="button" onClick={this.onClick}>
                    {children}
                </div>
            );
        }
        return children;
    }

    // Overridden by MStickerBody
    protected getPlaceholder(width: number, height: number): ReactNode {
        const blurhash = this.props.mxEvent.getContent().info?.[BLURHASH_FIELD];

        // CTalk added
        width = Math.max(width, MAX_IMAGE_SIZE);

        if (blurhash) {
            if (this.state.placeholder === Placeholder.NoImage) {
                return null;
            } else if (this.state.placeholder === Placeholder.Blurhash) {
                return <Blurhash className="mx_Blurhash" hash={blurhash} width={width} height={height} />;
            }
        }
        return <Spinner w={32} h={32} />;
    }

    // Overridden by MStickerBody
    protected getTooltip(): ReactNode {
        return null;
    }

    // Overridden by MStickerBody
    protected getFileBody(): ReactNode {
        if (this.props.forExport) return null;
        /*
         * In the room timeline or the thread context we don't need the download
         * link as the message action bar will fulfill that
         */
        const hasMessageActionBar =
            this.context.timelineRenderingType === TimelineRenderingType.Room ||
            this.context.timelineRenderingType === TimelineRenderingType.Pinned ||
            this.context.timelineRenderingType === TimelineRenderingType.Search ||
            this.context.timelineRenderingType === TimelineRenderingType.Thread ||
            this.context.timelineRenderingType === TimelineRenderingType.ThreadsList;
        if (!hasMessageActionBar) {
            return <MFileBody {...this.props} showGenericPlaceholder={false} />;
        }
    }

    public render(): React.ReactNode {
        const content = this.props.mxEvent.getContent<ImageContent>();

        let contentUrl = this.state.contentUrl;
        let thumbUrl: string | null;
        if (this.props.forExport) {
            contentUrl = this.props.mxEvent.getContent().url ?? this.props.mxEvent.getContent().file?.url;
            thumbUrl = contentUrl;
        } else if (this.state.isAnimated && SettingsStore.getValue("autoplayGifs")) {
            thumbUrl = contentUrl;
        } else {
            thumbUrl = this.state.thumbUrl ?? this.state.contentUrl;
        }

        const thumbnail = this.messageContent(contentUrl, thumbUrl, content);
        const fileBody = this.getFileBody();

        // CTalk added
        const layout = SettingsStore.getValue("layout");
        // CTalk force setting size image Normal
        // const size = SettingsStore.getValue("Images.size") as ImageSize;
        const size = ImageSize.Normal;
        const { w: maxWidth } = suggestedImageSize(
            size,
            { w: content.info.w, h: content.info.h },
        );
        const classNameImageBody = classNames('mx_MImageBody', {
            ctalk_MImageBody_Large: size === ImageSize.Large,
        });

        return (
            // <div className="mx_MImageBody" // Ctalk remove
            <div className={classNameImageBody} // Ctalk added
                style={{ // CTalk added
                    maxWidth: layout === Layout.Group
                        && !content['description']
                        ? maxWidth : undefined,
                }}
            >
               {thumbnail}
               {fileBody}
            </div>
        );
    }
}

interface PlaceholderIProps {
    hover?: boolean;
    maxWidth?: number;
    // CTalk added
    height?: number;
    showImage?: boolean;
    theme?: string;
    blurhash?: string;
    inReplyChain?: boolean;
}

export class HiddenImagePlaceholder extends React.PureComponent<PlaceholderIProps> {
    public render(): React.ReactNode {
        const maxWidth = this.props.maxWidth ? this.props.maxWidth + "px" : null;
        // CTalk removed
        // let className = "mx_HiddenImagePlaceholder";
        // if (this.props.hover) className += " mx_HiddenImagePlaceholder_hover";

        // CTalk added
        const className = classNames('mx_HiddenImagePlaceholder', {
            mx_HiddenImagePlaceholder_hover: this.props.hover,
            ctalk_HiddenImagePlaceholder: !this.props.showImage,
            ctalk_HiddenImagePlaceholder_Hover_Dark: this.props.theme === 'dark',
            ctalk_HiddenImagePlaceholder_Hover_Light: this.props.theme === 'light',
        });

        // CTalk added
        const styles = {
            maxWidth: `min(${MAX_IMAGE_SIZE}, ${maxWidth}px)`,
            height: this.props.height ?? undefined,
        };

        let blurhashElm;
        if (this.props.blurhash) {
            blurhashElm = <Blurhash
                className="ctalk_Blurhash_placeholder"
                hash={this.props.blurhash}
                width="100%"
                height="100%"
            />;
        }

        return (
            <div className={className} style={styles}>
                <div className="mx_HiddenImagePlaceholder_button">
                    {blurhashElm}
                    { !this.props.inReplyChain && <span className="ctalk_HiddenImagePlaceholder_download" /> }
                </div>
            </div>
        );
    }
}
