import { isDesktopMode } from "@ctalk/utils/helper";
import ToastStore from "matrix-react-sdk/src/stores/ToastStore";
import { FileDownloader } from "matrix-react-sdk/src/utils/FileDownloader";
import GenericExpiringToast from "matrix-react-sdk/src/components/views/toasts/GenericExpiringToast";

import { _t } from "../../languageHandler";
import ElectronPlatform from "../../vector/platform/ElectronPlatform";

enum SaveFilePickerStatus {
    SUCCESS,
    UNSUPPORTED,
    CANCELLED,
}

interface SaveFilePickerResult {
    status: SaveFilePickerStatus;
    handle?: FileSystemFileHandle;
}

export class CTalkFileDownloader {
    private readonly isElectron: boolean;
    private downloader: FileDownloader;

    public constructor(downloader?: FileDownloader) {
        this.isElectron = isDesktopMode();
        this.downloader = downloader ?? new FileDownloader();
    }

    public async saveFileWeb(handle: FileSystemFileHandle, blob: Blob): Promise<void> {
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
    }

    public async showSaveFilePicker(suggestedName: string): Promise<SaveFilePickerResult> {
        if (window.showSaveFilePicker) {
            try {
                const handle = await window.showSaveFilePicker({
                    suggestedName,
                });
                return { status: SaveFilePickerStatus.SUCCESS, handle };
            } catch (err: any) {
                if (err.name === 'AbortError') {
                    return { status: SaveFilePickerStatus.CANCELLED };
                } else {
                    console.error('Failed to show save file picker:', err);
                }
            }
        } else {
            console.error('showSaveFilePicker is not supported in this browser.');
            return { status: SaveFilePickerStatus.UNSUPPORTED };
        }
        return { status: SaveFilePickerStatus.UNSUPPORTED };
    }

    public async downloadEncryptedFile(
        fileName: string,
        decryptFile: () => Promise<Blob | undefined>,
        setLoading: (loading: boolean) => void
    ): Promise<void> {
        let savePath: string | undefined | FileSystemFileHandle;

        setLoading(true);

        if (this.isElectron) {
            savePath = await new ElectronPlatform().getSaveFilePathElectron(fileName);
            if (!savePath) {
                setLoading(false);
                return;
            }
        } else {
            const { status, handle } = await this.showSaveFilePicker(fileName);
            if (status === SaveFilePickerStatus.CANCELLED) {
                setLoading(false);
                return;
            } else if (status === SaveFilePickerStatus.UNSUPPORTED) {
                const decryptedBlob = await decryptFile();
                if (decryptedBlob) {
                    await this.downloader.download({
                        blob: decryptedBlob,
                        name: fileName,
                    });
                }
                setLoading(false);
                return;
            }
            savePath = handle;
        }

        const decryptedBlob = await decryptFile();

        if (!decryptedBlob) {
            setLoading(false);
            return;
        }

        if (this.isElectron) {
            await new ElectronPlatform().saveFileElectron(savePath as string, decryptedBlob);
        } else if (savePath instanceof FileSystemFileHandle) {
            await this.saveFileWeb(savePath, decryptedBlob);
            const name = savePath.name;
            const key = `DOWNLOAD_TOAST_${name}`;
            const onAccept = (): void => {
                if (savePath instanceof FileSystemFileHandle) {
                    savePath.getFile().then(file => {
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    });
                }
            };
            ToastStore.sharedInstance().addOrReplaceToast({
                key,
                title: _t("download_completed"),
                props: {
                    description: name,
                    acceptLabel: _t("action|open"),
                    onAccept,
                    dismissLabel: _t("action|dismiss"),
                    numSeconds: 10,
                },
                component: GenericExpiringToast,
                priority: 99,
            });
        } else {
            await this.downloader.download({
                blob: decryptedBlob,
                name: fileName,
            });
        }
        setLoading(false);
    }
}
