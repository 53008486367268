import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import { UPDATE_EVENT } from "matrix-react-sdk/src/stores/AsyncStore";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { EFormStatus } from "../ctalk/enums/bot-form.enum";

interface IEventInfo {
    eventId: string;
    relations: MatrixEvent[];
}

export interface IRoomEventRelations {
    eventInfo: IEventInfo[];
}

export class RoomBotFormStore extends AsyncStoreWithClient<{}> {
    private static readonly internalInstance = ((): RoomBotFormStore => {
        const instance = new RoomBotFormStore();
        instance.start();
        return instance;
    })();

    private roomMap = new Map<string, IRoomEventRelations>(); // Key is room ID
    public constructor() {
        super(defaultDispatcher, {
            whiteListGlobal: false,
            roomMap: new Map(),
        });
    }

    private initRoom(roomId: string): void {
        this.roomMap.set(roomId, {
            eventInfo: [],
        })
    }

    public static get instance(): RoomBotFormStore {
        return RoomBotFormStore.internalInstance;
    }

    protected async onReady(): Promise<void> {
        this.emit(UPDATE_EVENT, null); // emit for all rooms
    }

    protected async onNotReady(): Promise<void> {
        await this.updateState({
            whiteListGlobal: false,
            roomMap: new Map(),
        });
    }

    protected async onAction(payload: ActionPayload): Promise<void> {
        // We don't actually do anything here
    }

    public setNewRelations(roomId: string, eventId: string, relations: MatrixEvent[]): void {
        if (!this.roomMap.has(roomId)) {
            this.initRoom(roomId);
        }
        const eventInfo = this.getRoomEventRelations(roomId);
        this.roomMap.set(roomId, {
            eventInfo: [...eventInfo, {eventId, relations}]
        });
    }

    public addNewRelationForEvent(roomId: string, eventId: string, relation: MatrixEvent): void {
        if (!this.roomMap.has(roomId)) {
            this.initRoom(roomId);
        }
        const eventInfo = this.getRoomEventRelations(roomId);
        const eventRelations = this.getEventRelations(roomId, eventId);
        const newEventInfo = eventRelations
            ? eventInfo.map(event => {
                if (event.eventId === eventId) {
                    return {
                        ...event,
                        relations: [...event.relations, relation]
                    };
                }
                return event;
            })
            : [...eventInfo, { eventId, relations: [relation] }];

        this.roomMap.set(roomId, { eventInfo: newEventInfo });
    }

    public getRoomEventRelations(roomId: string): IEventInfo[] {
        if (!this.roomMap.has(roomId)) {
            this.initRoom(roomId);
            return [];
        }
        return this.roomMap.get(roomId)?.eventInfo ?? [];
    }

    public getEventRelations(roomId: string, eventId: string): IEventInfo | undefined {
        if (!this.roomMap.has(roomId)) {
            this.initRoom(roomId);
        }
        const roomEvents = this.roomMap.get(roomId);
        return roomEvents?.eventInfo.find(x => x.eventId === eventId);
    }

    public isSubmittedEvent(roomId: string, eventId: string): boolean {
        const events = this.getEventRelations(roomId, eventId)?.relations;
        if (!events) {
            return false;
        }
        return events?.findIndex(e => e.getContent()?.status === EFormStatus.SUBMITTED) !== -1;
    }
}
