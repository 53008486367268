import React from "react";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { IEncryptFileContent } from "@ctalk/helpers/BotFormHelper";
import { _t, TranslationKey } from "matrix-react-sdk/src/languageHandler";

import PreviewFile from "../../elements/PreviewFile";

interface ICFileDownloadProps {
    encryptFile: IEncryptFileContent;
    previewFileExpired?: boolean;
}

interface IState {
    file: File | null;
}

class CFileDownload extends React.Component<ICFileDownloadProps, IState> {
    public constructor(props: ICFileDownloadProps) {
        super(props);
        this.state = {
            file: null,
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="mx_MediaBody mx_MFileBody_info ctalk_MFileBody_info">
                <PreviewFile
                    file={this.props.encryptFile}
                    fileName={this.props.encryptFile.filename}
                    note=''
                    isPreviewRestricted={this.props.previewFileExpired}
                />
            </div>
        );
    }
}

interface IProps {
    mxEvent: MatrixEvent;
    previewFileExpired?: boolean;
}

export default class CFormInfo extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render(): React.ReactNode {
        const content = this.props.mxEvent.getContent();
        let documentContent;
        if (content.files && content.files.length) {
            documentContent = content.files.map((file: IEncryptFileContent, index: number) => (
                <div
                    key={`${index}_${file.filename}`}
                    className="ctalk_Form_Info_Action"
                >
                    <CFileDownload
                        encryptFile={file}
                        previewFileExpired={this.props.previewFileExpired}
                    />
                </div>
            ));
        }

        return (
            <div className="ctalk_Form_Info">
                <div className="ctalk_Form_Info_From">
                    {_t("ctalk|bot_form|from" as TranslationKey, { from: content.from })}
                </div>
                <div className="ctalk_Form_Info_Description">
                    {content.body}
                </div>
                {documentContent}
            </div>
        );
    }
}
