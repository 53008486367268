import React from "react";
import { EventTimeline, Room, RoomState } from "matrix-js-sdk/src/matrix";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { _t, TranslationKey } from "matrix-react-sdk/src/languageHandler";
import { SettingsSection } from "matrix-react-sdk/src/components/views/settings/shared/SettingsSection";
import StyledRadioGroup from "matrix-react-sdk/src/components/views/elements/StyledRadioGroup";
import StyledRadioButton from "matrix-react-sdk/src/components/views/elements/StyledRadioButton";
import Heading from "matrix-react-sdk/src/components/views/typography/Heading";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";
import { formatPreciseDuration } from "matrix-react-sdk/src/DateUtils";
import LabelledToggleSwitch from 'matrix-react-sdk/src/components/views/elements/LabelledToggleSwitch';
import Field from "matrix-react-sdk/src/components/views/elements/Field";
import { ECTalkRoomStateEvent } from "@ctalk/enums/event-type.enum";
import { AutoDeleteOptions, getDefaultOptions, msToDay, dayToMs } from '@ctalk/utils/auto-delete';
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { IValidationResult } from "matrix-react-sdk/src/components/views/elements/Validation";

const DEFAULT_MAX_LIFETIME_CUSTOM_VALUE = '15'; // days

interface IProps {
    room: Room,
}

interface IState {
    maxLifetimeCurrent: string;
    maxLifetime: string;
    loading: boolean;
    showOptions: boolean;
    maxLifetimeCustom: string;
    isCustomSelected: boolean;
    invalidMaxLifetime: boolean;
}

export default class AutoDeleteMessagesPanel extends React.Component<IProps, IState> {
    private roomState: RoomState;
    protected availableOptions: AutoDeleteOptions[];
    protected maxLifetimeCustomField: React.RefObject<Field>;

    public constructor(props: IProps) {
        super(props);
        this.availableOptions = getDefaultOptions();
        const retentionOptions = SdkConfig.get<any>('setting_defaults')['Retention.advanceOptions'];
        if (retentionOptions && retentionOptions.length) {
            retentionOptions.map((options: number) => {
                this.availableOptions.push({
                    value: options + "",
                    label: null,
                })
            });
        }
        this.roomState = this.props.room.getLiveTimeline().getState(EventTimeline.FORWARDS) as RoomState;
        const roomRetentionEvents = this.roomState.getStateEvents(ECTalkRoomStateEvent.RoomRetention, "");
        const eventContent = roomRetentionEvents?.event?.content;
        // because radio button need value type is string, so convert it to string
        const maxLifetimeCurrent = eventContent?.max_lifetime ? eventContent?.max_lifetime + "" : "0";
        const showOptions = maxLifetimeCurrent !== "0";
        this.state = {
            maxLifetimeCurrent,
            maxLifetime: maxLifetimeCurrent,
            loading: false,
            showOptions: showOptions,
            maxLifetimeCustom: this.isCustomMaxLifetime(maxLifetimeCurrent) && showOptions ? msToDay(maxLifetimeCurrent) : DEFAULT_MAX_LIFETIME_CUSTOM_VALUE,
            isCustomSelected: this.isCustomMaxLifetime(maxLifetimeCurrent),
            invalidMaxLifetime: false,
        }
        this.maxLifetimeCustomField = React.createRef();
    }

    public onToggleAutoDelete = async (enabled: boolean): Promise<void> => {
        let value: string;
        if (enabled) {
            // enable
            value = this.availableOptions[0].value + "";
        } else {
            // disabled
            value = "0";
        }
        this.setState({
            maxLifetimeCurrent: value,
            maxLifetime: value,
            showOptions: value !== "0",
            isCustomSelected: false,
        });
        return this.onSave(value);
    }

    public onChangeMaxLifetime = async (value: string): Promise<void> => {
        this.setState({
            maxLifetime: value,
            showOptions: value !== "0",
            isCustomSelected: false,
            invalidMaxLifetime: false,
        });
    }

    public onSave = async (maxLifetimeChanged: string): Promise<void> => {
        const changeValue = +maxLifetimeChanged;
        const cli: MatrixClient = MatrixClientPeg.safeGet();
        const maxLifetime = changeValue > 0 ? changeValue : null;
        try {
            this.setState({
                loading: true,
            });
            await cli.sendStateEvent(this.props.room.roomId, ECTalkRoomStateEvent.RoomRetention, {
                max_lifetime: maxLifetime,
            }).then(() => {
                if (maxLifetime !== null) {
                    this.setState({
                        maxLifetimeCurrent: maxLifetime.toString(),
                    });
                }
            });
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    protected isCustomMaxLifetime = (maxLifetime: string): boolean => {
        return !this.availableOptions.some(i => i.value === maxLifetime);
    }

    protected onCustomClicked = (event: any): void => {
        this.setState({
            maxLifetime: dayToMs(this.state.maxLifetimeCustom),
            isCustomSelected: true,
        }, () => {
            event.target?.focus && event.target?.focus();
        });
    };

    protected onCustomChanged = (event: any): void => {
        let value = event.target.value.trim();
        let customValue = value;
        const validPattern = new RegExp(/^[0-9]*$/);
        if (!validPattern.test(value)) {
            value = this.state.maxLifetime;
            customValue = msToDay(this.state.maxLifetime);
        }
        if (customValue > 30) {
            value = customValue = 30;
        }
        this.setState({
            maxLifetime: dayToMs(customValue),
            maxLifetimeCustom: customValue,
        });
    };

    protected validateMaxLifetime = (input: any): Promise<IValidationResult> => {
        const inputValue = Number.parseInt(input.value);
        const isValid = !input.value.includes('.') &&
            Number.isInteger(+input.value) &&
            inputValue >= 1 &&
            inputValue < 31 // max 30 days
        ;
        this.setState({
            invalidMaxLifetime: !isValid,
        });
        return new Promise<IValidationResult>((re) => ({
            valid: isValid,
        }));
    };

    public render(): React.ReactNode {
        const definitions = this.availableOptions
            .sort((a, b) => {
                return +a.value - +b.value; // sort value A-Z
            })
            .map((o) => {
                let label;
                if (!o.label) {
                    label = formatPreciseDuration(+o.value)
                } else {
                    label = _t(o.label as TranslationKey, o.options ?? undefined);
                }
                return {
                    value: o.value, label: label
                };
            });

        return <SettingsSection heading={
            <>
                <Heading as="h2" size="3" className="ctalk_AutoDeleteMessage_heading">
                    <div>
                        {_t("ctalk|settings|security|auto_delete_messages|title")}
                        {this.state.loading && <InlineSpinner w={15} h={15} />}
                    </div>
                    <LabelledToggleSwitch
                        value={this.state.showOptions}
                        onChange={this.onToggleAutoDelete}
                        label=""
                        disabled={this.state.loading}
                    />
                </Heading>

            </>
        }>
            <div className="ctalk_AutoDeleteMsg_panel">
                <div className="mx_SettingsFieldset_description">
                    {_t("ctalk|settings|security|auto_delete_messages|description")}
                </div>
                {
                    this.state.showOptions && <div>
                        <div className="mx_SettingsFieldset_content">
                            <StyledRadioGroup
                                name="maxLifetime"
                                value={this.state.maxLifetime}
                                onChange={this.onChangeMaxLifetime}
                                definitions={definitions}
                                disabled={this.state.loading}
                            />
                        </div>
                        <div className="ctalk_AutoDeleteMsg_OptionCustom">
                            <StyledRadioButton
                                className="ctalk_AutoDeleteMsg_RadioButon"
                                name="maxLifetime"
                                value={-1}
                                onChange={(e): void => {
                                    this.setState({
                                        maxLifetime: dayToMs(this.state.maxLifetimeCustom),
                                        isCustomSelected: true,
                                    });
                                }}
                                onClick={(): void => {
                                    this.maxLifetimeCustomField.current?.focus();
                                }}
                                checked={this.state.isCustomSelected}
                            />
                            <Field
                                ref={this.maxLifetimeCustomField}
                                id="ctalk_MaxLifetimeCustom_input"
                                type="text"
                                value={this.state.maxLifetimeCustom}
                                autoComplete="off"
                                onClick={this.onCustomClicked}
                                onChange={this.onCustomChanged}
                                onValidate={this.validateMaxLifetime}
                                validateOnFocus={false}
                                validateOnChange={true}
                            />
                            <span className="ctalk_MaxLifetimeCustom_unit">{_t('ctalk|settings|security|auto_delete_messages|days')}</span>
                        </div>
                        <p className="ctalk_LocalPasscodePanel_Error">
                            {this.state.invalidMaxLifetime && _t('ctalk|settings|security|auto_delete_messages|invalid_max_lifetime')}
                        </p>
                        <AccessibleButton
                            kind="primary"
                            onClick={(): void => {this.onSave(this.state.maxLifetime)}}
                            // eslint-disable-next-line max-len
                            disabled={this.state.invalidMaxLifetime || +this.state.maxLifetime === +this.state.maxLifetimeCurrent}
                        >
                            {_t('ctalk|local_passcode|save_change')}
                        </AccessibleButton>
                    </div>
                }
            </div>
        </SettingsSection>
    }
}
