/*
Copyright 2019-2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { ReactNode } from "react";
import {
    GuestAccess,
    HistoryVisibility,
    JoinRule,
    MatrixEvent,
    RoomStateEvent,
    Room,
    EventType, EventTimeline,
} from "matrix-js-sdk/src/matrix";
import { logger } from "matrix-js-sdk/src/logger";
import { Icon as WarningIcon } from "matrix-react-sdk/res/img/warning.svg";
import { /*_t, */getCurrentLanguage } from "matrix-react-sdk/src/languageHandler";
import LabelledToggleSwitch from "matrix-react-sdk/src/components/views/elements/LabelledToggleSwitch";
import Modal from "matrix-react-sdk/src/Modal";
import QuestionDialog from "matrix-react-sdk/src/components/views/dialogs/QuestionDialog";
import StyledRadioGroup from "matrix-react-sdk/src/components/views/elements/StyledRadioGroup";
import { SettingLevel } from "matrix-react-sdk/src/settings/SettingLevel";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import { UIFeature } from "matrix-react-sdk/src/settings/UIFeature";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import SettingsFlag from "matrix-react-sdk/src/components/views/elements/SettingsFlag";
import createRoom from "matrix-react-sdk/src/createRoom";
import CreateRoomDialog from "matrix-react-sdk/src/components/views/dialogs/CreateRoomDialog";
import JoinRuleSettings from "matrix-react-sdk/src/components/views/settings/JoinRuleSettings";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import SettingsFieldset from "matrix-react-sdk/src/components/views/settings/SettingsFieldset";
import ExternalLink from "matrix-react-sdk/src/components/views/elements/ExternalLink";
import PosthogTrackers from "matrix-react-sdk/src/PosthogTrackers";
import MatrixClientContext from "matrix-react-sdk/src/contexts/MatrixClientContext";
import { SettingsSection } from "matrix-react-sdk/src/components/views/settings/shared/SettingsSection";
import SettingsTab from "matrix-react-sdk/src/components/views/settings/tabs/SettingsTab";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";
import { shouldForceDisableEncryption } from "matrix-react-sdk/src/utils/crypto/shouldForceDisableEncryption";
import { Caption } from "matrix-react-sdk/src/components/views/typography/Caption";
// CTalk imported
import InfoDialog from "matrix-react-sdk/src/components/views/dialogs/InfoDialog";
import { SdkContextClass } from "matrix-react-sdk/src/contexts/SDKContext";
import { isUserRoleInRoom } from "@ctalk/helpers/RoomHelper";
import { ERoomRole } from "@ctalk/enums/room-role.enum";
import AutoDeleteMessagesPanel from "@ctalk/components/views/settings/tabs/room/AutoDeleteMessagesPanel";
import { hasPermission } from "@ctalk/roles/PermissionView";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";

// CTalk added
import { _t } from "../../../../../languageHandler";
import { IPowerLevelsContent } from "../../../right_panel/UserInfo";

interface IProps {
    room: Room;
    closeSettingsFn: () => void;
}

interface IState {
    guestAccess: GuestAccess;
    history: HistoryVisibility;
    hasAliases: boolean;
    encrypted: boolean;
    showAdvancedSection: boolean;
    // CTalk added
    // rotationPeriodMsgs?: number;
    // busy: boolean;
}

export default class SecurityRoomSettingsTab extends React.Component<IProps, IState> {
    public static contextType = MatrixClientContext;
    public context!: React.ContextType<typeof MatrixClientContext>;

    public constructor(props: IProps, context: React.ContextType<typeof MatrixClientContext>) {
        super(props, context);

        const state = this.props.room.currentState;

        this.state = {
            guestAccess: this.pullContentPropertyFromEvent<GuestAccess>(
                state?.getStateEvents(EventType.RoomGuestAccess, ""),
                "guest_access",
                GuestAccess.Forbidden,
            ),
            history: this.pullContentPropertyFromEvent<HistoryVisibility>(
                state?.getStateEvents(EventType.RoomHistoryVisibility, ""),
                "history_visibility",
                HistoryVisibility.Shared,
            ),
            hasAliases: false, // async loaded in componentDidMount
            encrypted: context.isRoomEncrypted(this.props.room.roomId),
            showAdvancedSection: false,
            // busy: false,
        };
    }

    public componentDidMount(): void {
        this.context.on(RoomStateEvent.Events, this.onStateEvent);
        this.hasAliases().then((hasAliases) => this.setState({ hasAliases }));
        // CTalk added
        // if (this.state.encrypted) {
        //     this.setState({
        //         rotationPeriodMsgs: this.pullContentPropertyFromEvent<any>(
        //             this.props.room.currentState?.getStateEvents(EventType.RoomEncryption, ""),
        //             "rotation_period_msgs",
        //             100,
        //         ),
        //     });
        // }
    }

    private pullContentPropertyFromEvent<T>(event: MatrixEvent | null | undefined, key: string, defaultValue: T): T {
        return event?.getContent()[key] || defaultValue;
    }

    public componentWillUnmount(): void {
        this.context.removeListener(RoomStateEvent.Events, this.onStateEvent);
    }

    private onStateEvent = (e: MatrixEvent): void => {
        const refreshWhenTypes: EventType[] = [
            EventType.RoomJoinRules,
            EventType.RoomGuestAccess,
            EventType.RoomHistoryVisibility,
            EventType.RoomEncryption,
        ];
        const currentRoomId = SdkContextClass.instance.roomViewStore.getRoomId();
        if (
            refreshWhenTypes.includes(e.getType() as EventType) &&
            e.getRoomId() === currentRoomId
        ) {
            const currentUserId = this.context.getSafeUserId();
            if (currentUserId !== e.sender?.userId) {
                const currentModal = (Modal as any)?.getCurrentModal();
                // Not show dialog if dialog already open
                if (currentModal?.elem?.props?.className !== 'ctalk_dialog_security_setting_warning') {
                    // CTalk added show warning dialog
                    Modal.createDialog(InfoDialog, {
                        title: _t("ctalk|room_settings|security|enable_encryption_confirm_title_with_option_anyone"),
                        description: _t("ctalk|room_settings|security|warning_setting_changed"),
                        hasCloseButton: true,
                        className: "ctalk_dialog_security_setting_warning"
                    });
                }
            }
            if (e.getType() === EventType.RoomEncryption) {
                this.setState({ encrypted: true });
            }
            if (e.getType() === EventType.RoomHistoryVisibility) {
                const content = e.getContent();
                this.setState({ history: content?.history_visibility });
            }
            this.forceUpdate();
        }
    };

    private onEncryptionChange = async (): Promise<void> => {
        if (this.props.room.getJoinRule() === JoinRule.Public) {
            const dialog = Modal.createDialog(QuestionDialog, {
                title: _t("room_settings|security|enable_encryption_public_room_confirm_title"),
                description: (
                    <div>
                        <p>
                            {" "}
                            {_t(
                                "room_settings|security|enable_encryption_public_room_confirm_description_1",
                                undefined,
                                { b: (sub) => <b>{sub}</b> },
                            )}{" "}
                        </p>
                        <p>
                            {" "}
                            {_t(
                                "room_settings|security|enable_encryption_public_room_confirm_description_2",
                                undefined,
                                {
                                    a: (sub) => (
                                        <AccessibleButton
                                            kind="link_inline"
                                            onClick={(): void => {
                                                dialog.close();
                                                this.createNewRoom(false, true);
                                            }}
                                        >
                                            {" "}
                                            {sub}{" "}
                                        </AccessibleButton>
                                    ),
                                },
                            )}{" "}
                        </p>
                    </div>
                ),
            });

            const { finished } = dialog;
            const [confirm] = await finished;
            if (!confirm) return;
        }
        // CTalk added
        const isHistoryWorldReadable = this.state.history === HistoryVisibility.WorldReadable; // Anyone option
        const titleDiaglog = isHistoryWorldReadable ?
            _t("ctalk|room_settings|security|enable_encryption_confirm_title_with_option_anyone") : _t("room_settings|security|enable_encryption_confirm_title");
        const descriptionDiaglog = isHistoryWorldReadable ?
            _t("ctalk|room_settings|security|enable_encryption_confirm_description_option_anyone") : _t(
                "room_settings|security|enable_encryption_confirm_description",
                {},
                {
                    a: (sub) => <ExternalLink href={SdkConfig.get("help_encryption_url")}>{sub}</ExternalLink>,
                },
            );
        Modal.createDialog(QuestionDialog, {
            title: titleDiaglog,
            description: descriptionDiaglog,
            onFinished: (confirm) => {
                if (!confirm) {
                    this.setState({ encrypted: false });
                    return;
                }

                const beforeEncrypted = this.state.encrypted;
                this.setState({ encrypted: true });
                this.context
                    .sendStateEvent(this.props.room.roomId, EventType.RoomEncryption, {
                        algorithm: "m.megolm.v1.aes-sha2",
                    })
                    // CTalk added
                    .then(() => {
                        // If history = world_readable, we'll set to shared
                        if (isHistoryWorldReadable) {
                            this.onHistoryRadioToggle(HistoryVisibility.Shared);
                        }
                    })
                    .catch((e) => {
                        logger.error(e);
                        this.setState({ encrypted: beforeEncrypted });
                    });
            },
        });
    };

    private onGuestAccessChange = (allowed: boolean): void => {
        const guestAccess = allowed ? GuestAccess.CanJoin : GuestAccess.Forbidden;
        const beforeGuestAccess = this.state.guestAccess;
        if (beforeGuestAccess === guestAccess) return;

        this.setState({ guestAccess });

        this.context
            .sendStateEvent(
                this.props.room.roomId,
                EventType.RoomGuestAccess,
                {
                    guest_access: guestAccess,
                },
                "",
            )
            .catch((e) => {
                logger.error(e);
                this.setState({ guestAccess: beforeGuestAccess });
            });
    };

    private createNewRoom = async (defaultPublic: boolean, defaultEncrypted: boolean): Promise<boolean> => {
        const modal = Modal.createDialog(CreateRoomDialog, { defaultPublic, defaultEncrypted });

        PosthogTrackers.trackInteraction("WebRoomSettingsSecurityTabCreateNewRoomButton");

        const [shouldCreate, opts] = await modal.finished;
        if (shouldCreate) {
            await createRoom(this.context, opts);
        }
        return shouldCreate;
    };

    private onHistoryRadioToggle = (history: HistoryVisibility): void => {
        const beforeHistory = this.state.history;
        if (beforeHistory === history) return;

        this.setState({ history: history });
        this.context
            .sendStateEvent(
                this.props.room.roomId,
                EventType.RoomHistoryVisibility,
                {
                    history_visibility: history,
                },
                "",
            )
            .catch((e) => {
                logger.error(e);
                this.setState({ history: beforeHistory });
            });
    };

    // CTalk added
    // private onRotationPeriodToggle = (rotationPeriodMsgs: string): void => {
    //     if (this.state.rotationPeriodMsgs === +rotationPeriodMsgs) return;

    //     this.setState({ busy: true });
    //     this.context
    //         .sendStateEvent(
    //             this.props.room.roomId,
    //             EventType.RoomEncryption,
    //             {
    //                 algorithm: "m.megolm.v1.aes-sha2",
    //                 rotation_period_msgs: Number(rotationPeriodMsgs),
    //             },
    //             "",
    //         )
    //         .then(() => {
    //             this.setState({ rotationPeriodMsgs: Number(rotationPeriodMsgs) });
    //         })
    //         .catch((e) => {
    //             logger.error(e);
    //         })
    //         .finally(() => {
    //             this.setState({ busy: false });
    //         });
    // };

    private updateBlacklistDevicesFlag = (checked: boolean): void => {
        this.props.room.setBlacklistUnverifiedDevices(checked);
    };

    private async hasAliases(): Promise<boolean> {
        const cli = this.context;
        const response = await cli.getLocalAliases(this.props.room.roomId);
        const localAliases = response.aliases;
        return Array.isArray(localAliases) && localAliases.length !== 0;
    }

    private renderJoinRule(): JSX.Element {
        const room = this.props.room;

        let aliasWarning: JSX.Element | undefined;
        if (room.getJoinRule() === JoinRule.Public && !this.state.hasAliases) {
            aliasWarning = (
                <div className="mx_SecurityRoomSettingsTab_warning">
                    <WarningIcon width={15} height={15} />
                    <span>{_t("room_settings|security|public_without_alias_warning")}</span>
                </div>
            );
        }
        const description = _t("room_settings|security|join_rule_description", {
            roomName: room.name,
        });

        let advanced: JSX.Element | undefined;
        if (room.getJoinRule() === JoinRule.Public) {
            advanced = (
                <div>
                    <AccessibleButton
                        onClick={this.toggleAdvancedSection}
                        kind="link"
                        className="mx_SettingsTab_showAdvanced"
                        aria-expanded={this.state.showAdvancedSection}
                    >
                        {this.state.showAdvancedSection ? _t("action|hide_advanced") : _t("action|show_advanced")}
                    </AccessibleButton>
                    {this.state.showAdvancedSection && this.renderAdvanced()}
                </div>
            );
        }

        return (
            <SettingsFieldset legend={_t("room_settings|access|title")} description={description}>
                <JoinRuleSettings
                    room={room}
                    beforeChange={this.onBeforeJoinRuleChange}
                    onError={this.onJoinRuleChangeError}
                    closeSettingsFn={this.props.closeSettingsFn}
                    promptUpgrade={true}
                    aliasWarning={aliasWarning}
                />
                {advanced}
            </SettingsFieldset>
        );
    }

    private onJoinRuleChangeError = (error: Error): void => {
        Modal.createDialog(ErrorDialog, {
            title: _t("room_settings|security|error_join_rule_change_title"),
            description: error.message ?? _t("room_settings|security|error_join_rule_change_unknown"),
        });
    };

    private onBeforeJoinRuleChange = async (joinRule: JoinRule): Promise<boolean> => {
        if (this.state.encrypted && joinRule === JoinRule.Public) {
            const dialog = Modal.createDialog(QuestionDialog, {
                title: _t("room_settings|security|encrypted_room_public_confirm_title"),
                description: (
                    <div>
                        <p>
                            {" "}
                            {_t("room_settings|security|encrypted_room_public_confirm_description_1", undefined, {
                                b: (sub) => <b>{sub}</b>,
                            })}{" "}
                        </p>
                        <p>
                            {" "}
                            {_t("room_settings|security|encrypted_room_public_confirm_description_2", undefined, {
                                a: (sub) => (
                                    <AccessibleButton
                                        kind="link_inline"
                                        onClick={(): void => {
                                            dialog.close();
                                            this.createNewRoom(true, false);
                                        }}
                                    >
                                        {" "}
                                        {sub}{" "}
                                    </AccessibleButton>
                                ),
                            })}{" "}
                        </p>
                    </div>
                ),
            });

            const { finished } = dialog;
            const [confirm] = await finished;
            if (!confirm) return false;
        }

        return true;
    };

    private renderHistory(): ReactNode {
        if (!SettingsStore.getValue(UIFeature.RoomHistorySettings)) {
            return null;
        }

        const client = this.context;
        const history = this.state.history;
        const state = this.props.room.currentState;
        const canChangeHistory = state?.mayClientSendStateEvent(EventType.RoomHistoryVisibility, client);

        const options = [
            {
                value: HistoryVisibility.Shared,
                label: _t("room_settings|security|history_visibility_shared"),
            },
            {
                value: HistoryVisibility.Invited,
                label: _t("room_settings|security|history_visibility_invited"),
            },
            {
                value: HistoryVisibility.Joined,
                label: _t("room_settings|security|history_visibility_joined"),
            },
        ];

        // World readable doesn't make sense for encrypted rooms
        if (!this.state.encrypted || history === HistoryVisibility.WorldReadable) {
            options.unshift({
                value: HistoryVisibility.WorldReadable,
                label: _t("ctalk|settings|security|history_visibility_world_readable"),
            });
        }

        const description = _t("room_settings|security|history_visibility_warning");

        return (
            <SettingsFieldset legend={_t("room_settings|security|history_visibility_legend")} description={description}>
                <StyledRadioGroup
                    name="historyVis"
                    value={history}
                    onChange={this.onHistoryRadioToggle}
                    disabled={!canChangeHistory}
                    definitions={options}
                />
            </SettingsFieldset>
        );
    }

    private toggleAdvancedSection = (): void => {
        this.setState({ showAdvancedSection: !this.state.showAdvancedSection });
    };

    private renderAdvanced(): JSX.Element {
        const client = this.context;
        const guestAccess = this.state.guestAccess;
        const state = this.props.room.currentState;
        const canSetGuestAccess = state?.mayClientSendStateEvent(EventType.RoomGuestAccess, client);

        return (
            <div className="mx_SecurityRoomSettingsTab_advancedSection">
                <LabelledToggleSwitch
                    value={guestAccess === GuestAccess.CanJoin}
                    onChange={this.onGuestAccessChange}
                    disabled={!canSetGuestAccess}
                    label={_t("room_settings|visibility|guest_access_label")}
                />
                <p>{_t("room_settings|security|guest_access_warning")}</p>
            </div>
        );
    }

    // private renderRotationPeriod(): ReactNode {
    //     const defaultRotationPeriodMsgs = 100;

    //     const formater = new Intl.NumberFormat(getCurrentLanguage());
    //     const options = [100, 1000, 10000].map((v) => {
    //         return {
    //             value: '' + v,
    //             label: _t(
    //                 "ctalk|settings|security|rotation_period_encryption|messages",
    //                 { messages: formater.format(v) },
    //             ),
    //         };
    //     });
    //     const legend = <>
    //         { _t("ctalk|settings|security|rotation_period_encryption|title") }
    //         { this.state.busy && <InlineSpinner /> }
    //     </>;

    //     return (
    //         <SettingsFieldset
    //             legend={legend}
    //             description={_t("ctalk|settings|security|rotation_period_encryption|description")}>
    //             <StyledRadioGroup
    //                 name="rotationPeriodMsgs"
    //                 value={'' + (this.state.rotationPeriodMsgs ?? defaultRotationPeriodMsgs)}
    //                 onChange={this.onRotationPeriodToggle}
    //                 disabled={this.state.busy}
    //                 definitions={options}
    //             />
    //         </SettingsFieldset>
    //     );
    // };

    public render(): React.ReactNode {
        const client = this.context;
        const room = this.props.room;
        const isEncrypted = this.state.encrypted;
        const hasEncryptionPermission = room.currentState.mayClientSendStateEvent(EventType.RoomEncryption, client);
        const isEncryptionForceDisabled = shouldForceDisableEncryption(client);
        const canEnableEncryption = !isEncrypted && !isEncryptionForceDisabled && hasEncryptionPermission;

        let encryptionSettings: JSX.Element | undefined;
        if (isEncrypted && SettingsStore.isEnabled("blacklistUnverifiedDevices")) {
            encryptionSettings = (
                <SettingsFlag
                    name="blacklistUnverifiedDevices"
                    level={SettingLevel.ROOM_DEVICE}
                    onChange={this.updateBlacklistDevicesFlag}
                    roomId={this.props.room.roomId}
                />
            );
        }

        const historySection = this.renderHistory();
        let autoDeleteMsgSection;
        const roomMember = room.getMember(client.getSafeUserId());
        const roomState = room.getLiveTimeline().getState(EventTimeline.FORWARDS);
        const powerLevelsEvent = roomState?.getStateEvents(EventType.RoomPowerLevels, "");
        const powerLevels = powerLevelsEvent && powerLevelsEvent.getContent() as IPowerLevelsContent;
        if (hasPermission(roomMember, powerLevels, 'state_default')) {
            autoDeleteMsgSection = <AutoDeleteMessagesPanel room={this.props.room} />
        }

        // CTalk added
        // const isOwner = isUserRoleInRoom(ERoomRole.OWNER, room.getMember(room.myUserId || ""));
        // let rotationPeriod;
        // if (isOwner && isEncrypted) {
        //     rotationPeriod = this.renderRotationPeriod();
        // }

        return (
            <SettingsTab>
                <SettingsSection heading={_t("room_settings|security|title")}>
                    <SettingsFieldset
                        legend={_t("settings|security|encryption_section")}
                        description={
                            isEncryptionForceDisabled && !isEncrypted
                                ? undefined
                                : _t("room_settings|security|encryption_permanent")
                        }
                    >
                        <LabelledToggleSwitch
                            value={isEncrypted}
                            onChange={this.onEncryptionChange}
                            label={_t("common|encrypted")}
                            disabled={!canEnableEncryption}
                        />
                        {isEncryptionForceDisabled && !isEncrypted && (
                            <Caption>{_t("room_settings|security|encryption_forced")}</Caption>
                        )}
                        {encryptionSettings}
                    </SettingsFieldset>
                    {/* {rotationPeriod} */}
                    {autoDeleteMsgSection}
                    {this.renderJoinRule()}
                    {historySection}
                </SettingsSection>
            </SettingsTab>
        );
    }
}
