function sortAndStringify(obj: any): string {
    if (typeof obj !== 'object' || obj === null) {
        return;
    }
    const sortedObj = Object.keys(obj).sort((a, b) => a.localeCompare(b)).reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {});

    const jsonString = JSON.stringify(sortedObj);

    return jsonString;
}

export function areObjectsEqual(obj1: any, obj2: any): boolean {
    return sortAndStringify(obj1) === sortAndStringify(obj2);
}
