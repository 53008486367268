import { logger } from "matrix-js-sdk/src/logger";
import { SetUpAuthOptions } from "@ctalk/interfaces/ICloudKit";

import { decrypt, encrypt } from "../utils/aes";

export interface CloudKitConfig {
    zone_name: string;
    api_access_token: string;
    api_access_token_desktop: string;
    container_identifier: string;
}

let container;
const CLOUDKIT_CONFIG = {
    DEFAULT_ZONE_NAME: "_defaultZone",
    RECORD_TYPE: "SecurityKey",
};
export function setContainer(newContainer): void {
    container = newContainer;
}

export function getContainer(): any {
    if (!container) {
        setContainer(window.CloudKit.getDefaultContainer());
    }
    return container;
}

export interface CloudKitConfigureOptions {
    locale?: string;
    services?: {
        logger?: any; // An object with methods info, log, warn, and error. You can set this to window.console
        fetch?: any; // A function compatible with window.fetch
        Promise?: any; // An object used for deferred and asynchronous computations. To learn more, go to Mozilla Developer Network: Promise.
        authTokenStore?: {
            putToken?: (containerIdentifier: string, authToken: string) => void;
            getToken?: (containerIdentifier: string) => string | null;
        };
    };
    containers: {
        containerIdentifier: string;
        apiTokenAuth: {
            apiToken: string;
            persist: boolean; // Sets a cookie
            signInButton?: {
                id: string;
                theme: "black" | "white" | "white-with-outline";
            };
            signOutButton?: {
                id: string;
                theme: "black" | "white" | "white-with-outline";
            };
        };
        environment: "development" | "production";
    }[];
}

interface ZoneOptions {
    zoneID: string;
}

interface SaveRecordsOptions extends ZoneOptions {
    recordChangeTag?: string;
}

interface FetchRecordsOptions extends ZoneOptions {
    decryptKey?: boolean;
    passCode?: string;
}

interface DeleteRecordsOptions extends ZoneOptions {
    throwError?: boolean;
}

export async function configure(options: CloudKitConfigureOptions): void {
    if (!window.CloudKit) {
        logger.error(`Failed to load CloudKit, please check configuration and try again.`);
        return;
    }
    try {
        window.CloudKit.configure(options);
        logger.log(`CloudKit configure completed and ready for use.`);
    } catch (e) {
        logger.error(`Error when configure CloudKit: `, e);
    }
}

/**
 * Common set up authentication with Apple ID using CloudKit
 */
export function setUpAuth(options: SetUpAuthOptions): Promise<any> {
    const container = getContainer();

    const gotoAuthenticatedState = async (userIdentity): void => {
        options.onAuthenticated(userIdentity);
        container
            .whenUserSignsOut()
            .then(gotoUnauthenticatedState);
    };

    const gotoUnauthenticatedState = (error): void => {
        options.onUnauthenticated?.(error);
        container
            .whenUserSignsIn()
            .then(gotoAuthenticatedState)
            .catch(gotoUnauthenticatedState);
    };
    return container.setUpAuth().then((userIdentity) => {
        options.onFinished();
        // Either a sign-in or a sign-out button was added to the DOM.
        // userIdentity is the signed-in user or null.
        if (userIdentity) {
            gotoAuthenticatedState(userIdentity);
        } else {
            gotoUnauthenticatedState();
        }
    });
}

export function getPublicDB(): any {
    return getContainer().publicCloudDatabase;
}

export function getPrivateDB(): any {
    return getContainer().privateCloudDatabase;
}

function handlingResponse(response): any {
    if (response.hasErrors) {
        throw response.errors[0];
    }
    return response;
}

export function queryPrivateDB(query: any): Promise<any> {
    return getPrivateDB().performQuery(query).then(handlingResponse);
}

export function savePrivateDBRecords(record: any, options: ZoneOptions): Promise<any> {
    return getPrivateDB().saveRecords(record, options).then(handlingResponse);
}

export function fetchPrivateDBRecords(record: any, options: ZoneOptions): Promise<any> {
    return getPrivateDB()
        .fetchRecords(record, options)
        .then(handlingResponse)
        .catch((error) => {
            if (error.ckErrorCode === "ZONE_NOT_FOUND") {
                return savePrivateDBRecordZones(options.zoneID);
            }
            throw error;
        });
}

export function deletePrivateDBRecords(record: any, options: ZoneOptions): Promise<any> {
    return getPrivateDB()
        .deleteRecords(record, options)
        .then(handlingResponse);
}

export function savePrivateDBRecordZones(zoneName: string): Promise<any> {
    return getPrivateDB()
        .saveRecordZones({ zoneName: zoneName })
        .then(handlingResponse);
}

export async function saveKeyEncrypted(
    userId: string,
    keyContent: string,
    passCode: string,
    options: SaveRecordsOptions = { zoneID: CLOUDKIT_CONFIG.DEFAULT_ZONE_NAME },
): Promise<any> {
    const keyEncrypted = await encrypt(keyContent, userId + passCode);
    let oldRecords;
    try {
        oldRecords = await fetchPrivateDBRecords(userId, {
            zoneID: options.zoneID,
        });
    } catch (e) {
        // Case not exist old records
        oldRecords = null;
    }
    const query: any = {
        recordType: CLOUDKIT_CONFIG.RECORD_TYPE,
        recordName: userId,
        fields: {
            seedPhrase: {
                value: keyEncrypted,
            },
        },
    };
    // if existed old record, get recordChangeTag for update
    // check case oldRecords existed but no data in records
    if (oldRecords && oldRecords.records && oldRecords.records[0].recordChangeTag) {
        query.recordChangeTag = oldRecords.records[0].recordChangeTag;
    }
    return savePrivateDBRecords(query, { zoneID: options.zoneID });
}

export function fetchKeyDecrypted(
    userId: string,
    options: FetchRecordsOptions = {
        zoneID: CLOUDKIT_CONFIG.DEFAULT_ZONE_NAME,
        decryptKey: false,
        passCode: null,
    },
): Promise<any> {
    return fetchPrivateDBRecords(userId, { zoneID: options.zoneID }).then(
        async (res) => {
            const seedPhrase = res.records[0].fields.seedPhrase;
            let key = seedPhrase.value;
            if (options.decryptKey) {
                key = await decrypt(key, userId + options.passCode);
            }
            return {
                key: key,
                recordChangeTag: res.records[0].recordChangeTag,
            };
        },
    );
}

export function deleteKeyEncrypted(
    userId: string,
    options: DeleteRecordsOptions = {} as DeleteRecordsOptions,
): Promise<any> {
    const opts: DeleteRecordsOptions = { // Merge input options with default options
        ...{
            zoneID: CLOUDKIT_CONFIG.DEFAULT_ZONE_NAME,
            throwError: false,
        },
        ...options,
    };
    return deletePrivateDBRecords(userId, { zoneID: opts.zoneID })
        .catch((e) => {
            if (opts.throwError) {
                throw e;
            }
            return;
        });
}

/**
 * Decrypt Key
 *
 * @param key
 * @param password userId + passcode
 */
export async function decryptKey(key, password): Promise<any> {
    return await decrypt(key, password);
}
