import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import { UPDATE_EVENT } from "matrix-react-sdk/src/stores/AsyncStore";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import { ERoomMessageEventType } from "@ctalk/interfaces/rooms/IRoomMessageEvent";

interface IState {
    [key: ERoomMessageEventType]: boolean;
}

export class RightPanelStatusStore extends AsyncStoreWithClient<IState> {
    private static readonly internalInstance = new RightPanelStatusStore();

    public constructor() {
        super(defaultDispatcher, {});
    }

    public static get instance(): RightPanelStatusStore {
        return RightPanelStatusStore.internalInstance;
    }

    protected async onReady(): Promise<void> {
        this.emit(UPDATE_EVENT, null); // emit for all rooms
    }

    protected async onNotReady(): Promise<void> {
        await this.updateState({});
    }

    protected async onAction(payload: ActionPayload): Promise<void> {
        // We don't actually do anything here
    }

    private async setStatusPanel(panel: ERoomMessageEventType, status: boolean): Promise<void> {
        await this.updateState({
            [panel]: status,
        });
    }

    public panelOpened(panel: ERoomMessageEventType): void {
        this.setStatusPanel(panel, true);
    }

    public panelClosed(panel: ERoomMessageEventType): void {
        this.setStatusPanel(panel, false);
    }

    public getStatusPanel(panel: ERoomMessageEventType): boolean {
        return this.state[panel];
    }
}
