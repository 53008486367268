/*
Copyright 2019 Michael Telatynski <7t3chguy@gmail.com>
Copyright 2015, 2016, 2019, 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from "react";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import CopyableText from "matrix-react-sdk/src/components/views/elements/CopyableText";
import SyntaxHighlight from "matrix-react-sdk/src/components/views/elements/SyntaxHighlight";
import { EFormRelType } from "@ctalk/enums/bot-form.enum";
import { MatrixClient } from "matrix-js-sdk/src/client";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { stringify } from "matrix-react-sdk/src/components/views/dialogs/devtools/Event";
import { RelationType } from "matrix-js-sdk/src/@types/event";
import { ECustomMsgType } from "@ctalk/enums/custom-event";

import { _t } from "../../../languageHandler";
import BaseDialog from "../../../components/views/dialogs/BaseDialog";

interface IProps {
    mxEvent: MatrixEvent; // the MatrixEvent associated with the context menu
    onFinished(): void;
}

interface IStates {
    viewSourceContent: JSX.Element[];
    relationType: string;
}

export default class ViewRelations extends React.Component<IProps, IStates> {
    private client: MatrixClient;
    public constructor(props: IProps) {
        super(props);
        this.client = MatrixClientPeg.get()!;
        const isBotForm = this.props.mxEvent.getContent().msgtype === ECustomMsgType.CForm

        this.state = {
            viewSourceContent: [],
            relationType: isBotForm ? EFormRelType.FormUpdate : RelationType.Replace,
        };
    }

    public componentDidMount(): void {
        this.initData();
    }

    private async initData(): Promise<void> {
        const viewSourceContent = await this.viewSourceContent();
        this.setState({ viewSourceContent });
    }

    // returns the dialog body for viewing the event source
    private async viewSourceContent(): Promise<JSX.Element[]> {
        const event = this.props.mxEvent;
        const {events} = await this.client.relations(
            event.getRoomId()!,
            event.getId()!,
            this.state.relationType,
        );
        // await Promise.all(events.map((e) => this.client.decryptEventIfNeeded(e)));
        return events.map(ev => {
            const mxEvent = ev as any;
            const isEncrypted = mxEvent.isEncrypted();
            const decryptedEventSource = mxEvent.clearEvent; // FIXME: clearEvent is private
            const originalEventSource = mxEvent.event;
            const copyOriginalFunc = (): string => {
                return stringify(originalEventSource);
            };
            const eventHeading = <CopyableText getTextToCopy={(): string => ev.getId()!} border={false}>
                {_t("devtools|event_id", { eventId: ev.getId() })}
            </CopyableText>;
            if (isEncrypted) {
                const copyDecryptedFunc = (): string => {
                    return stringify(decryptedEventSource || {});
                };
                return (
                    <>
                        {eventHeading}
                        <details className="mx_ViewSource_details">
                            <summary>
                            <span className="mx_ViewSource_heading">
                                {_t("devtools|view_source_decrypted_event_source")}
                            </span>
                            </summary>
                            {decryptedEventSource ? (
                                <CopyableText getTextToCopy={copyDecryptedFunc}>
                                    <SyntaxHighlight language="json">{stringify(decryptedEventSource)}</SyntaxHighlight>
                                </CopyableText>
                            ) : (
                                <div>{_t("devtools|view_source_decrypted_event_source_unavailable")}</div>
                            )}
                        </details>
                        <details className="mx_ViewSource_details">
                            <summary>
                                <span className="mx_ViewSource_heading">{_t("devtools|original_event_source")}</span>
                            </summary>
                            <CopyableText getTextToCopy={copyOriginalFunc}>
                                <SyntaxHighlight language="json">{stringify(originalEventSource)}</SyntaxHighlight>
                            </CopyableText>
                        </details>
                    </>
                );
            } else {
                return (
                    <>
                        {eventHeading}
                        <div className="mx_ViewSource_heading">{_t("devtools|original_event_source")}</div>
                        <CopyableText getTextToCopy={copyOriginalFunc}>
                            <SyntaxHighlight language="json">{stringify(originalEventSource)}</SyntaxHighlight>
                        </CopyableText>
                    </>
                );
            }
        });
    }


    public render(): React.ReactNode {
        const mxEvent = this.props.mxEvent.replacingEvent() || this.props.mxEvent;

        const roomId = mxEvent.getRoomId()!;
        const eventId = mxEvent.getId()!;

        return (
            <BaseDialog className="mx_ViewSource" onFinished={this.props.onFinished} title="View Relations">
                <div className="mx_ViewSource_header">
                    <CopyableText getTextToCopy={(): string => roomId} border={false}>
                        {_t("devtools|room_id", { roomId })}
                    </CopyableText>
                    <CopyableText getTextToCopy={(): string => eventId} border={false}>
                        {_t("devtools|event_id", { eventId })}
                    </CopyableText>
                    {mxEvent.threadRootId && (
                        <CopyableText getTextToCopy={(): string => mxEvent.threadRootId!} border={false}>
                            {_t("devtools|thread_root_id", {
                                threadRootId: mxEvent.threadRootId,
                            })}
                        </CopyableText>
                    )}
                </div>
                {this.state
                && this.state.viewSourceContent
                    ? this.state.viewSourceContent.map((content, index) => {
                        return (
                            <div key={index}>
                                {content}
                            </div>
                        )
                    })
                    : null}
            </BaseDialog>
        );
    }
}
