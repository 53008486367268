import { MatrixClient } from "matrix-js-sdk/src/client";
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import { EventType } from "matrix-js-sdk/src/@types/event";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import { ViewRoomPayload } from "matrix-react-sdk/src/dispatcher/payloads/ViewRoomPayload";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { ISendEventResponse } from "matrix-js-sdk/src/@types/requests";
import { ViewRoom as ViewRoomEvent } from "@matrix-org/analytics-events/types/typescript/ViewRoom";

export async function unpinMessage(cli: MatrixClient, event: MatrixEvent): Promise<ISendEventResponse | void> {
    const room = cli.getRoom(event.getRoomId());
    const pinnedEvents = room?.currentState.getStateEvents(EventType.RoomPinnedEvents, "");
    if (pinnedEvents?.getContent()?.pinned) {
        const pinned = pinnedEvents.getContent().pinned;
        const index = pinned.indexOf(event.getId());
        if (index !== -1) {
            pinned.splice(index, 1);
            return await cli.sendStateEvent(room?.roomId, EventType.RoomPinnedEvents, { pinned }, "");
        }
    }
}

export function dispatchToMessage(
    roomId: string,
    eventId: string,
    highlighted = false,
    metricsTrigger:string | undefined = undefined,
): void {
    if (roomId === undefined || eventId === undefined) {
        return;
    }
    dis.dispatch<ViewRoomPayload>({
        action: Action.ViewRoom,
        event_id: eventId,
        highlighted,
        room_id: roomId,
        metricsTrigger: metricsTrigger as ViewRoomEvent["trigger"],
    });
}

export function isStickerType(mxEvent: MatrixEvent): boolean {
    return mxEvent.getType() === "m.sticker";
}

