/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { ReactNode } from "react";
// import MImageBody from "matrix-react-sdk/src/components/views/messages/MImageBody";
import { BLURHASH_FIELD } from "matrix-react-sdk/src/utils/image-media";
import Tooltip from "matrix-react-sdk/src/components/views/elements/Tooltip";
import { IMediaEventContent } from "matrix-react-sdk/src/customisations/models/IMediaEventContent";
// CTalk imported
import Lottie from "react-lottie";
import classNames from "classnames";
import { logger } from "matrix-js-sdk/src/logger";
import { createThumbnail } from "matrix-react-sdk/src/utils/image-media";
import { blobIsAnimated, mayBeAnimated } from "matrix-react-sdk/src/utils/Image";
import SettingsStore from "matrix-react-sdk/src/settings/SettingsStore";
import MediaProcessingError from "matrix-react-sdk/src/components/views/messages/shared/MediaProcessingError";
import { _t } from "matrix-react-sdk/src/languageHandler";
import { getForwardedEventInfo } from "@ctalk/utils/helper";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";

// CTalk added
import MImageBody from "./MImageBody";

export default class MStickerBody extends MImageBody {
    /**
     * CTalk added
     */
    protected async downloadImage(): Promise<void> {
        if (this.state.contentUrl) return; // already downloaded

        let thumbUrl: string;
        let contentUrl: string;
        if (this.props.mediaEventHelper.media.isEncrypted) {
            try {
                ([contentUrl, thumbUrl] = await Promise.all([
                    this.props.mediaEventHelper.sourceUrl.value,
                    this.props.mediaEventHelper.thumbnailUrl.value,
                ]));
            } catch (error) {
                if (this.unmounted) return;
                logger.warn("Unable to decrypt attachment: ", error);
                // Set a placeholder image when we can't decrypt the image.
                this.setState({ error });
            }
        } else {
            thumbUrl = this.getThumbUrl();
            contentUrl = this.getContentUrl();
        }

        const content = this.props.mxEvent.getContent<IMediaEventContent>();
        const mediaContent = await this.getStickerAnimation(content, contentUrl);
        if (mediaContent) {
            if (this.unmounted) return;
            this.setState({
                contentUrl,
                thumbUrl,
                animationJsonData: mediaContent,
                isAnimated: true,
            });
            this.playStickerAnimation();
            return;
        }
        let isAnimated = mayBeAnimated(content.info?.mimetype);

        // If there is no included non-animated thumbnail then we will generate our own, we can't depend on the server
        // because 1. encryption and 2. we can't ask the server specifically for a non-animated thumbnail.
        if (isAnimated && !SettingsStore.getValue("autoplayGifs")) {
            if (!thumbUrl || !content?.info?.thumbnail_info || mayBeAnimated(content.info.thumbnail_info.mimetype)) {
                const img = document.createElement("img");
                const loadPromise = new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
                img.crossOrigin = "Anonymous"; // CORS allow canvas access
                img.src = contentUrl;

                await loadPromise;

                const blob = await this.props.mediaEventHelper.sourceBlob.value;
                if (!await blobIsAnimated(content.info.mimetype, blob)) {
                    isAnimated = false;
                }

                if (isAnimated) {
                    const thumb = await createThumbnail(img, img.width, img.height, content.info.mimetype, false);
                    thumbUrl = URL.createObjectURL(thumb.thumbnail);
                }
            }
        }

        if (this.unmounted) return;
        this.setState({
            contentUrl,
            thumbUrl,
            isAnimated,
        });
    }

    /**
     * CTalk added: support sticker animation import from Telegram
     *
     * @param content
     * @param contentUrl
     */
    public getStickerAnimation(content, contentUrl): Promise<string> {
        if (content.info.mimetype !== 'image/tgs') {
            return;
        }
        const ckClient = CTalkClientPeg.get();
        return ckClient.getMediaContent(contentUrl);
    }

    // Mostly empty to prevent default behaviour of MImageBody
    protected onClick = (ev: React.MouseEvent): void => {
        ev.preventDefault();
        if (!this.state.showImage) {
            this.showImage();
        }
    };

    // MStickerBody doesn't need a wrapping `<a href=...>`, but it does need extra padding
    // which is added by mx_MStickerBody_wrapper
    protected wrapImage(contentUrl: string, children: React.ReactNode): JSX.Element {
        let onClick: React.MouseEventHandler | undefined;
        if (!this.state.showImage) {
            onClick = this.onClick;
        }
        return (
            <div className="mx_MStickerBody_wrapper" onClick={onClick}>
                {" "}
                {children}{" "}
            </div>
        );
    }

    // Placeholder to show in place of the sticker image if img onLoad hasn't fired yet.
    protected getPlaceholder(width: number, height: number): ReactNode {
        if (this.props.mxEvent.getContent().info?.[BLURHASH_FIELD]) return super.getPlaceholder(width, height);
        return (
            <img
                aria-hidden
                alt=""
                className="mx_MStickerBody_placeholder"
                src={
                    // eslint-disable-next-line
                    require("matrix-react-sdk/res/img/icons-show-stickers.svg").default
                }
                width="80"
                height="80"
                onMouseEnter={this.onImageEnter}
                onMouseLeave={this.onImageLeave}
            />
        );
    }

    // Tooltip to show on mouse over
    protected getTooltip(): ReactNode {
        const content = this.props.mxEvent && this.props.mxEvent.getContent();

        if (!content || !content.body || !content.info || !content.info.w) return null;

        return (
            <div style={{ left: content.info.w + "px" }} className="mx_MStickerBody_tooltip">
                <Tooltip label={content.body} />
            </div>
        );
    }

    // Don't show "Download this_file.png ..."
    protected getFileBody(): ReactNode {
        return null;
    }

    protected getBanner(content: IMediaEventContent): ReactNode {
        return null; // we don't need a banner, we have a tooltip
    }

    /**
     * CTalk added
     */
    protected playStickerAnimation = (): void => {
        if (!this.state.isStoppedAnimation) { // ignore if is playing
            return; // ignore set state with same value.
        }
        this.setState({
            isStoppedAnimation: false,
        });
        setTimeout(() => {
            this.setState({
                isStoppedAnimation: true,
            });
        }, 5000);
    };

    /**
     * CTalk added
     */
    public render(): React.Component {
        const content = this.props.mxEvent.getContent<IMediaEventContent>();
        const isForwardedEvent = !!getForwardedEventInfo(this.props.mxEvent.getContent());

        if (this.state.error) {
            return (
                <MediaProcessingError className="mx_MImageBody">
                    { _t("timeline|m.image|error_decrypting") }
                </MediaProcessingError>
            );
        }

        let contentUrl = this.state.contentUrl;
        let thumbUrl: string;
        if (this.props.forExport) {
            contentUrl = this.props.mxEvent.getContent().url ?? this.props.mxEvent.getContent().file?.url;
            thumbUrl = contentUrl;
        } else if (this.state.isAnimated && SettingsStore.getValue("autoplayGifs")) {
            thumbUrl = contentUrl;
        } else {
            thumbUrl = this.state.thumbUrl ?? this.state.contentUrl;
        }

        const stickerSize = 200;

        const thumbnail = this.messageContent(contentUrl, thumbUrl, content, stickerSize, null, this.props.mxEvent.getType());
        const defaultOptions = {
            loop: true,
            autoplay: false,
            animationData: this.state.animationJsonData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
                clearCanvas: false,
                progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
                hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
            },
        };

        const lineClasses = classNames("mx_MImageBody", {
            ctalk_MStickerBody_forward: isForwardedEvent,
        });

        return (
            <div className={lineClasses}>
                {
                    this.state.animationJsonData ?
                        <div
                            style={{ "width": `${stickerSize}px` }}
                            onMouseEnter={(): void => this.playStickerAnimation()}
                        >
                            <Lottie
                                options={defaultOptions}
                                height={stickerSize}
                                width={stickerSize}
                                isStopped={this.state.isStoppedAnimation}
                            />
                        </div> :
                        thumbnail
                }
            </div>
        );
    }
}
