import React from "react";
import { getBlobSafeMimeType } from "matrix-react-sdk/src/utils/blobs";
import Modal from "matrix-react-sdk/src/Modal";
import ImageView from "matrix-react-sdk/src/components/views/elements/ImageView";
import classNames from "classnames";
import TextWithTooltip from "matrix-react-sdk/src/components/views/elements/TextWithTooltip";
import { decryptFile } from "matrix-react-sdk/src/utils/DecryptFile";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";
import { downloadError, IErrorDownloaded } from "@ctalk/utils/message-error";

import VideoView from "./VideoView";
import PDFView from "./PDFView";
import { IEncryptFileContent, deCompactEncryptFileContent } from "../../../helpers/BotFormHelper";
import { truncate } from "../../../helpers/StringHelper";
import { _t } from "../../../../languageHandler";

interface IProps {
    file: File | IEncryptFileContent;
    fileName: string;
    note?: string;
    isNotAllowDownload?: boolean; // Default is Allow Download;
    isPreviewRestricted?: boolean;
    showFileSize?: boolean;
    isNotShortenFileName?: boolean; // Default is Shorten FileName;
}

interface IState {
    fileName: string;
    fileType: string;
    isEncryptFile: boolean;
}

function isEncryptFileInput(file: any): file is IEncryptFileContent {
    return file.hashed !== undefined;
}

export default class PreviewFile extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        const isEncryptFile = isEncryptFileInput(this.props.file);
        this.state = {
            fileName: this.props.fileName,
            fileType:
                isEncryptFile ? this.props.file.mime_type : this.props.file.type,
            isEncryptFile,
        };
    }

    private getUrl = (file: File): string => {
        const mimeType = getBlobSafeMimeType(file.type);
        const blob = new Blob([file!], { type: mimeType });
        return URL.createObjectURL(blob);
    };

    private onDownloadClick = (sourceUrl: string): void => {
        const a = document.createElement("a");
        a.href = sourceUrl!;
        if (this.state.fileName) a.download = this.state.fileName;
        a.target = "_blank";
        a.rel = "noreferrer noopener";
        a.click();
    };

    private onDecryptFile = async (encryptFile: IEncryptFileContent): Promise<File | undefined> => {
        const fileCompacted = deCompactEncryptFileContent(encryptFile);
        const info = {
            mimetype: encryptFile.mime_type
        };
        try {
            const blob = await decryptFile(fileCompacted, info);
            return new File(
                [blob as any], // cast as any
                encryptFile.filename,
                {
                    lastModified: new Date().getTime(),
                    type: encryptFile.mime_type
                }
            );
        } catch (error) {
            let description;
            if (downloadError(error as IErrorDownloaded)) {
                description = _t("ctalk|error|media_deleted");
            } else {
                description = _t("ctalk|error|decrypt_file_failed");
            }
            Modal.createDialog(ErrorDialog, {
                description,
            });
        }
    }

    private onClickFile = async (): Promise<void> => {
        if (this.props.isPreviewRestricted) {
            return;
        }
        let sourceUrl;
        let file;
        if (this.state.isEncryptFile) {
            file = await this.onDecryptFile(this.props.file as IEncryptFileContent);
            if (!file) {
                return;
            }
            sourceUrl = this.getUrl(file);
        } else {
            file = this.props.file as File;
            sourceUrl = this.getUrl(file);
        }
        const httpUrl = sourceUrl;
        if (!httpUrl) return;
        const params = {
            src: httpUrl,
            name: file?.name,
            description: this.props.note,
            isNotAllowDownload: this.props.isNotAllowDownload,
            isPreviewFileModal: true,
        };
        if (file?.type.includes("image/")) {
            Modal.createDialog(ImageView, params, "mx_Dialog_lightbox");
        } else if (file?.type.includes("video/")) {
            Modal.createDialog(VideoView, params, "mx_Dialog_lightbox");
        } else if (file?.type.includes("/pdf")) {
            Modal.createDialog(PDFView, {
                ...params,
                file,
            }, "mx_Dialog_lightbox");
        } else if (!this.props.isNotAllowDownload) {
            // Handle download if not show preview
            this.onDownloadClick(sourceUrl);
        }
    };

    public render(): React.ReactNode {
        const infoPreviewClassname = classNames("ctalk_file_info_preview", {
            ctalk_file_info_preview_restricted: this.props.isPreviewRestricted,
        });

        return (
            <div
                className={infoPreviewClassname}
                onClick={this.onClickFile}
            >
                <div className="ctalk_UploadFile_Info">
                    <TextWithTooltip tooltip={this.state.fileName}>
                        {
                            this.props.isNotShortenFileName ?
                            <div className="mx_MFileBody_info_filename">
                                {this.state.fileName}
                            </div> :
                            <div className="mx_MFileBody_info_filename">
                                { truncate(this.state.fileName, 30) }
                            </div>
                        }
                    </TextWithTooltip>
                </div>
            </div>
        );
    }
}
