/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { forwardRef, ReactNode, KeyboardEvent, Ref } from "react";
import classNames from "classnames";
import AutoHideScrollbar from "matrix-react-sdk/src/components/structures/AutoHideScrollbar";
import { _t } from "matrix-react-sdk/src/languageHandler";
import AccessibleButton, { ButtonEvent } from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import RightPanelStore from "matrix-react-sdk/src/stores/right-panel/RightPanelStore";
import { backLabelForPhase } from "matrix-react-sdk/src/stores/right-panel/RightPanelStorePhases";
import { CardContext } from "matrix-react-sdk/src/components/views/right_panel/context";
// CTalk imported
import { Room } from "matrix-js-sdk/src/models/room";
import DMRoomMap from "matrix-react-sdk/src/utils/DMRoomMap";
import { SettingStoreEvent } from "@ctalk/enums/setting-store.enum";

// CTalk added
import { getDeviceSettings } from "../../../utils/DeviceSettings";

interface IProps {
    header?: ReactNode | null;
    footer?: ReactNode;
    className?: string;
    withoutScrollContainer?: boolean;
    closeLabel?: string;
    onClose?(ev: ButtonEvent): void;
    onBack?(ev: ButtonEvent): void;
    onKeyDown?(ev: KeyboardEvent): void;
    cardState?: any;
    ref?: Ref<HTMLDivElement>;
    children: ReactNode;
    room?: Room; // CTalk added
}

interface IGroupProps {
    className?: string;
    title: string;
    children: ReactNode;
}

export const Group: React.FC<IGroupProps> = ({ className, title, children }) => {
    return (
        <div className={classNames("mx_BaseCard_Group", className)}>
            <h2>{title}</h2>
            {children}
        </div>
    );
};

/**
 * CTalk added
 * @param room
 */
const isDirectMessage = (room: Room): boolean => {
    if (!room) {
        return false;
    }
    return DMRoomMap.shared().getUserIdForRoomId(room?.roomId);
};

const BaseCard: React.FC<IProps> = forwardRef<HTMLDivElement, IProps>(
    ({ room, closeLabel, onClose, onBack, className, header, footer, withoutScrollContainer, children, onKeyDown}, ref) => {
        let backButton;
        const cardHistory = RightPanelStore.instance.roomPhaseHistory;
        if (cardHistory.length > 1) {
            const prevCard = cardHistory[cardHistory.length - 2];
            const onBackClick = (ev: ButtonEvent): void => {
                onBack?.(ev);
                RightPanelStore.instance.popCard();
            };
            const label = backLabelForPhase(prevCard.phase) ?? _t("action|back");
            backButton = <AccessibleButton className="mx_BaseCard_back" onClick={onBackClick} title={label} />;
        }

        let closeButton;
        if (onClose) {
            closeButton = (
                <AccessibleButton
                    data-testid="base-card-close-button"
                    className="mx_BaseCard_close"
                    onClick={onClose}
                    title={closeLabel || _t("action|close")}
                />
            );
        }

        if (!withoutScrollContainer) {
            children = <AutoHideScrollbar>{children}</AutoHideScrollbar>;
        }

        // CTalk added
        const blockNonAdminInvites = getDeviceSettings(SettingStoreEvent.blockNonAdminInvites);
        const isDM = isDirectMessage(room);
        return (
            <CardContext.Provider value={{ isCard: true }}>
                <div className={classNames("mx_BaseCard", className)} ref={ref} onKeyDown={onKeyDown}>
                    {header !== null && (
                        <div className="mx_BaseCard_header">
                            {(!blockNonAdminInvites || !isDM) && backButton}
                            {closeButton}
                            <div className="mx_BaseCard_headerProp">{header}</div>
                        </div>
                    )}
                    {children}
                    {footer && <div className="mx_BaseCard_footer">{footer}</div>}
                </div>
            </CardContext.Provider>
        );
    },
);

export default BaseCard;
