import React, { createRef, KeyboardEvent } from "react";
import classNames from "classnames";
import { _t } from "matrix-react-sdk/src/languageHandler";
import AccessibleTooltipButton from "matrix-react-sdk/src/components/views/elements/AccessibleTooltipButton";
import RoomContext from "matrix-react-sdk/src/contexts/RoomContext";

interface IProps {
    compact?: boolean;
    sendMessage?: (caption: string) => void;
}

interface IState {
    caption: string;
}

enum KeyCode {
    Enter = 'Enter'
}

export default class CaptionComposer extends React.Component<IProps, IState> {
    private ref: React.RefObject<HTMLDivElement> = createRef();
    public static contextType = RoomContext;
    public context!: React.ContextType<typeof RoomContext>;
    public static defaultProps = {
        compact: false,
    };

    public constructor(props: IProps) {
        super(props);
        this.state = {
            caption: '',
        };
    }

    private sendMessage = async (): void => {
        // Do something here
        // then call props sendMessage()
        if (typeof this.props.sendMessage === 'function') {
            this.props.sendMessage(this.state.caption.trim());
        }
    };

    private onKeypress = (event: KeyboardEvent): void => {
        if (event.code === KeyCode.Enter && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
            return;
        }
    };

    private onInput = (event): void => {
        event.preventDefault();
        // set auto height
        const element = event.target;
        element.style.height = '25px'; // auto minimize
        element.style.height = (element.scrollHeight) + 'px'; // auto maximize
        this.setState({
            caption: element.value,
        });
    };

    public render(): React.ReactNode {
        const classes = classNames({
            "mx_MessageComposer": true,
            "mx_MessageComposer--compact": this.props.compact,
        });
        return (
            <div className={classes} ref={this.ref}>
                <div className="ctalk_MessageComposer_wrapper">
                    <div className="mx_MessageComposer_row">
                        <div className="mx_SendMessageComposer">
                            <div className="mx_BasicMessageComposer">
                                <textarea
                                    autoFocus
                                    value={this.state.caption}
                                    style={{ height: '25px' }}
                                    className="mx_BasicMessageComposer_input"
                                    maxLength={1024}
                                    placeholder={_t('CTALK_ADD_A_CAPTION')}
                                    onInput={this.onInput}
                                    onKeyPress={this.onKeypress}
                                />
                            </div>
                        </div>
                        <AccessibleTooltipButton
                            key="controls_send"
                            className="mx_MessageComposer_sendMessage"
                            onClick={this.sendMessage}
                            title={_t('ctalk|action|send')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
