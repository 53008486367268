/*
Copyright 2021 Robin Townsend <robin@robin.town>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React, { useCallback, useEffect, useMemo, useState } from "react";
// import classnames from "classnames";
import {
    IContent,
    MatrixEvent,
    Room,
    // RoomMember,
    EventType,
    MatrixClient,
    ContentHelpers,
    ILocationContent,
    LocationAssetType,
    M_TIMESTAMP,
    M_BEACON,
} from "matrix-js-sdk/src/matrix";
import { _t } from "matrix-react-sdk/src/languageHandler";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import { useSettingValue } from "matrix-react-sdk/src/hooks/useSettings";
// import { Layout } from "matrix-react-sdk/src/settings/enums/Layout";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
// import { avatarUrlForUser } from "matrix-react-sdk/src/Avatar";
// import EventTile from "matrix-react-sdk/src/components/rooms/EventTile";
import SearchBox from "matrix-react-sdk/src/components/structures/SearchBox";
import DecoratedRoomAvatar from "matrix-react-sdk/src/components/views/avatars/DecoratedRoomAvatar";
import { Alignment } from "matrix-react-sdk/src/components/views/elements/Tooltip";
import AccessibleTooltipButton from "matrix-react-sdk/src/components/views/elements/AccessibleTooltipButton";
import AutoHideScrollbar from "matrix-react-sdk/src/components/structures/AutoHideScrollbar";
// import { StaticNotificationState } from "matrix-react-sdk/src/stores/notifications/StaticNotificationState";
// import NotificationBadge from "matrix-react-sdk/src/components/views/rooms/NotificationBadge";
import { RoomPermalinkCreator } from "matrix-react-sdk/src/utils/permalinks/Permalinks";
import { sortRooms } from "matrix-react-sdk/src/stores/room-list/algorithms/tag-sorting/RecentAlgorithm";
import QueryMatcher from "matrix-react-sdk/src/autocomplete/QueryMatcher";
import TruncatedList from "matrix-react-sdk/src/components/views/elements/TruncatedList";
import EntityTile from "matrix-react-sdk/src/components/views/rooms/EntityTile";
import BaseAvatar from "matrix-react-sdk/src/components/views/avatars/BaseAvatar";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";
import { ViewRoomPayload } from "matrix-react-sdk/src/dispatcher/payloads/ViewRoomPayload";
// import { ButtonEvent } from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { isLocationEvent } from "matrix-react-sdk/src/utils/EventUtils";
import { isSelfLocation, locationEventGeoUri } from "matrix-react-sdk/src/utils/location";
import { RoomContextDetails } from "matrix-react-sdk/src/components/views/rooms/RoomContextDetails";
import { filterBoolean } from "matrix-react-sdk/src/utils/arrays";
// CTalk imported
import { logger } from "matrix-js-sdk/src/logger";
import { RoomEvent } from "matrix-js-sdk/src/matrix";
import { CTalkRelatesToKeys } from "@ctalk/constants";
import { getBotInDirectMessage } from "@ctalk/helpers/RoomHelper";
import { hasLinksNewMessage } from "@ctalk/helpers/RoomEventInfoHelper";
import { ERoomMessageEventType } from "@ctalk/interfaces/rooms/IRoomMessageEvent";
import { CTalkClientPeg } from "@ctalk/CTalkClientPeg";
import { _tPlural } from "@ctalk/helpers/StringHelper";
import { getForwardedEventInfo } from "@ctalk/utils/helper";
import { removeReplyFromMessageContent } from "../../../utils/Reply";

// const AVATAR_SIZE = 30;

interface IProps {
    matrixClient: MatrixClient;
    // The event to forward
    event: MatrixEvent | MatrixEvent[];
    // We need a permalink creator for the source room to pass through to EventTile
    // in case the event is a reply (even though the user can't get at the link)
    permalinkCreator: RoomPermalinkCreator;
    onFinished(): void;
}

interface ForwardEvents {
    type: EventType | string;
    content: IContent;
}

interface IEntryProps {
    room: Room;
    events: MatrixEvent[];
    matrixClient: MatrixClient;
    onFinished(success: boolean): void;
}

enum SendState {
    CanSend,
    Sending,
    Sent,
    Failed,
}

const Entry: React.FC<IEntryProps> = ({ room, events, matrixClient: cli, onFinished }) => {
    const [sendState, setSendState] = useState<SendState>(SendState.CanSend);
    const ckClient = CTalkClientPeg.get();

    // CTalk hide this
    // const jumpToRoom = (ev: ButtonEvent): void => {
    //     dis.dispatch<ViewRoomPayload>({
    //         action: Action.ViewRoom,
    //         room_id: room.roomId,
    //         metricsTrigger: "WebForwardShortcut",
    //         metricsViaKeyboard: ev.type !== "click",
    //     });
    //     onFinished(true);
    // };

    const send = async (ev): Promise<void> => {
        setSendState(SendState.Sending);
        const calls = [];
        const forwardEvents: ForwardEvents[] = getMessagesContent(events);
        for (const event of forwardEvents) {
            calls.push(
                cli.sendEvent(room.roomId, event.type, event.content)
                    .then((response) => {
                        if (hasLinksNewMessage(event.content)) {
                            ckClient.createRoomMessageEvent({
                                eventId: response.event_id,
                                roomId: room.roomId,
                                type: ERoomMessageEventType.LINK,
                            }).catch((e) => {
                                logger.warn('Failed to create room message event', e);
                            });
                        }
                    })
                    .catch((e) => {
                        logger.warn('Failed to forward event to room', e);
                    }),
            );
        }
        onFinished(true);
        dis.dispatch<ViewRoomPayload>({
            action: Action.ViewRoom,
            room_id: room.roomId,
            metricsTrigger: "WebForwardShortcut",
            metricsViaKeyboard: ev.type !== "click",
        });
        // CTalk hide this
        // Origin behaviour forward events
        // const send = async (ev): Promise<void> => {
        //     setSendState(SendState.Sending);
        //     try {
        //         await cli.sendEvent(room.roomId, type, content);
        //         setSendState(SendState.Sent);
        //     } catch (e) {
        //         setSendState(SendState.Failed);
        //     }
        // };
    };

    let className;
    let disabled = false;
    let title;
    // let icon;
    if (sendState === SendState.CanSend) {
        className = "mx_ForwardList_canSend";
        if (!room.maySendMessage()) {
            disabled = true;
            title = _t("forward|no_perms_title");
        }
    // } else if (sendState === SendState.Sending) {
    //     className = "mx_ForwardList_sending";
    //     disabled = true;
    //     title = _t("forward|sending");
    //     icon = <div className="mx_ForwardList_sendIcon" aria-label={title} />;
    // } else if (sendState === SendState.Sent) {
    //     className = "mx_ForwardList_sent";
    //     disabled = true;
    //     title = _t("forward|sent");
    //     icon = <div className="mx_ForwardList_sendIcon" aria-label={title} />;
    // } else {
    //     className = "mx_ForwardList_sendFailed";
    //     disabled = true;
    //     title = _t("timeline|send_state_failed");
    //     icon = <NotificationBadge notification={StaticNotificationState.RED_EXCLAMATION} />;
    }

    return (
        <div className="mx_ForwardList_entry">
            <div
                className="mx_ForwardList_roomButton"
            >
                <DecoratedRoomAvatar room={room} size="32px" />
                <span className="mx_ForwardList_entry_name">{room.name}</span>
                <RoomContextDetails component="span" className="mx_ForwardList_entry_detail" room={room} />
            </div>
            <AccessibleTooltipButton
                kind={sendState === SendState.Failed ? "danger_outline" : "primary_outline"}
                className={`mx_ForwardList_sendButton ${className}`}
                onClick={send}
                disabled={disabled}
                title={title}
                alignment={Alignment.Top}
            >
                <div className="mx_ForwardList_sendLabel">{_t("forward|send_label")}</div>
                {/*{icon}*/}
            </AccessibleTooltipButton>
        </div>
    );
};

const transformEvent = (event: MatrixEvent): { type: string; content: IContent } => {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        "m.relates_to": _, // strip relations - in future we will attach a relation pointing at the original event
        // We're taking a shallow copy here to avoid https://github.com/vector-im/element-web/issues/10924
        ...content
    } = event.getContent();

    // beacon pulses get transformed into static locations on forward
    const type = M_BEACON.matches(event.getType()) ? EventType.RoomMessage : event.getType();

    // self location shares should have their description removed
    // and become 'pin' share type
    if (
        (isLocationEvent(event) && isSelfLocation(content as ILocationContent)) ||
        // beacon pulses get transformed into static locations on forward
        M_BEACON.matches(event.getType())
    ) {
        const timestamp = M_TIMESTAMP.findIn<number>(content);
        const geoUri = locationEventGeoUri(event);
        return {
            type,
            content: {
                ...content,
                ...ContentHelpers.makeLocationContent(
                    undefined, // text
                    geoUri,
                    timestamp || Date.now(),
                    undefined, // description
                    LocationAssetType.Pin,
                ),
            },
        };
    }

    return { type, content };
};

function getMessagesContent(events: MatrixEvent[]): ForwardEvents[] {
    const messages = [];
    events.sort((a: MatrixEvent, b: MatrixEvent) => {
        // sort events by Ts order by asc before send
        return a.getTs() - b.getTs();
    }).map((event: MatrixEvent) => {
        let { type, content } = transformEvent(event);

        // Add info sender into forwarded message
        if (!getForwardedEventInfo(content)) {
            content[CTalkRelatesToKeys.C_RELATES_TO] = {
                [CTalkRelatesToKeys.C_FORWARDED_FROM]: {
                    user_id: event.sender?.userId,
                },
            };
        }

        // Handle remove "In reply to" in forwarded message
        const isReplyMessage = !!event.getWireContent()?.["m.relates_to"]?.["m.in_reply_to"];
        if (isReplyMessage) {
            content = removeReplyFromMessageContent(content);
        }
        messages.push({ type, content });
    });
    return messages;
}

const ForwardDialog: React.FC<IProps> = ({ matrixClient: cli, event, permalinkCreator, onFinished }) => {
    // const userId = cli.getSafeUserId();
    // const [profileInfo, setProfileInfo] = useState<any>({});
    // useEffect(() => {
    //     cli.getProfileInfo(userId).then(info => setProfileInfo(info));
    // }, [cli, userId]);
    // const { type, content } = transformEvent(event);
    const events = Array.isArray(event) ? event : [event];
    const [mxEvents, setMxEvents] = useState(events);

    // CTalk start added
    useEffect(() => {
        // Anything in here is fired on component mount.
        const room: Room = cli.getRoom(mxEvents[0].getRoomId());
        room.on(RoomEvent.Redaction, onRoomReaction);
        return () => {
            // Anything in here is fired on component unmount.
            room.off(RoomEvent.Redaction, onRoomReaction);
        };
    }, [mxEvents]);

    const onRoomReaction = useCallback((ev) => {
        const newMxEvents = mxEvents.filter((oE) => {
            return ev.event.redacts !== oE.getId();
        });
        if (!newMxEvents.length) {
            onFinished(false);
            return;
        }
        setMxEvents(newMxEvents);
    }, [setMxEvents, mxEvents, onFinished]);
    // CTalk end added

    // For the message preview we fake the sender as ourselves
    // const mockEvent = new MatrixEvent({
    //     type: "m.room.message",
    //     sender: userId,
    //     content,
    //     unsigned: {
    //         age: 97,
    //     },
    //     event_id: "$9999999999999999999999999999999999999999999",
    //     room_id: event.getRoomId(),
    //     origin_server_ts: event.getTs(),
    // });
    // mockEvent.sender = {
    //     name: profileInfo.displayname || userId,
    //     rawDisplayName: profileInfo.displayname,
    //     userId,
    //     getAvatarUrl: (..._) => {
    //         return avatarUrlForUser({ avatarUrl: profileInfo.avatar_url }, AVATAR_SIZE, AVATAR_SIZE, "crop");
    //     },
    //     getMxcAvatarUrl: () => profileInfo.avatar_url,
    // } as RoomMember;

    const [query, setQuery] = useState("");
    const lcQuery = query.toLowerCase();

    // const previewLayout = useSettingValue<Layout>("layout");
    const msc3946DynamicRoomPredecessors = useSettingValue<boolean>("feature_dynamic_room_predecessors");

    let rooms = useMemo(
        () =>
            sortRooms(
                cli
                    .getVisibleRooms(msc3946DynamicRoomPredecessors)
                    .filter((room) => room.getMyMembership() === "join" && !room.isSpaceRoom() && !getBotInDirectMessage(room)),
            ),
        [cli, msc3946DynamicRoomPredecessors],
    );

    if (lcQuery) {
        rooms = new QueryMatcher<Room>(rooms, {
            keys: ["name"],
            funcs: [(r): Room[] => filterBoolean([r.getCanonicalAlias(), ...r.getAltAliases()])],
            shouldMatchWordsOnly: false,
        }).match(lcQuery);
    }

    const [truncateAt, setTruncateAt] = useState(20);
    function overflowTile(overflowCount: number, totalCount: number): JSX.Element {
        const text = _t("common|and_n_others", { count: overflowCount });
        return (
            <EntityTile
                className="mx_EntityTile_ellipsis"
                avatarJsx={
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    <BaseAvatar url={require("matrix-react-sdk/res/img/ellipsis.svg").default} name="..." size="36px" />
                }
                name={text}
                showPresence={false}
                onClick={(): void => setTruncateAt(totalCount)}
            />
        );
    }

    return (
        <BaseDialog
            title={_t(_tPlural('CTALK_FORWARD_MESSAGES_TITLE', mxEvents.length), { count: mxEvents.length })}
            className="mx_ForwardDialog"
            contentId="mx_ForwardList"
            onFinished={onFinished}
            fixedWidth={false}
        >
        { /* CTalk hide this
            <h3>{_t("forward|message_preview_heading")}</h3>
            <div
                className={classnames("mx_ForwardDialog_preview", {
                    mx_IRCLayout: previewLayout == Layout.IRC,
                })}
            >
                <EventTile
                    mxEvent={mockEvent}
                    layout={previewLayout}
                    permalinkCreator={permalinkCreator}
                    as="div"
                    inhibitInteraction
                />
            </div>
            <hr />
        */ }
            <div className="mx_ForwardList" id="mx_ForwardList">
                <SearchBox
                    className="mx_textinput_icon mx_textinput_search"
                    placeholder={_t("forward|filter_placeholder")}
                    onSearch={setQuery}
                    autoFocus={true}
                />
                <AutoHideScrollbar className="mx_ForwardList_content">
                    {rooms.length > 0 ? (
                        <div className="mx_ForwardList_results">
                            <TruncatedList
                                className="mx_ForwardList_resultsList"
                                truncateAt={truncateAt}
                                createOverflowElement={overflowTile}
                                getChildren={(start, end): Room[] =>
                                    rooms
                                        .slice(start, end)
                                        .map((room) => (
                                            <Entry
                                                key={room.roomId}
                                                room={room}
                                                // type={type}
                                                // content={content}
                                                events={mxEvents}
                                                matrixClient={cli}
                                                onFinished={onFinished}
                                            />
                                        ))
                                }
                                getChildCount={(): number => rooms.length}
                            />
                        </div>
                    ) : (
                        <span className="mx_ForwardList_noResults">{_t("common|no_results")}</span>
                    )}
                </AutoHideScrollbar>
            </div>
        </BaseDialog>
    );
};

export default ForwardDialog;
