import React from "react";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import CFormInfo from "@ctalk/components/views/messages/form_components/CFormInfo";
import { ICResponseItem, IBotFormValue } from "@ctalk/models/bot-form.interface";
import CResponseItem from "@ctalk/components/views/messages/form_components/CResponseItem";
import Modal from "matrix-react-sdk/src/Modal";
import ErrorDialog from "matrix-react-sdk/src/components/views/dialogs/ErrorDialog";

import BaseDialog from "../../../components/views/dialogs/BaseDialog";
import { getLatestEventInfo } from "../../helpers/BotFormHelper";
import { EFormStatus, EFormType } from "../../enums/bot-form.enum";
import { _t } from "../../../languageHandler";

interface IProps {
    mxEvent: MatrixEvent;
    submittingEvent: MatrixEvent;
    eventsUntilSubmitted: MatrixEvent[];
    onFinished: (success: boolean) => void;
}

interface IState {
    mappingResponse: ICResponseItem[];
    viewResponseExpired?: number;
    previewFileExpired?: boolean;
}

export default class CFormViewResponseDialog extends React.Component<IProps, IState> {
    private timeoutExpiredId: ReturnType<typeof setTimeout> | null = null;
    public constructor(props: IProps) {
        super(props);
        const submittedEvent = getLatestEventInfo(this.props.eventsUntilSubmitted, EFormStatus.SUBMITTED);
        this.state = {
            mappingResponse: [],
            viewResponseExpired: submittedEvent?.event.getContent()?.view_response_expired,
        };
    }

    public componentDidMount(): void {
        const mappingResponse = this.dataMapping(this.props.mxEvent, this.props.submittingEvent);
        this.setState({
            mappingResponse,
        });
        this.handlePreviewFiles(mappingResponse);
    }

    public componentWillUnmount(): void {
        if (this.timeoutExpiredId) {
            clearTimeout(this.timeoutExpiredId);
            this.timeoutExpiredId = null;
        }
    }

    private handlePreviewFiles(data: ICResponseItem[]): void {
        const content =  this.props.mxEvent.getContent();
        const hasFile = data.find(x => x.type === EFormType.File) || content?.files?.length;
        if (hasFile) {
            const viewResponseExpired = this.state.viewResponseExpired ?? 0;
            const expireTime = viewResponseExpired - Date.now();
            if (expireTime > 0) {
                this.setState({
                    previewFileExpired: false,
                });
                this.timeoutExpiredId = setTimeout(() => {
                    this.setState({
                        previewFileExpired: true,
                    });

                    const currentModal = (Modal as any)?.getCurrentModal();
                    if (currentModal?.elem?.props?.isPreviewFileModal) {
                        currentModal?.close();
                    }

                    Modal.createDialog(ErrorDialog, {
                        title: _t("ctalk|bot_form|files_expired_title"),
                        description:_t("ctalk|bot_form|files_expired_description"),
                    });
                }, expireTime);
            } else {
                this.setState({
                    previewFileExpired: true,
                });
            }
        }
    }

    private onCancel = (): void => {
        this.props.onFinished(false);
    };

    private dataMapping(originalEvent: MatrixEvent, submittingEvent: MatrixEvent): ICResponseItem[] {
        const originalContent = originalEvent.getContent();
        const { response } = submittingEvent.getContent();

        const mappingData = originalContent.form.map((item: ICResponseItem) => {
            const data = response.find((x: IBotFormValue) => x.name === item.name);
            return {
                ...item,
                responseValue: data ? data.value : undefined,
            };
        });

        return mappingData;
    }

    public render(): React.ReactNode {
        const renderedResponses: React.ReactNode[] = [];
        this.state.mappingResponse.forEach(response => {
            renderedResponses.push(
                <CResponseItem
                    key={`${response.type}_${response.name}`}
                    viewResponseExpired={this.state.viewResponseExpired}
                    previewFileExpired={this.state.previewFileExpired}
                    {...response}
                />
            );
        });

        return (
            <BaseDialog
                onFinished={this.onCancel}
                title={_t("ctalk|bot_form|preview_title")}
                className="ctalk_view_response"
            >
                <div className="ctalk_view_response_container">
                    <CFormInfo
                        mxEvent={this.props.mxEvent}
                        previewFileExpired={this.state.previewFileExpired}
                    />
                    {renderedResponses}
                </div>
            </BaseDialog>
        );
    }
}
