import { RoomMember } from "matrix-js-sdk/src/models/room-member";
import { ERoomRole } from "@ctalk/enums/room-role.enum";

import { IPowerLevelsContent } from "../../components/views/right_panel/UserInfo";

/**
 * @param member
 * @param powerLevelContent
 * @param permissionKey
 * @param childKey
 */
export const hasPermission = (
    member: RoomMember | null | undefined,
    powerLevelContent: IPowerLevelsContent | null | undefined,
    permissionKey: string,
    childKey?: string,
): boolean => {
    if (!powerLevelContent || !member) return false;
    let currentPowerLevelKey;
    if (childKey && powerLevelContent[childKey]) {
        currentPowerLevelKey = powerLevelContent[childKey][permissionKey];
    } else {
        currentPowerLevelKey = powerLevelContent[permissionKey];
    }
    return member.powerLevel >= (currentPowerLevelKey ?? ERoomRole.OWNER);
};

/**
 * Check permission in events
 * eventContent => powerLevelContent.events
 * @param member
 * @param eventContent
 * @param permissionKey
 */
export const permissionViewEventContent = (
    member: RoomMember,
    eventContent: Record<string, number>,
    permissionKey: string,
): boolean => {
    if (!eventContent || !member) return false;
    return member.powerLevel >= eventContent[permissionKey];
};
