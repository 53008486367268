
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import React, { useEffect, useState, useRef } from "react";
import InlineSpinner from "matrix-react-sdk/src/components/views/elements/InlineSpinner";
import { Icon as CheckIcon } from "matrix-react-sdk/res/img/element-icons/check.svg";
import { Icon as RetryIcon } from "matrix-react-sdk/res/img/element-icons/retry.svg";
import { Icon as BackIcon } from "matrix-react-sdk/res/img/element-icons/room/message-bar/reply.svg";
import { Icon as TakePictureIcon } from "matrix-react-sdk/res/img/element-icons/new-and-improved.svg";
import AccessibleButton from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { randomString } from "matrix-js-sdk/src/randomstring";
import classNames from "classnames";
import { _t } from "matrix-react-sdk/src/languageHandler";
import { dataURItoBlob } from "../../../helpers/FileHepler";

interface IProps {
    onFinished(result?: {
      isFinished: boolean,
      imageFile?: File,
    }): void;
}

export default function CameraDialog(props: IProps): React.FC<IProps> {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [loadingCamera, setLoadingCamera] = useState(true);
    const [cameraNumber, setCameraNumber] = useState<number>(0);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [imageDataURL, setImageDataURL] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [listDevice, setListDevice] = useState<any[]>([]);
    const [deviceName, setDeviceName] = useState<string | null>(null);
    const [initCamera, setInitCamera] = useState<boolean>(true);

    useEffect(() => {
      startCamera();
      // Cleanup function to stop the video stream
      return () => {
        stopCamera();
      };
    }, []);

    useEffect(() => {
      if (!initCamera) {
        startCamera();
      }
    }, [cameraNumber]);

    const startCamera = async () => {
      //Get the details of video inputs of the device
      const videoInputs = await getListOfVideoInputs();
      if (videoInputs.length) {
        navigator.mediaDevices.getUserMedia({
          video: initCamera ? true : {
            deviceId: {
              exact: videoInputs[cameraNumber].deviceId,
            },
            width: 640,
          },
        }).then(async (stream) => {
          setStream(stream);
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
          if (initCamera) {
            setDeviceName((await getListOfVideoInputs())[cameraNumber].label);
          } else {
            setDeviceName(videoInputs[cameraNumber].label);
          }
          setError(null);
        }).catch((error: any) => {
          console.error('Error accessing the camera: ', error);
          if (error?.code === 0 && error?.name === 'NotReadableError') {
            setError('ctalk|camera|error_device_in_use');
          } else if (error?.code === 0 && error?.name === 'NotAllowedError') {
            setError('ctalk|camera|error_not_have_permission');
          } else {
            setError(error?.message);
          }
          setDeviceName(null);
        }).finally(() => {
          setLoadingCamera(false);
        });
      } else {
        setDeviceName(null);
        setLoadingCamera(false);
        console.error('The device does not have a camera');
        setError('ctalk|camera|error_not_have_camera');
      }
      setInitCamera(false);
    };

    const stopCamera = async () => {
      if (stream) {
        stream.getVideoTracks().forEach((track) => {
          track.stop();
        });
        setStream(null);
      }
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };

    const capturePicture = () => {
      if (canvasRef.current) {
        canvasRef.current.width = videoRef.current?.videoWidth!;
        canvasRef.current.height = videoRef.current?.videoHeight!;
        let contex = canvasRef.current.getContext("2d");
        contex?.drawImage(videoRef.current!, 0, 0, canvasRef.current.width, canvasRef.current.height);
        stopCamera();
        setImageDataURL(canvasRef.current.toDataURL());
      }
    };

    const switchCamera = async () => {
      const listOfVideoInputs = await getListOfVideoInputs();

      // The device has more than one camera
      if (listOfVideoInputs.length > 1) {
        if (videoRef.current?.srcObject) {
          (videoRef.current?.srcObject as  MediaStream)?.getVideoTracks().forEach((track) => {
            track.stop();
          });
        }

        // switch to other camera
        if ((cameraNumber + 1) === listOfVideoInputs.length) {
          setCameraNumber(0);
        } else {
          setCameraNumber(cameraNumber + 1);
        }
      } else if (listOfVideoInputs.length === 1) {
        alert("The device has only one camera");
      } else {
        alert("The device does not have a camera");
      }
    };

    const getListOfVideoInputs = async () => {
      if (listDevice.length) {
        return listDevice;
      }

      const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

      const videoDevices = enumerateDevices.filter((device) => device.kind === "videoinput");
      setListDevice(videoDevices);
      return videoDevices;
    };

    const onRetake = () => {
      setImageDataURL(null);
      startCamera();
    };

    const onUsePicture = () => {
      const blob = dataURItoBlob(imageDataURL!);
      const resultFile = new File([blob], randomString(30).concat('.png'), { type: 'image/png' });
      stopCamera();
      props.onFinished({ isFinished: true, imageFile: resultFile });
    };

    const onFinished = () => {
      stopCamera();
      props.onFinished({ isFinished: false });
    }

    return (
        <BaseDialog
            title={
              deviceName ?
              _t('ctalk|camera|title_dialog_device', { device_name: deviceName }) :
              _t('ctalk|camera|title_dialog')
            }
            hasCancel={true}
            className="ctalk_Dialog_camera"
            onFinished={onFinished}
            fixedWidth={false}
        >
            <div className="ctalk_Dialog_camera_content">
              {
                <div>
                  {
                    <div>
                        <canvas
                          className={
                            classNames("ctalk_Dialog_camera_canvas", {
                              hidden: !Boolean(imageDataURL),
                          })}
                          ref={canvasRef}
                          id="canvas"
                        />
                        <video
                          disablePictureInPicture
                          ref={videoRef}
                          autoPlay
                          className={
                            classNames("ctalk_Dialog_camera_video", {
                              hidden: Boolean(imageDataURL) || Boolean(error) || loadingCamera,
                          })}
                        />
                      {
                        loadingCamera ? <InlineSpinner w={50} h={50} /> :
                        <div>
                          {
                            !imageDataURL ?
                            <div>
                              {
                                error &&
                                <div className="errorText">
                                  {_t(error)}
                                </div>
                              }
                              {
                                listDevice?.length > 0 && <div
                                  className={
                                    classNames("ctalk_Dialog_camera_actions", {
                                      hidden_switching_camera: !Boolean(listDevice?.length > 1),
                                  })}
                                >
                                  { listDevice?.length > 1 && <div className="ctalk_Dialog_camera_space"></div> }
                                  <AccessibleButton
                                    className="ctalk_AccessibleButton ctalk_AccessibleButton_Dialog_camera ctalk_AccessibleButton_primary"
                                    onClick={capturePicture}
                                    disabled={!!error}
                                  >
                                    <TakePictureIcon width={40} height={40} className="ctalk_Dialog_camera_icon" />
                                  </AccessibleButton>
                                  {
                                    listDevice?.length > 1 && <AccessibleButton
                                      className="ctalk_AccessibleButton ctalk_AccessibleButton_Dialog_camera ctalk_AccessibleButton_secondary"
                                      onClick={switchCamera}
                                    >
                                      <RetryIcon width={20} height={20} className="ctalk_Dialog_camera_icon" />
                                    </AccessibleButton>
                                  }
                                </div>
                              }
                            </div> :
                            <div className="ctalk_Dialog_camera_actions ctalk_Dialog_camera_actions_capture">
                              <AccessibleButton
                                kind="primary_outline"
                                className="ctalk_AccessibleButton"
                                onClick={onRetake}
                              >
                                <BackIcon width={20} height={20} className="ctalk_Dialog_camera_icon_capture" />
                                {_t('ctalk|camera|back')}
                              </AccessibleButton>
                              <AccessibleButton
                                kind="primary"
                                className="ctalk_AccessibleButton"
                                onClick={onUsePicture}
                              >
                                <CheckIcon width={15} height={15} className="ctalk_Dialog_camera_icon_capture" />
                                {_t('ctalk|camera|use_picture')}
                              </AccessibleButton>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
        </BaseDialog>
    );
}
